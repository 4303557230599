import type React from 'react';
import { type SetStateAction } from 'react';
import { useRef, useEffect, useCallback, useState } from 'react';

export function useMounted() {
  const isMountedRef: React.MutableRefObject<null | boolean> = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return useCallback(() => {
    return isMountedRef.current;
  }, []);
}

export function useSafeState<TState>(initialState: TState | (() => TState)) {
  const isMounted = useMounted();
  const [state, setState] = useState<TState>(initialState);

  const setSafeState = useCallback((newState: SetStateAction<TState>) => {
    if (isMounted()) {
      setState(newState);
    }
  }, []); // eslint-disable-line

  return [state, setSafeState] as const;
}
