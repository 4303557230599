import { List, Map, type OrderedMap } from 'immutable';

import AccessGroup from '@peakon/records/AccessGroupRecord';
import type Category from '@peakon/records/CategoryRecord';
import { type CategoryGroup } from '@peakon/records/constants/questionSets';
import { AccessGroupFormEditorRecord } from '@peakon/records/FormEditorRecord';
import type Segment from '@peakon/records/SegmentRecord';
import {
  ACCESS_SETTINGS_GROUPS,
  type AccessByGroup,
  type AccessSettingGroupKey,
  accessSettingGroupKeys,
} from '@peakon/records/settings';
import { type AccessGroupResponseData } from '@peakon/shared/schemas/api/accessGroups';
import { type CategoryGroup as CategoryGroupResponse } from '@peakon/shared/schemas/api/categoryGroups';

import {
  getGroupNextCategories,
  getGroupNextGroupSettings,
  getAccessByGroup,
} from './utils';

type AccessGroupSettings = Record<AccessSettingGroupKey, boolean>;

export const sideEffects = (
  settings: AccessGroupSettings,
  id: AccessSettingGroupKey,
) => {
  // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
  settings[id] = false;

  for (const accessSettingGroupId of accessSettingGroupKeys) {
    const accessSettingGroup = ACCESS_SETTINGS_GROUPS[accessSettingGroupId];
    if (accessSettingGroup.hasParent(id)) {
      // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
      settings[accessSettingGroupId] = false;
      // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
      settings = sideEffects(settings, accessSettingGroupId);
    }
  }

  return settings;
};

export const categorySideEffects = ({
  settings,
  accessByGroup,
}: {
  settings: AccessGroupSettings;
  accessByGroup: AccessByGroup;
}) => {
  for (const accessSettingGroupId of accessSettingGroupKeys) {
    const accessSettingGroup = ACCESS_SETTINGS_GROUPS[accessSettingGroupId];
    if (!accessSettingGroup.hasGroupAccess(accessByGroup)) {
      // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
      settings[accessSettingGroupId] = false;
      // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
      settings = sideEffects(settings, accessSettingGroupId);
    }
  }
  return settings;
};

type Action =
  | {
      type: 'ACCESS_SETTINGS_CREATE_SUCCESS';
      data: {
        data: CategoryGroupResponse[];
        specialist?: boolean;
      };
    }
  | {
      type: 'ACCESS_SETTINGS_EDIT_START_SUCCESS' | 'ACCESS_SETTINGS_EDIT_START';
      data: {
        group: AccessGroup;
      };
    }
  | {
      type: 'ACCESS_SETTINGS_CHANGE';
      data: {
        id: AccessSettingGroupKey;
        enabled: boolean;
      };
    }
  | {
      type: 'ACCESS_CATEGORY_CHANGE';
      data: {
        groupId: CategoryGroup;
        categoryId: string;
        categoriesByGroup: OrderedMap<string, Map<string, Category>>;
        enabled: boolean;
      };
    }
  | {
      type: 'ACCESS_CATEGORY_ALL_CHANGE';
      data: {
        groupId: CategoryGroup;
        categoriesByGroup: OrderedMap<string, Map<string, Category>>;
        enabled: boolean;
      };
    }
  | {
      type: 'ACCESS_QUESTION_SET_CHANGE';
      data: {
        id: CategoryGroup;
        enabled: boolean;
      };
    }
  | {
      type: 'ACCESS_GROUP_UPDATE';
      data:
        | { field: 'name'; value: string }
        | {
            field: 'level';
            value: 'manager' | 'all' | 'overall';
          }
        | {
            field: 'memberType';
            value: 'member' | 'all';
          };
    }
  | {
      type: 'ACCESS_GROUP_ATTRIBUTE_CHANGE';
      data: {
        id: string;
        optionId: string;
      };
    }
  | {
      type: 'ACCESS_GROUP_INCLUDED_CHANGE';
      data: {
        includedSegments: Segment[];
      };
    }
  | {
      type: 'ACCESS_GROUP_INCLUDED_REMOVE' | 'ACCESS_GROUP_EXCLUDED_REMOVE';
      data: {
        segmentId: string;
      };
    }
  | {
      type: 'ACCESS_GROUP_EXCLUDED_CHANGE';
      data: {
        excludedSegments: Segment[];
      };
    }
  | {
      type: 'ACCESS_GROUP_CREATE_SUCCESS' | 'ACCESS_GROUP_PATCH_SUCCESS';
      data: {
        data: AccessGroupResponseData;
      };
    }
  | {
      type:
        | 'ACCESS_GROUP_MEMBER_ADD_LOADING'
        | 'ACCESS_GROUP_MEMBER_REMOVE_FAILED'
        | 'ACCESS_GROUP_MEMBER_ADD_FAILED'
        | 'ACCESS_GROUP_MEMBER_REMOVE_SUCCESS';
    };

const settings = (
  state = new AccessGroupFormEditorRecord(),
  action: Action,
): AccessGroupFormEditorRecord => {
  switch (action.type) {
    case 'ACCESS_SETTINGS_CREATE_SUCCESS': {
      const { specialist, data: categoryGroups } = action.data;

      const accessCategoryGroups = Map(
        categoryGroups
          .filter((group) => group.id !== 'text' && group.id !== 'values')
          .map((group) => [group.id, !specialist]),
      );

      const accessSettings = Object.keys(ACCESS_SETTINGS_GROUPS).reduce(
        (acc, curr) => {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
          acc[curr] = false;

          return acc;
        },
        {},
      );

      return AccessGroupFormEditorRecord.startEditing(
        new AccessGroup({
          settings: Map(accessSettings),
          categoryGroupSettings: accessCategoryGroups,
          status: 'enabled',
          specialist: Boolean(specialist),
          level: specialist ? 'all' : undefined,
          memberType: specialist ? 'member' : undefined,
        }),
      );
    }
    case 'ACCESS_SETTINGS_EDIT_START_SUCCESS':
    case 'ACCESS_SETTINGS_EDIT_START': {
      const { group } = action.data;

      return AccessGroupFormEditorRecord.startEditing(group);
    }
    case 'ACCESS_SETTINGS_CHANGE': {
      const { id, enabled } = action.data;

      // @ts-expect-error Type 'Map<string, any>' is missing the following properties from type 'AccessGroupFormEditorRecord': current, original, isEditing, isDirty, diffts(2739)
      return enabled
        ? state.setIn(['current', 'settings', id], true)
        : state.updateIn(['current', 'settings'], (stateSettings) =>
            stateSettings.merge(sideEffects(stateSettings.toJS(), id)),
          );
    }

    case 'ACCESS_CATEGORY_CHANGE': {
      const { groupId, categoryId, categoriesByGroup, enabled } = action.data;

      // Determine what's the next state for categoryGroupSettings and
      // the active categoryIds
      const { categoryGroupSettings, categoryIds } = getGroupNextCategories({
        enabled,
        categoriesByGroup,
        categoryId,
        groupId,
        categoryIds: state.getIn(['current', 'categoryIds']),
        categoryGroupSettings: state.getIn([
          'current',
          'categoryGroupSettings',
        ]),
      });

      // Calculate the type of access for each group, in order to determine
      // if any side-effects should be introduced on the next step
      const accessByGroup = getAccessByGroup({
        categoryGroupSettings,
        categoryIds,
        categoriesByGroup,
      });

      // @ts-expect-error Type 'Map<string, any>' is missing the following properties from type 'AccessGroupFormEditorRecord': current, original, isEditing, isDirty, diffts(2739)
      return state.updateIn(['current'], (group) => {
        const categorySideEffectsSettings = categorySideEffects({
          settings: group.settings.toJS(),
          accessByGroup,
        });

        return group.merge({
          categoryGroupSettings,
          categoryIds,
          settings: categorySideEffectsSettings,
        });
      });
    }

    case 'ACCESS_CATEGORY_ALL_CHANGE': {
      const { groupId, enabled, categoriesByGroup } = action.data;

      const { categoryGroupSettings, categoryIds } = getGroupNextCategories({
        enabled,
        categoriesByGroup,
        categoryId: null,
        groupId,
        categoryIds: state.getIn(['current', 'categoryIds']),
        categoryGroupSettings: state.getIn([
          'current',
          'categoryGroupSettings',
        ]),
      });

      // Calculate the type of access for each group, in order to determine
      // if any side-effects should be introduced on the next step
      const accessByGroup = getAccessByGroup({
        categoryGroupSettings,
        categoryIds,
        categoriesByGroup,
      });

      // @ts-expect-error Type 'Map<string, any>' is missing the following properties from type 'AccessGroupFormEditorRecord': current, original, isEditing, isDirty, diffts(2739)
      return state.updateIn(['current'], (group) => {
        const categorySideEffectsSettings = categorySideEffects({
          settings: group.settings.toJS(),
          accessByGroup,
        });

        return group.merge({
          categoryGroupSettings,
          categoryIds,
          settings: categorySideEffectsSettings,
        });
      });
    }

    case 'ACCESS_QUESTION_SET_CHANGE': {
      const { id, enabled } = action.data;

      const categoryGroupSettings = getGroupNextGroupSettings({
        categoryGroupSettings: state.current.categoryGroupSettings,
        groupId: id,
        enabled,
      });

      const accessByGroup = getAccessByGroup({
        categoryGroupSettings,
      });

      // @ts-expect-error Type 'Map<string, any>' is missing the following properties from type 'AccessGroupFormEditorRecord': current, original, isEditing, isDirty, diffts(2739)
      return state.updateIn(['current'], (group) => {
        return group.merge({
          categoryGroupSettings,
          settings: categorySideEffects({
            settings: group.settings.toJS(),
            accessByGroup,
          }),
        });
      });
    }
    case 'ACCESS_GROUP_UPDATE': {
      const { field, value } = action.data;

      // An access group of type manager cannot admin segments
      if (field === 'level' && value === 'manager') {
        return state
          .setIn(['current', field], value)
          .setIn(['current', 'settings', 'segmentAdmin'], false);
      }

      return state.setIn(['current', field], value);
    }
    case 'ACCESS_GROUP_ATTRIBUTE_CHANGE': {
      const { id, optionId } = action.data;

      return state.withMutations((editor) => {
        editor
          .setIn(['current', 'attributeId'], id)
          .setIn(['current', 'attributeOptionId'], optionId);
      });
    }
    case 'ACCESS_GROUP_INCLUDED_CHANGE': {
      const { includedSegments } = action.data;

      return state.withMutations((editor) => {
        editor.setIn(['current', 'includedSegments'], List(includedSegments));
      });
    }
    case 'ACCESS_GROUP_INCLUDED_REMOVE': {
      const { segmentId } = action.data;

      return state.withMutations((editor) => {
        editor.updateIn(['current', 'includedSegments'], (includedSegments) =>
          includedSegments.filter(
            // @ts-expect-error Parameter 'segment' implicitly has an 'any' type.ts(7006)
            (segment) => segment.id !== segmentId,
          ),
        );
      });
    }
    case 'ACCESS_GROUP_EXCLUDED_CHANGE': {
      const { excludedSegments } = action.data;

      return state.withMutations((editor) => {
        editor.setIn(['current', 'excludedSegments'], List(excludedSegments));
      });
    }
    case 'ACCESS_GROUP_EXCLUDED_REMOVE': {
      const { segmentId } = action.data;

      return state.withMutations((editor) => {
        editor.updateIn(['current', 'excludedSegments'], (excludedSegments) =>
          excludedSegments.filter(
            // @ts-expect-error Parameter 'segment' implicitly has an 'any' type.ts(7006)
            (segment) => segment.id !== segmentId,
          ),
        );
      });
    }
    case 'ACCESS_GROUP_CREATE_SUCCESS':
    case 'ACCESS_GROUP_PATCH_SUCCESS': {
      const { data } = action.data;

      return AccessGroupFormEditorRecord.startEditing(
        AccessGroup.createFromApi(data),
      );
    }

    case 'ACCESS_GROUP_MEMBER_ADD_LOADING':
    case 'ACCESS_GROUP_MEMBER_REMOVE_FAILED': {
      // @ts-expect-error Type 'Map<string, any>' is missing the following properties from type 'AccessGroupFormEditorRecord': current, original, isEditing, isDirty, diffts(2739)
      return state
        .updateIn(['original', 'memberCount'], (memberCount) => memberCount + 1)
        .updateIn(['current', 'memberCount'], (memberCount) => memberCount + 1);
    }
    case 'ACCESS_GROUP_MEMBER_ADD_FAILED':
    case 'ACCESS_GROUP_MEMBER_REMOVE_SUCCESS': {
      // @ts-expect-error Type 'Map<string, any>' is missing the following properties from type 'AccessGroupFormEditorRecord': current, original, isEditing, isDirty, diffts(2739)
      return state
        .updateIn(['original', 'memberCount'], (memberCount) => memberCount - 1)
        .updateIn(['current', 'memberCount'], (memberCount) => memberCount - 1);
    }

    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/no-default-export
export default settings;
