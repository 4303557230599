import React from 'react';

import { z } from 'zod';

import { LinkButton } from '@peakon/bedrock/react/button';
import { Typography } from '@peakon/components';
import { validateData } from '@peakon/shared/utils/validateData/validateData';

import { getNotificationContent } from './getNotificationContent';
import { ProgressBar } from '../../TaskNotification/ProgressBar/ProgressBar';
import { TaskNotification } from '../../TaskNotification/TaskNotification';
import { type Task } from '../types';

import styles from './styles.css';

const exportTypeSchema = z.enum([
  'commentExport',
  'topicExport',
  'groupExport',
  'segmentExport',
  'metricsExport',
  'employeesExport',
  'segmentManagersExport',
  'kioskCodeExport',
  'engagementPresentation',
  'diversityAndInclusionPresentation',
  'healthAndWellbeingPresentation',
  'benchmarkMappingTemplate',
]);

const completedDataSchema = z.object({
  status: z.literal('completed'),
  url: z.string(),
});

const errorDataSchema = z.object({
  status: z.literal('error'),
  error: z.string(),
  errorId: z.string().optional(),
});

const inProgressDataSchema = z.object({
  status: z.literal('inProgress'),
  percent: z.number().min(0).max(100),
});

const dataSchema = z.union([
  inProgressDataSchema,
  completedDataSchema,
  errorDataSchema,
]);

const exportTaskSchema = z.object({
  id: z.string(),
  type: exportTypeSchema,
  data: dataSchema,
});

export type SupportedExportType = z.infer<typeof exportTypeSchema>;

export type ExportTask = z.infer<typeof exportTaskSchema>;
export type ExportTaskInitializationData = Pick<ExportTask, 'type'>;

// eslint-disable-next-line no-restricted-syntax
export const isExportTask = (task: Task): task is ExportTask => {
  if (exportTypeSchema.safeParse(task.type).success) {
    validateData(task, exportTaskSchema, {
      errorMessagePrefix: 'EmployeeImportBackgroundTask',
    });
    return true;
  }
  return false;
};

export const ExportTaskNotification = ({
  task,
  onClose,
}: {
  task: ExportTask;
  onClose: () => void;
}) => {
  const notificationContent = getNotificationContent(task.type);

  if (task.data.status === 'inProgress') {
    return (
      <TaskNotification
        taskId={task.id}
        heading={notificationContent.inProgress.title}
        onClose={onClose}
        status="inProgress"
      >
        <TaskNotification.Content icon={notificationContent.icon}>
          <div className={styles.description}>
            <Typography.P>{notificationContent.inProgress.text}</Typography.P>
          </div>
          <ProgressBar percent={task.data.percent} />
        </TaskNotification.Content>
      </TaskNotification>
    );
  }

  if (task.data.status === 'completed') {
    return (
      <TaskNotification
        taskId={task.id}
        heading={notificationContent.completed.title}
        onClose={onClose}
        status="completed"
      >
        <TaskNotification.Content icon={notificationContent.icon}>
          <div className={styles.description}>
            <Typography.P>{notificationContent.completed.text}</Typography.P>
          </div>
          <LinkButton variant="primary" href={task.data.url}>
            {notificationContent.completed.callToAction}
          </LinkButton>
        </TaskNotification.Content>
      </TaskNotification>
    );
  }

  return (
    <TaskNotification
      taskId={task.id}
      heading={notificationContent.error.title}
      onClose={onClose}
      status="error"
    >
      <TaskNotification.Content icon={notificationContent.icon}>
        <Typography.P className={styles.error}>{task.data.error}</Typography.P>
      </TaskNotification.Content>
    </TaskNotification>
  );
};
