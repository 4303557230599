import React, { type Dispatch, type SetStateAction, useEffect } from 'react';

import { redirectTo } from '@peakon/shared/utils/redirectTo';

import { Header } from './Header';
import { useShellContext } from '../../context/ShellContext';
import {
  useAuthToken,
  useInitialAuthToken,
} from '../../hooks/useTokenFromStorage';
import { ProxyViewBar } from '../ProxyViewBar/ProxyViewBar';
import SystemAlertBar from '../SystemAlertBar';

import styles from './styles.css';

type Props = {
  children: React.ReactNode;
  isTest?: boolean;
  isProxyViewing?: boolean;
  proxyViewManagerName?: string | null;
  setShowProxyViewModal?: Dispatch<SetStateAction<boolean>>;
  setCloseProxyViewModal?: Dispatch<SetStateAction<boolean>>;
  welcomeTourItem?: React.ReactNode;
  systemConsultantBar?: React.ReactNode;
};

export const AppChrome = ({
  children,
  isTest = false,
  isProxyViewing = false,
  proxyViewManagerName = '',
  setShowProxyViewModal,
  setCloseProxyViewModal,
  welcomeTourItem,
  systemConsultantBar,
}: Props) => {
  const [initialToken] = useInitialAuthToken();
  const [authToken] = useAuthToken();
  const { authenticate } = useShellContext();

  useEffect(() => {
    /* Update redux store for active browser */
    const handleActiveBrowser = async () => {
      if (window.document.visibilityState === 'visible') {
        if (initialToken && authToken) {
          return redirectTo('/transfer/proxy-view', {
            keepQueryParamsOnRedirect: true,
          });
        } else {
          /**
           * FIXME-ProxyView: Find another way to handle this case
           * If we are opening proxy view with multiple tabs and end the proxy view session on one tab.
           * The other tabs will get stuck on impersonated manager's view without the Proxy View Bar. It will eventually log the user out since the token is invalid (401).
           * This block will also be called when you're just activating a tab without proxy view, and it will reset the frontend state (e.g. closing slide ins), so we have to find a way to prevent this issue.
           */
          // authenticate();
        }
      }
    };

    window.document.addEventListener('visibilitychange', handleActiveBrowser);
    return () => {
      window.document.removeEventListener(
        'visibilitychange',
        handleActiveBrowser,
      );
    };
  }, [authToken, authenticate, initialToken]);

  return (
    <div className={isProxyViewing ? styles.isProxyViewing : ''}>
      {isProxyViewing && (
        <ProxyViewBar
          managerName={proxyViewManagerName}
          setShowProxyViewModal={setShowProxyViewModal}
          setCloseProxyViewModal={setCloseProxyViewModal}
        />
      )}
      <Header isTest={isTest} welcomeTourItem={welcomeTourItem} />
      {children}
      <SystemAlertBar systemConsultantBar={systemConsultantBar} />
    </div>
  );
};
