import React from 'react';

import { type List } from 'immutable';

import { CommentsRelevantCommentIcon as RelevantCommentIcon } from '@peakon/bedrock/icons/graphic';
import { GuidanceWhatDoesThisMeanOutlineIcon as InfoIcon } from '@peakon/bedrock/icons/system';
import { Tag } from '@peakon/bedrock/react/tag';
import { Tooltip } from '@peakon/components';
import { createTranslationMap } from '@peakon/shared/features/i18next/createTranslationMap';
import { t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

type Props = {
  reasons: List<'focused_content' | 'low_score' | 'text_length' | 'high_score'>;
};

export const HighlightBanner = ({ reasons }: Props) => {
  const relevanceReasonTranslationMap = createTranslationMap({
    focused_content: t('comment_item__relevance__focused_content'),
    low_score: t('comment_item__relevance__low_score'),
    text_length: t('comment_item__relevance__text_length'),
    high_score: t('comment_item__relevance__high_score'),
  } as const);

  return (
    <div className={styles.root} data-test-id="comment-hightlight-banner">
      <Tag
        variant="info"
        icon={<RelevantCommentIcon />}
        label={t('comment__relevant')}
      />

      <Tooltip
        arrow
        placement="top"
        buttonLabel={t('comment__relevant__reasons')}
        title={
          <div className={styles.tooltip}>
            <div className={styles.heading}>
              {t('comment__relevant__reasons')}
            </div>
            <ul className={styles.reasons}>
              {/* @ts-expect-error React-18 Type 'List<Element>' is not assignable to type 'ReactI18NextChildren | Iterable<ReactI18NextChildren>'.ts(2322) */}
              {reasons.map((reason) => (
                <li key={reason}>
                  {relevanceReasonTranslationMap.get(reason)}
                </li>
              ))}
            </ul>
          </div>
        }
      >
        <InfoIcon className={styles.infoIcon} />
      </Tooltip>
    </div>
  );
};
