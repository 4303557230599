import { useEffect, useRef, useReducer, useCallback, useMemo } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import get from 'lodash/get';

import type PeakonApi from '@peakon/peakon-js';
import useRealtimeChannel from '@peakon/shared/hooks/useRealtimeChannel';
import { useShellContext } from '@peakon/shell';
import { proxyViewSnapshot } from '@peakon/shell/hooks/useTokenFromStorage';

import { type NotificationItem } from './Notification/types';
import { queryKeys } from './queries/queryKeys';
import { useGetNotificationsInfiniteQuery } from './queries/useGetNotificationsInfiniteQuery';
import reducer, { defaultState } from './reducer';

type Props = {
  client: PeakonApi;
  employeeId: string | number;
};

function useNotifications({ client, employeeId }: Props) {
  const connectionRef = useRef(false);
  const queryClient = useQueryClient();
  const { session } = useShellContext();
  const [state, dispatch] = useReducer(reducer, defaultState);

  const rights = get(session, 'data.attributes.rights', []);
  const hasNotificationReadRight = useMemo(
    // @ts-expect-error TS2345: Argument of type 'string' is not assignable to parameter of type 'never'.
    () => rights.includes('notification:read'),
    [rights],
  );

  const {
    unreadNotifications,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    isInitialLoading,
    isSuccess,
    data,
  } = useGetNotificationsInfiniteQuery(
    state.isOpen && hasNotificationReadRight,
  );

  const channel = useRealtimeChannel(`notifications/${employeeId}`, {
    autoConnect: false,
    client,
  });

  const onNewNotification = useCallback(
    (notification: NotificationItem) => {
      return dispatch({
        type: 'NEW_NOTIFICATION',
        data: notification,
      });
    },
    [dispatch],
  );

  const markAllAsRead = useCallback(() => {
    return client.post(`/notifications/read`);
  }, [client]);

  const onToggleMenu = useCallback(
    async (isOpen: boolean) => {
      dispatch({
        type: 'TOGGLE',
        data: isOpen,
      });

      if (isOpen) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disable here to enable the rule globally
        markAllAsRead();
      } else {
        dispatch({
          type: 'RESET',
        });
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disable here to enable the rule globally
        queryClient.resetQueries({
          queryKey: queryKeys.unreadNotifications(),
          exact: true,
        });
      }
    },
    [markAllAsRead, queryClient],
  );

  const onNewNotifications = useCallback(async () => {
    dispatch({
      type: 'RESET',
    });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disable here to enable the rule globally
    queryClient.resetQueries({
      queryKey: queryKeys.unreadNotifications(),
      exact: true,
    });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disable here to enable the rule globally
    markAllAsRead();
  }, [markAllAsRead, queryClient]);

  const markAsViewed = useCallback(
    (id: string) => {
      return client.post(`/notifications/${id}/viewed`);
    },
    [client],
  );

  useEffect(() => {
    let isCancelled = false;
    async function fetchUnreadCount() {
      try {
        const response = await client.get('/notifications/unread/count');
        if (!isCancelled) {
          dispatch({
            type: 'NOTIFICATION_UNREAD_COUNT',
            // @ts-expect-error @peakon/peakon-js TS18046: 'response' is of type 'unknown'.
            data: response.count,
          });
        }
      } catch {
        // ignore
      }
    }
    if (
      channel &&
      employeeId &&
      !connectionRef.current &&
      !proxyViewSnapshot()
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disable here to enable the rule globally
      fetchUnreadCount();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disable here to enable the rule globally
      channel.join();
      channel.on('new', onNewNotification);
      connectionRef.current = true;
    }
    return () => {
      isCancelled = true;
    };
  }, [channel, client, employeeId, onNewNotification]);

  return {
    ...state,
    newNotifications: state.newNotifications.length,
    isEmpty: unreadNotifications.length === 0,
    loadMore: fetchNextPage,
    hasMore: Boolean(hasNextPage),
    isLoading,
    isInitialLoading: Boolean(isInitialLoading),
    isLoadingMore: isFetchingNextPage,
    markAsViewed,
    onNewNotifications,
    onToggleMenu,
    unreadNotifications,
    isSuccess,
    data,
  };
}

// eslint-disable-next-line import/no-default-export
export default useNotifications;
