import { t } from '@peakon/shared/features/i18next/t';
import { type OptionRecord } from '@peakon/shared/types/OptionRecord';

import { type ACTION_STATUS } from '../../../../../../constants/actions';

export const getCategoryGroupDropdownOptionAll = () =>
  ({
    value: 'all',
    label: t('actions__filters__all_actions'),
  }) as const;

export const getCategoryGroupDropdownOptions = ({
  companyName,
}: {
  companyName: string;
}) => {
  return [
    getCategoryGroupDropdownOptionAll(),
    { value: 'engagement', label: t('actions__filters__engagement_actions') },
    {
      value: 'diversity_inclusion',
      label: t('actions__filters__diversity_inclusion_actions'),
    },
    {
      value: 'health_wellbeing',
      label: t('actions__filters__health_wellbeing_actions'),
    },
    {
      value: 'transformation_change',
      label: t('actions__filters__transformation_change_actions'),
    },
    { value: 'onboarding', label: t('actions__filters__onboarding_actions') },
    { value: 'exit', label: t('actions__filters__exit_actions') },
    { value: 'covid19', label: t('actions__filters__covid19_actions') },
    {
      value: 'other',
      label: t('actions__filters__other_actions__v2', {
        replace: { company: companyName },
      }),
    },
    { value: 'values', label: t('actions__filters__values_actions') },
  ] as const;
};

export const QUESTION_SET_OPTIONS = [
  {
    id: 'all',
    key: 'actions__filters__all_actions',
  },
  {
    id: 'engagement',
    key: 'actions__filters__engagement_actions',
  },
  {
    id: 'diversity_inclusion',
    key: 'actions__filters__diversity_inclusion_actions',
  },
  {
    id: 'health_wellbeing',
    key: 'actions__filters__health_wellbeing_actions',
  },
  {
    id: 'transformation_change',
    key: 'actions__filters__transformation_change_actions',
  },
  {
    id: 'covid19',
    key: 'actions__filters__covid19_actions',
  },
  {
    id: 'other',
    key: 'actions__filters__other_actions__v2',
  },
  {
    id: 'values',
    key: 'actions__filters__values_actions',
  },
  {
    id: 'onboarding',
    key: 'actions__filters__onboarding_actions',
  },
  {
    id: 'exit',
    key: 'actions__filters__exit_actions',
  },
];

export const getAccessOptions = () =>
  [
    {
      id: 'shared',
      label: t('actions__v2__visibility_on'),
    },

    {
      id: 'private',
      label: t('actions__v2__visibility_off'),
    },
  ] as const;

export const getAnonymityOptions = () =>
  [
    {
      id: 'all',
      label: t('actions__filters__anonymity__all'),
    },

    {
      id: 'above',
      label: t('actions__filters__anonymity__above'),
    },
  ] as const;

export const getDeadlineOptions = () =>
  [
    {
      id: 'due_this_week',
      label: t('actions__filters__due_this_week'),
    },

    {
      id: 'upcoming',
      label: t('actions__filters__upcoming'),
    },

    {
      id: 'overdue',
      label: t('actions__filters__overdue'),
    },

    {
      id: 'date_not_set',
      label: t('actions__filters__not_set'),
    },
  ] as const;

export const getSortOptions = () =>
  [
    {
      id: 'group',
      label: t('actions__sort__group'),
    },

    {
      id: 'deadline',
      label: t('actions__sort__deadline'),
    },
  ] as const;

export const getStatusOptions = () => {
  return [
    {
      id: 'open',
      label: t('actions__filter__open'),
    },
    {
      id: 'completed',
      label: t('actions__filter__completed'),
    },
    {
      id: 'archived',
      label: t('actions__filter__archived'),
    },
  ] as const satisfies OptionRecord<typeof ACTION_STATUS>;
};
