import { List } from 'immutable';

import BulkNotify from '@peakon/records/BulkNotifyRecord';
import EmployeeRecord from '@peakon/records/EmployeeRecord';

import { type BulkNotifyActions } from '../../actions/BulkNotifyActions';

const bulkNotify = (
  state = new BulkNotify(),
  action: BulkNotifyActions,
): BulkNotify => {
  switch (action.type) {
    case 'BULK_NOTIFY_FLUSH': {
      return new BulkNotify();
    }

    case 'BULK_NOTIFY_LOAD_MEMBERS_SUCCESS': {
      const {
        data,
        links: { next },
        meta: {
          page: { total },
        },
      } = action.data;

      return state.merge({
        members: List(data.map(EmployeeRecord.createFromApi)),
        nextUrl: next,
        total,
        totalFiltered: null,
      });
    }

    case 'BULK_NOTIFY_FILTER_MEMBERS_SUCCESS': {
      const {
        data,
        links: { next },
        meta: {
          page: { total },
        },
      } = action.data;

      return state.merge({
        members: List(data.map(EmployeeRecord.createFromApi)),
        nextUrl: next,
        totalFiltered: total,
      });
    }

    case 'BULK_NOTIFY_LOAD_NEXT_LOADING': {
      return state.set('isLoadingNext', true);
    }

    case 'BULK_NOTIFY_LOAD_NEXT_SUCCESS': {
      const {
        data,
        links: { next },
      } = action.data;

      return state.merge({
        isLoadingNext: false,
        members: state.members?.concat(
          List(data.map(EmployeeRecord.createFromApi)),
        ),
        nextUrl: next,
      });
    }

    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/no-default-export
export default bulkNotify;
