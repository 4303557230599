import { List } from 'immutable';

import { EmployeeKioskSchedule } from '@peakon/records';
import { type KioskEmployeeExportData } from '@peakon/shared/schemas/api/employeeKioskExport';

type Data = {
  data: KioskEmployeeExportData[];
};

type Action = {
  type: `EMPLOYEE_KIOSK_EXPORT_LIST_SCHEDULES_${
    | 'SUCCESS'
    | 'LOADING'
    | 'FAILED'
    | 'CANCELLED'}`;
  data: Data;
};

const employeeKioskExport = (
  state = List<EmployeeKioskSchedule>(),
  action: Action,
) => {
  switch (action.type) {
    case 'EMPLOYEE_KIOSK_EXPORT_LIST_SCHEDULES_SUCCESS': {
      const { data } = action.data;

      return List(data.map(EmployeeKioskSchedule.createFromApi));
    }

    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/no-default-export
export default employeeKioskExport;
