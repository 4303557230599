import { Record, fromJS } from 'immutable';
import { z } from 'zod';

import {
  type AccountResponse,
  ColorModeEnum,
  CommentMachineTranslationModeEnum,
  RoleEnum,
  TwofactorStatusEnum,
} from '@peakon/shared/schemas/api/accounts';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

export type Location = {
  city?: string;
  country?: string;
  countryCode?: string;
};

const schema = z.object({
  bouncedAt: z.string().nullable(),
  bounceReason: z.string().nullable(),
  city: z.string().optional(),
  colorMode: ColorModeEnum,
  companyId: z.number().nullable().optional(),
  countryCode: z.string().optional(),
  country: z.string().optional(),
  commentMachineTranslationMode: CommentMachineTranslationModeEnum,
  email: z.string().nullable(),
  id: z.string(),
  locale: z.string().nullable(),
  localeEffective: z.string().nullable(),
  role: RoleEnum.nullable(),
  settings: z.object({
    colorMode: ColorModeEnum,
    commentMachineTranslationMode: CommentMachineTranslationModeEnum,
    mobileNotifications: z.object({
      actionsReminder: z.boolean(),
      conversation: z.boolean(),
      daily: z.boolean(),
      employeeComment: z.boolean(),
      employeeConversation: z.boolean(),
      sensitiveComments: z.boolean(),
      survey: z.boolean(),
      system: z.boolean(),
      unreadComments: z.boolean(),
    }),
  }),
  timezone: z.string().nullable(),
  timezoneEffective: z.string().nullable(),
  twofactorStatus: TwofactorStatusEnum,
});

type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class Account extends Record<Schema>({
  id: '',
  email: '',
  role: null,
  locale: null,
  timezone: null,
  bouncedAt: null,
  bounceReason: null,
  twofactorStatus: 'disabled',
  localeEffective: null,
  timezoneEffective: null,
  settings: {
    colorMode: 'normal',
    commentMachineTranslationMode: 'original',
    mobileNotifications: {
      actionsReminder: true,
      conversation: true,
      daily: true,
      employeeComment: true,
      employeeConversation: true,
      sensitiveComments: true,
      survey: true,
      system: true,
      unreadComments: true,
    },
  },
  colorMode: 'normal',
  commentMachineTranslationMode: 'original',
  // Location
  city: undefined,
  country: undefined,
  countryCode: undefined,
}) {
  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'Account',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  setLocation({ city, country, countryCode }: Location) {
    return this.merge({
      city,
      country,
      countryCode,
    });
  }

  getLocation(): Location {
    return {
      city: this.city,
      country: this.country,
      countryCode: this.countryCode,
    };
  }

  static createFromApi(data: AccountResponse) {
    const { id, attributes } = data;

    return new Account(
      fromJS({
        id,
        colorMode: attributes?.settings?.colorMode ?? 'normal',
        commentMachineTranslationMode:
          attributes?.settings?.commentMachineTranslationMode ?? 'original',
        ...attributes,
      }),
    );
  }
}
