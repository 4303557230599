import React from 'react';

import { AcknowledgementIcon } from '@peakon/shared/components/AcknowledgementIcon';
import { type AcknowledgementType } from '@peakon/shared/constants/acknowledgementTypes';
import { t } from '@peakon/shared/features/i18next/t';
import emphasize, {
  DEFAULT_EMPHASIS_KEY,
} from '@peakon/shared/utils/emphasizeTranslationString';

import { ConfirmModal } from '../../';
import { useAcknowledgementTranslationMap } from '../AcknowledgementMenu/Button';

import styles from './styles.css';

type Props = {
  type: AcknowledgementType;
  onCancel: () => void;
  onConfirm: (options: { optOut?: boolean }) => Promise<void>;
};

const AcknowledgementModal = ({ onCancel, onConfirm, type }: Props) => {
  const { acknowledgementTranslationMap } = useAcknowledgementTranslationMap();

  return (
    <ConfirmModal
      asyncConfirm
      confirmLabel={t('acknowledge__confirm__button')}
      isOpen
      onConfirm={onConfirm}
      onCancel={onCancel}
      optOut
      title={t('acknowledge__confirm__title')}
      width={680}
    >
      <div className={styles.content}>
        <div className={styles.icon}>
          <AcknowledgementIcon size="huge" type={type} />
        </div>
        <div>
          {emphasize(
            t('acknowledge__confirm__type', {
              replace: {
                type: acknowledgementTranslationMap.get(type),
                emphasis_start: DEFAULT_EMPHASIS_KEY,
                emphasis_end: DEFAULT_EMPHASIS_KEY,
              },
            }),
          )}
        </div>
      </div>
    </ConfirmModal>
  );
};

// eslint-disable-next-line import/no-default-export
export default AcknowledgementModal;
