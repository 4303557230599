import { useMutation, useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router';

import { useConnectIntegrationMutation } from '../../administration/routes/integrations/data/useConnectIntegrationMutation';
import {
  type OAuthService,
  responseCheckerFactory,
} from '../responseCheckerFactory';

const useConnectIntegrationWithOAuthMutation = (id: OAuthService) => {
  const { mutateAsync: connect } = useConnectIntegrationMutation(id);
  const { search: locationSearch } = useLocation();

  return useMutation({
    mutationFn: async () => {
      const { data, error } = responseCheckerFactory(id)(locationSearch);
      if (error) {
        return Promise.reject(error);
      }
      await connect(data);
      return null;
    },
  });
};

export const useConnectIntegrationWithOAuthMutationQuery = (
  id: OAuthService,
) => {
  const { mutateAsync: connectWithOAuth } =
    useConnectIntegrationWithOAuthMutation(id);

  return useQuery({
    queryFn: () => connectWithOAuth(),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};
