import { t } from '@peakon/shared/features/i18next/t';

import exploreMore from './exploreMore.mp4';
import intoTheDetails from './intoTheDetail.mp4';
import welcomeVideo from './welcomeVideo.mp4';
import { type ProductTour } from '../../providers/ProductTourContextProvider/types';

const isMobile = () => window.innerWidth < 768;

const semanticTopics: ProductTour = {
  queryParam: 'showOnboarding',
  uxFlow: {
    entity: 'account',
    key: 'semanticTopicsOnboarding',
  },
  steps: [
    {
      type: 'modal',
      id: 'welcome',
      video: { src: welcomeVideo },
      getTitle: () => t('semantic_topics__onboarding__welcome__title'),
      getText: () => t('semantic_topics__onboarding__welcome__description'),
    },
    {
      type: 'tooltip',
      id: 'sentiments',
      getTitle: () =>
        t('semantic_topics__onboarding__surfacing_sentiment__title'),
      getText: () =>
        t('semantic_topics__onboarding__surfacing_sentiment__description'),
      selector: ['[data-test-id="topic-card"]'],
      verticalOffset: 420,
      placement: 'top-start',
    },
    {
      type: 'tooltip',
      id: 'focusedThemes',
      getTitle: () => t('semantic_topics__onboarding__focused_themes__title'),
      getText: () =>
        t('semantic_topics__onboarding__focused_themes__description'),
      selector: ['[data-test-id="filter-view"]'],
      placement: 'bottom-start',
      shouldRenderStep: () => {
        return Boolean(
          window.document.querySelector('[data-test-id="filter-view"]') &&
            !isMobile(),
        );
      },
    },
    {
      type: 'tooltip',
      id: 'focusedThemesMobile',
      getTitle: () => t('semantic_topics__onboarding__focused_themes__title'),
      getText: () =>
        t('semantic_topics__onboarding__focused_themes__description'),
      selector: ['[data-test-id="mobile-filter-toggler"]'],
      placement: 'bottom-start',
      shouldRenderStep: () => {
        return isMobile();
      },
    },
    {
      type: 'modal',
      id: 'details',
      video: { src: intoTheDetails },
      getTitle: () => t('semantic_topics__onboarding__into_detail__title'),
      getText: () => t('semantic_topics__onboarding__into_detail__description'),
      getPrimaryButtonLabel: () =>
        t('semantic_topics__onboarding__into_detail__next_button_details'),
      getPrimaryButtonUrl: () => {
        return (
          window.document
            .querySelector('[data-test-id="topic-card"] a')
            ?.getAttribute('href') || ''
        );
      },
    },
    {
      type: 'tooltip',
      id: 'insights',
      getTitle: () =>
        t('semantic_topics__onboarding__topics_of_the_moment__title'),
      getText: () =>
        t('semantic_topics__onboarding__topics_of_the_moment__description'),
      waitForElement: '[data-test-id="insights-block"]',
      selector: ['[data-test-id="insights-block"]'],
      verticalOffset: 320,
      placement: 'top-start',
      historyBack: true,
    },
    {
      type: 'tooltip',
      id: 'insights',
      getTitle: () =>
        t('semantic_topics__onboarding__track_long_term_trends__title'),
      getText: () =>
        t('semantic_topics__onboarding__track_long_term_trends__description'),
      waitForElement: '[data-test-id="insights-filters"]',
      selector: ['[data-test-id="insights-filters"]'],
    },
    {
      type: 'tooltip',
      id: 'improving',
      getTitle: () => t('semantic_topics__onboarding__always_improving__title'),
      getText: () =>
        t('semantic_topics__onboarding__always_improving__description'),
      waitForElement: '[data-test-id="comment-container"]',
      selector: ['[data-test-id="comment-feedback-result"]'],
      shouldRenderStep: () => {
        return Boolean(
          window.document.querySelector('[data-test-id="comment-container"]'),
        );
      },
    },
    {
      type: 'modal',
      id: 'exploreMore',
      video: { src: exploreMore },
      getTitle: () => t('semantic_topics__onboarding__explore_more__title'),
      getText: () =>
        t('semantic_topics__onboarding__explore_more__description'),
      getPrimaryButtonLabel: () =>
        t(
          'semantic_topics__onboarding__explore_more__return_to_overview_button',
        ),
      historyBack: true,
      excludeFromProgress: true,
    },
  ],
};

// eslint-disable-next-line import/no-default-export
export default semanticTopics;
