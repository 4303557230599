import React, { useEffect, useState } from 'react';

import classnames from 'classnames';

import { GuidanceInfoIcon as InfoCircle } from '@peakon/bedrock/icons/system';
import { Button } from '@peakon/bedrock/react/button';
import { Divider } from '@peakon/bedrock/react/divider';
import { ToggleSwitch } from '@peakon/bedrock/react/form';
import { Spinner } from '@peakon/bedrock/react/spinner';
import { Heading2, Heading4, BodyText } from '@peakon/bedrock/react/typography';
import { InputField, RadioGroup, Tooltip } from '@peakon/components';

import useFeatureFlips, { type FeatureFlip } from './queries/useFeatureFlips';
import { useUpdateFeatureFlips } from './queries/useUpdateFeatureFlips';

import styles from './styles.css';

// eslint-disable-next-line no-restricted-syntax
enum FlipValue {
  ENABLED = 1,
  DISABLED = 2,
  DEFAULT = 3,
}

export type GodModeModalProps = {
  onClose: () => void;
  companyName: string;
  onError: (error: Error) => void;
};
export const GodModeModal = ({
  onClose,
  companyName,
  onError,
}: GodModeModalProps) => {
  const { data: featureFlips, isLoading } = useFeatureFlips();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredFeatureFlips, setFilteredFeatureFlips] =
    useState<FeatureFlip[]>();
  const [changedFeatureFlips, setChangedFeatureFlips] =
    useState<FeatureFlip[]>();

  const handleError = (error: Error) => {
    onError(error);
  };

  const { mutateAsync: updateFeatureFlips, isLoading: isUpdatingFeatureFlips } =
    useUpdateFeatureFlips({
      onError: handleError,
    });

  useEffect(() => {
    if (!isLoading) {
      setFilteredFeatureFlips(featureFlips);
      // clean on load
      setChangedFeatureFlips([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const search = (value: string) => {
    setSearchTerm(value);
    if (value) {
      setFilteredFeatureFlips(
        featureFlips?.filter((it) =>
          it.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
        ),
      );
    } else {
      setFilteredFeatureFlips(featureFlips);
    }
  };

  const handleSave = async () => {
    const changed =
      changedFeatureFlips?.filter((feature) => {
        if (featureFlips) {
          const currentValue = featureFlips.find((it) => it.id === feature.id);
          if (
            currentValue &&
            (feature.defaultValue !== currentValue.defaultValue ||
              feature.userValue !== currentValue.userValue)
          ) {
            return true;
          }
        }
        return false;
      }) ?? [];

    if (changed.length > 0) {
      await updateFeatureFlips(changed);
      window.location.reload();
    }
  };

  const calculateValue = (featureItem: FeatureFlip) => {
    if (featureItem.userValue !== null) {
      if (featureItem.userValue) {
        return FlipValue.ENABLED;
      }
      return FlipValue.DISABLED;
    }
    return FlipValue.DEFAULT;
  };

  const setValue = (item: FeatureFlip, value: number) => {
    const newItems = filteredFeatureFlips?.map((feature) => {
      if (feature.name === item.name) {
        switch (value) {
          case FlipValue.ENABLED:
            // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
            feature.userValue = true;
            break;
          case FlipValue.DISABLED:
            // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
            feature.userValue = false;
            break;
          case FlipValue.DEFAULT:
            // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
            feature.userValue = null;
            break;
        }

        return feature;
      }
      return feature;
    });

    setChangedFeatureFlips(newItems);
    setFilteredFeatureFlips(newItems);
  };

  const resetToDefault = () => {
    const newItems = filteredFeatureFlips?.map((feature) => {
      // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
      feature.userValue = null;

      return feature;
    });

    setChangedFeatureFlips(newItems);

    setFilteredFeatureFlips(newItems);
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <Heading2 level={1}>{companyName}</Heading2>
      </div>

      <ToggleTranslationKeys />

      <Divider />

      <div className={styles.subTitle}>
        <Heading4 level={2}>Feature Flips</Heading4>
      </div>

      <Divider />

      <div className={styles.tabsRoot}>
        <div className={styles.flipsHeader}>
          <InputField
            className={styles.search}
            value={searchTerm}
            onChange={(value) => search(value)}
            onClear={() => setSearchTerm('')}
            placeholder="Search for a feature"
            clearLabel="Clear"
          />
          <Button variant="warning" onClick={resetToDefault}>
            Reset to default
          </Button>
        </div>
        {isLoading ? (
          <div className={styles.loading}>
            <Spinner size="48" />
          </div>
        ) : (
          <div className={styles.flipsContainer}>
            {filteredFeatureFlips?.map((item, index) => (
              <div
                key={index}
                className={classnames(styles.row, {
                  [styles.rowAlternate]: index % 2 === 0,
                })}
              >
                <div className={styles.featureName}>
                  <BodyText>{item.name}</BodyText>
                  {item.description && (
                    <Tooltip title={item.description}>
                      <InfoCircle aria-hidden />
                    </Tooltip>
                  )}
                </div>

                <div className={styles.scale}>
                  <RadioGroup
                    orientation="horizontal"
                    variant="card"
                    value={calculateValue(item)}
                    onChange={(value) => {
                      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                      setValue(item, value as number);
                    }}
                  >
                    <RadioGroup.Button value={FlipValue.ENABLED}>
                      Enabled
                    </RadioGroup.Button>

                    <RadioGroup.Button value={FlipValue.DISABLED}>
                      Disabled
                    </RadioGroup.Button>

                    <RadioGroup.Button value={FlipValue.DEFAULT}>
                      {`Default (${
                        item.defaultValue ? 'Enabled' : 'Disabled'
                      } )`}
                    </RadioGroup.Button>
                  </RadioGroup>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className={styles.saveButtons}>
        <Button
          variant="primary"
          disabled={isUpdatingFeatureFlips}
          onClick={handleSave}
        >
          Save & Reload
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

function ToggleTranslationKeys() {
  const isShowing =
    window.localStorage.getItem('peakon.showTranslationKeys') === 'true';

  return (
    <div className={styles.toggleTranslationKeys}>
      <ToggleSwitch
        label="Show Translation Keys (instead of translations)"
        checked={isShowing}
        onChange={() => {
          window.localStorage.setItem(
            'peakon.showTranslationKeys',
            (!isShowing).toString(),
          );
          window.location.reload();
        }}
      />
    </div>
  );
}
