import { useMemo } from 'react';

import {
  type Requirements,
  createAccessChecker,
} from '@peakon/shared/utils/access';
import { useShellContext } from '@peakon/shell';

export const useRouteAccess = (requirements: Requirements) => {
  const { context, session } = useShellContext();

  const accessCheck = useMemo(
    () =>
      createAccessChecker({
        session: session?.data,
        context,
      }),
    [session, context],
  );

  return accessCheck(requirements);
};
