import { OrderedMap } from 'immutable';
import indexOf from 'lodash/indexOf';
import sortBy from 'lodash/sortBy';

import { ActionsGroup } from '@peakon/records';
import Action from '@peakon/records/ActionRecord';

import { QUESTION_SET_OPTIONS } from '../../containers/insight/routes/actions/components/ActionFilters/constants';

type State = OrderedMap<string, ActionsGroup>;

const groups = (
  state = OrderedMap<string, ActionsGroup>(),
  action: $TSFixMe,
): State => {
  switch (action.type) {
    case 'ACTION_GROUPS_LIST_SUCCESS': {
      const { data } = action.data;

      const groupsOrderOptions = QUESTION_SET_OPTIONS.map((group) => group.id);

      // If the group is a segment group, display it at the end
      // Otherwise, display groups in order from constant array
      const sortedData = sortBy(data, (group) => {
        // segment actions
        if (group.relationships.segment) {
          return 20000;
          // unassigned actions
        } else if (group.id.includes('unassigned')) {
          return 10000;
        }

        return indexOf(
          groupsOrderOptions,
          group.relationships.actions[0].attributes.categoryGroup,
        );
      });

      return OrderedMap(
        sortedData.map((group) => [
          group.attributes.group,
          // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
          ActionsGroup.createFromApi(group),
        ]),
      );
    }

    case 'ACTION_REMOVE': {
      const { id, group } = action.data;

      if (!state.has(group)) {
        return state;
      }

      const newState = state.updateIn([group, 'actions'], (actions) =>
        // @ts-expect-error TS(7006): Parameter 'item' implicitly has an 'any' type.
        actions?.filter((item) => item?.id !== id),
      );

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return newState.filter((item) => Boolean(item?.actions?.size)) as State;
    }

    case 'ACTION_UPDATE_SUCCESS': {
      const { id, group, attributes, data } = action.data;

      if (!state.has(group)) {
        return state;
      }

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return state.updateIn([group, 'actions'], (actions) => {
        if (!actions) {
          return;
        }

        // @ts-expect-error TS(7006): Parameter 'a' implicitly has an 'any' type.
        const index = actions.findIndex((a) => a.id === id);
        if (index === -1) {
          return;
        }

        if (data) {
          return actions.updateIn([index], () => Action.createFromApi(data));
        }

        // @ts-expect-error TS(7006): Parameter 'a' implicitly has an 'any' type.
        return actions.updateIn([index], (a) => a.merge(attributes));
      }) as State;
    }

    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/no-default-export
export default groups;
