import first from 'lodash/first';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { type Category } from '@peakon/records';
import { type CategoryGroup } from '@peakon/records/constants/questionSets';

import {
  type ContextCategoryGroupResponse,
  type ContextResponse,
} from '../../schemas/api/contexts';

export type CategoryGroupAccessType =
  | 'partial'
  | 'overview'
  | 'full'
  | 'questionSet'
  | null;

type ContextCategoryGroup = {
  access: CategoryGroupAccessType;
  hasOtherDrivers: boolean;
  hasOutcome: boolean;
} & ContextCategoryGroupResponse;

const service = {
  hasFullAccess(group: CategoryGroup, context: ContextResponse) {
    return this.hasGroupAccess(group, context, 'full');
  },

  hasOverviewAccess(group: CategoryGroup, context?: ContextResponse | null) {
    return this.hasGroupAccess(group, context, 'overview');
  },

  hasGroupAccess(
    group: CategoryGroup | null,
    context?: ContextResponse | null,
    accessType: CategoryGroupAccessType = 'partial',
  ) {
    if (!context) {
      return false;
    } else if (!group) {
      return true;
    }

    return this.getCategoryGroups(context).some(
      // @ts-expect-error TS2345: Argument of type '(categoryGroup: ContextCategoryGroup) => boolean | ...
      (categoryGroup: ContextCategoryGroup) => {
        let access;

        if (accessType === 'partial') {
          access = true;
        }

        if (accessType === 'overview') {
          access = categoryGroup.hasOutcome;
        }

        if (accessType === 'full') {
          access = categoryGroup.access === 'full';
        }

        return categoryGroup.id === group && access;
      },
    );
  },

  getMainCategoryGroup(
    context?: ContextResponse | null,
    filter: CategoryGroupAccessType = null,
  ) {
    const categoryGroups: ContextCategoryGroup[] = this.getCategoryGroups(
      context,
      filter,
      // @ts-expect-error TS2769: No overload matches this call.
    ).filter((group: ContextCategoryGroup) => group.id !== 'text');

    if (!isEmpty(categoryGroups)) {
      return first(categoryGroups)?.id;
    }

    return null;
  },

  getMainCategoryGroupWithDrivers(context: ContextResponse | null | undefined) {
    const categoryGroupsWithDrivers = this.getCategoryGroups(context).filter(
      // @ts-expect-error TS2769: No overload matches this call.
      (group: ContextCategoryGroup) => group.hasOtherDrivers,
    );

    // @ts-expect-error TS2339: Property 'id' does not exist on type '{ attributes: { nameTranslated:
    return categoryGroupsWithDrivers[0] && categoryGroupsWithDrivers[0].id
      ? // @ts-expect-error TS2339: Property 'id' does not exist on type '{ attributes: { nameTranslated:
        categoryGroupsWithDrivers[0].id
      : null;
  },

  getQuestionSetGroup(context?: ContextResponse | null) {
    return this.getMainCategoryGroup(context, 'questionSet');
  },

  getOverviewGroup(context: ContextResponse) {
    const groups = this.getCategoryGroups(context, 'overview');
    const overviewAccess = groups.find(
      // @ts-expect-error TS2769: No overload matches this call.
      (group: ContextCategoryGroup) => group.hasOutcome,
    );

    // @ts-expect-error TS2339: Property 'id' does not exist on type '{ attributes: { nameTranslated:
    return overviewAccess ? overviewAccess.id : null;
  },

  hasCategoryAccess(
    context: ContextResponse | null,
    category: Category | null,
  ) {
    if (!category || !context) {
      return false;
    }

    const groups = this.getCategoryGroups(context);

    const categoryGroup = groups.find(
      // @ts-expect-error TS2339: Property 'id' does not exist on type '{ attributes: { nameTranslated:
      (group: ContextCategoryGroup) => group.id === category.group,
    );

    if (!categoryGroup) {
      return false;
    }

    // @ts-expect-error TS2339: Property 'access' does not exist on type '{ attributes: { nameTranslated:
    if (categoryGroup.access === 'full') {
      return true;
    }

    return (
      // @ts-expect-error TS2339: Property 'categoryIds' does not exist on type '{ attributes: {
      categoryGroup.categoryIds &&
      // @ts-expect-error TS2339: Property 'categoryIds' does not exist on type '{ attributes: {
      (categoryGroup.categoryIds.includes(category.id) ||
        // @ts-expect-error TS2339: Property 'categoryIds' does not exist on type '{ attributes: {
        categoryGroup.categoryIds.includes(category.parentCategoryId))
    );
  },

  getCategoryGroups(
    context?: ContextResponse | null,
    filter: CategoryGroupAccessType = null,
  ) {
    const categoryGroups = get(
      context,
      'relationships.contextCategoryGroups',
      [],
    ).map((contextCategoryGroup: ContextCategoryGroupResponse) => {
      const categoryGroup = get(
        contextCategoryGroup,
        'relationships.categoryGroup',
        {},
      );
      // @ts-expect-error TS2339: Property 'hasOutcome' does not exist on type '{ attributes: {
      categoryGroup.hasOutcome = contextCategoryGroup.attributes.hasOutcome;
      // @ts-expect-error TS2339: Property 'access' does not exist on type '{ attributes: {
      categoryGroup.access = contextCategoryGroup.attributes.access;
      // @ts-expect-error TS2339: Property 'hasOtherDrivers' does not exist on type '{ attributes: {
      categoryGroup.hasOtherDrivers =
        contextCategoryGroup.attributes.hasOtherDrivers;
      // @ts-expect-error TS2339: Property 'categoryIds' does not exist on type '{ attributes: {
      categoryGroup.categoryIds = contextCategoryGroup.relationships.categories
        ? contextCategoryGroup.relationships.categories.map(
            (category) => category.id,
          )
        : null;

      return categoryGroup;
    });

    // @ts-expect-error TS2769: No overload matches this call.
    return categoryGroups.filter((group: ContextCategoryGroup) => {
      if (filter === 'overview') {
        return group.hasOutcome;
      }

      if (filter === 'questionSet') {
        return group.id !== 'values' && group.id !== 'text';
      }

      return true;
    });
  },
};

// eslint-disable-next-line import/no-default-export
export default service;
