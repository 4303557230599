import React from 'react';

import { useHistory } from 'react-router-dom';

import { UtilityCheckCircleIcon } from '@peakon/bedrock/icons/system';
import { Button } from '@peakon/bedrock/react/button';
import { Modal } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';

import ProgressBar from '../ProgressBar/ProgressBar';
import { type ProductTourModal } from '../types';

import styles from './styles.css';

type Props = {
  currentStep: number;
  onClose: () => void;
  onStepClick: (step: number) => void;
  step: ProductTourModal;
  totalSteps: number;
  isFirst: boolean;
  isLast: boolean;
};

// eslint-disable-next-line import/no-default-export
export default function ModalFooter({
  currentStep,
  onClose,
  onStepClick,
  step,
  totalSteps,
  isFirst,
  isLast,
}: Props) {
  const history = useHistory();

  if (step.hideFooter) {
    return null;
  }

  if (isLast) {
    return (
      <Modal.Footer className={styles.footer}>
        <div className={styles.completed}>
          {t('product_tour__complete')}
          <UtilityCheckCircleIcon />
        </div>
        <Button
          variant="primary"
          onClick={() => {
            if (step.historyBack) {
              history.go(-1);
            }

            onClose();
          }}
          data-test-id="product-tour-complete"
        >
          {step.getPrimaryButtonLabel
            ? step.getPrimaryButtonLabel()
            : t('common__close')}
        </Button>
      </Modal.Footer>
    );
  }

  const buttonLabel = step.getPrimaryButtonLabel
    ? step.getPrimaryButtonLabel()
    : t('onboarding__modal_next');

  return (
    <Modal.Footer className={styles.footer}>
      <div className={styles.progress}>
        <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
      </div>
      <div className={styles.actions}>
        {!isFirst && (
          <Button
            onClick={() => onStepClick(currentStep - 1)}
            data-test-id="product-tour-back"
            variant="secondary"
          >
            {t('common__back')}
          </Button>
        )}
        <Button
          data-test-id="product-tour-next"
          variant="primary"
          onClick={() => {
            if (step.getPrimaryButtonUrl) {
              const url = step.getPrimaryButtonUrl();
              history.push(url);
            }

            onStepClick(currentStep + 1);
          }}
        >
          {isFirst && !step.getPrimaryButtonLabel
            ? t('product_tour__show_how_it_works')
            : buttonLabel}
        </Button>
      </div>
    </Modal.Footer>
  );
}
