import { v4 as uuidv4 } from 'uuid';

import api from '@peakon/shared/utils/api';

import { type GetState, type Dispatch } from '../types/redux';
import { asyncDispatch } from '../utils';

async function fetchOptionAttributes(params: $TSFixMe) {
  const response = await api.attribute.list(params);
  const attributes = response.data.filter(
    (attribute: $TSFixMe) => attribute.attributes.type === 'option',
  );

  return { data: attributes };
}

export const listOptions = (params: $TSFixMe) => (dispatch: Dispatch) =>
  asyncDispatch({
    dispatch,
    resource: 'ATTRIBUTE_READ',
    action: fetchOptionAttributes(params),
  });

export const list =
  (params?: { [x: string]: $TSFixMe }) =>
  (dispatch: Dispatch, getState: GetState) => {
    const {
      features,
      company: { addOns },
    } = getState();

    return asyncDispatch({
      dispatch,
      resource: 'ATTRIBUTE_READ',
      action: api.attribute.list(params),
      data: {
        features: features.toArray(),
        hasEphemeralEmployees: addOns.includes('kiosk_ephemeral'),
      },
    });
  };

export const getAttributeOptions =
  (id: string, query: string) => (dispatch: Dispatch) => {
    const params: { [x: string]: $TSFixMe } = { translated: true };

    if (query) {
      params.q = query;
    }

    return asyncDispatch({
      dispatch,
      data: {
        id,
      },

      resource: 'ATTRIBUTE_GET_OPTIONS',
      action: api.attribute.getOptions(id, params),
    });
  };

export const append =
  (params: { [x: string]: $TSFixMe }) => (dispatch: Dispatch) => {
    return asyncDispatch({
      dispatch,
      resource: 'ATTRIBUTE_APPEND',
      action: api.attribute.list(params),
    });
  };

export const onAddOption = (attributeId: string, value: string) => ({
  type: 'ATTRIBUTE_OPTION_ADDED',

  data: {
    id: attributeId,
    option: {
      id: uuidv4(),
      title: value,
      type: 'attribute_options',
      isNew: true,
    },
  },
});

export const onRemoveOption = (attributeId: string, optionId: string) => ({
  type: 'ATTRIBUTE_OPTION_REMOVED',

  data: {
    id: attributeId,
    optionId,
  },
});

export const updateAttribute =
  (id: string) => (dispatch: Dispatch, getState: GetState) => {
    const { attributes } = getState();
    const attribute = attributes.find((attr) => attr.id === id);

    return asyncDispatch({
      dispatch,
      resource: 'ATTRIBUTE_UPDATE',
      action: api.attribute.update(attribute.id, null, {
        data: attribute.toJsonApi(),
      }),
    });
  };
