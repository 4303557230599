import React from 'react';

import { useHistory } from 'react-router-dom';

import { Button } from '@peakon/bedrock/react/button';
import { Modal } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';

import { type ProductTourModal } from '../types';

type Props = {
  onClose: () => void;
  onStepClick: (step: number) => void;
  step: ProductTourModal;
  isFirst: boolean;
  isLast: boolean;
};

// eslint-disable-next-line import/no-default-export
export default function ModalHeaderButton({
  step,
  onClose,
  onStepClick,
  isFirst,
  isLast,
}: Props) {
  const history = useHistory();

  const onReplayClick = () => {
    if (step.historyBack) {
      history.go(-1);
    }

    onStepClick(1);
  };

  if (isLast) {
    return (
      <Button
        variant="tertiary"
        data-test-id="product-tour-replay"
        onClick={onReplayClick}
      >
        {t('product_tour__replay')}
      </Button>
    );
  }

  if (isFirst) {
    return (
      <Button
        variant="tertiary"
        onClick={onClose}
        data-test-id="product-tour-skip"
      >
        {t('product_tour__skip')}
      </Button>
    );
  }

  return (
    <Modal.CloseButton
      closeLabel={t('common__close')}
      onClick={onClose}
      testId="product-tour-close-icon"
    />
  );
}
