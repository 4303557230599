import React, { Fragment } from 'react';

import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { NavigationBasicNavigationChevronRightIcon as ArrowRight } from '@peakon/bedrock/icons/system';
import { Spinner } from '@peakon/bedrock/react/spinner';
import { type TranslatedString, t } from '@peakon/shared/features/i18next/t';

import { StatusIndicator } from '../StatusIndicator';
import Tooltip from '../Tooltip';

import styles from './styles.css';

type ResourceListProps = {
  children?: React.ReactNode;
};

const ResourceList = ({ children }: ResourceListProps) => (
  <div className={styles.container}>{children}</div>
);

type HeaderProps = {
  children?: React.ReactNode;
};

const Header = ({ children }: HeaderProps) => (
  <div className={styles.header}>{children}</div>
);

type TitleProps = {
  title: TranslatedString;
  count?: number;
};

const Title = ({ title, count }: TitleProps) => (
  <div className={styles.meta}>
    <span className={styles.title}>{title}</span>
    {typeof count === 'number' && count > 0 && (
      <div className={styles.count}>({count})</div>
    )}
  </div>
);

type SearchProps = {
  children?: React.ReactNode;
};

const Search = ({ children }: SearchProps) => (
  <div className={styles.search}>{children}</div>
);

type EmptyProps = {
  children?: React.ReactNode;
};

const Empty = ({ children }: EmptyProps) => (
  <div className={styles.header}>{children}</div>
);

type ContentProps = {
  children?: React.ReactNode;
  className?: string;
  testId?: string;
};

const Content = ({ children, className, testId }: ContentProps) => (
  <div className={classnames(styles.list, className)} data-test-id={testId}>
    {children}
  </div>
);

type RowProps = {
  className?: string;
  children?: React.ReactNode;
  to?: string;
  testId?: string;
  style?: $TSFixMe;
};

const Row = ({ to, testId, children, style, className }: RowProps) =>
  to ? (
    <Link
      to={to}
      className={classnames(styles.row, styles.link, className)}
      data-test-id={testId}
      style={style}
    >
      <div className={styles.content}>{children}</div>
      <div className={styles.arrow}>
        <ArrowRight />
      </div>
    </Link>
  ) : (
    <div
      className={classnames(styles.row, className)}
      data-test-id={testId}
      style={style}
    >
      <div className={styles.content}>{children}</div>
    </div>
  );

type ResourceProps = {
  name?: string;
  count?: number;
  disabled?: boolean;
  expanded?: boolean;
  size?: 'medium' | 'large';
};

const Resource = ({
  name,
  count,
  disabled,
  expanded,
  size = 'medium',
}: ResourceProps) => (
  <Fragment>
    <div
      className={classnames(styles.resourceTitle, {
        [styles[size]]: size,
      })}
    >
      {name}
    </div>
    <div
      className={classnames(styles.resourceMeta, {
        [styles.expanded]: expanded,
      })}
    >
      {expanded && (
        <Fragment>
          <div>
            <StatusIndicator active={!disabled} />
          </div>
          <div className={styles.separator} />
        </Fragment>
      )}

      <span className={styles.resourceSubtitle}>
        {t('members__count', {
          replace: { count },
        })}
      </span>
    </div>
    {!expanded && disabled && (
      <div className={styles.disabledTooltip}>
        <Tooltip title={t('access_group__status__disabled')}>
          <div className={styles.disabled} />
        </Tooltip>
      </div>
    )}
  </Fragment>
);

const Loader = () => (
  <div className={styles.loader}>
    <Spinner />
  </div>
);

ResourceList.Header = Header;
ResourceList.Title = Title;
ResourceList.Content = Content;
ResourceList.Row = Row;
ResourceList.Empty = Empty;
ResourceList.Search = Search;
ResourceList.Resource = Resource;
ResourceList.Loader = Loader;

// eslint-disable-next-line import/no-default-export
export default ResourceList;
