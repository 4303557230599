import React from 'react';

import { Link } from 'react-router-dom';

import { NavigationBasicNavigationChevronRightThickIcon as ArrowRight } from '@peakon/bedrock/icons/system';
import { BodyText } from '@peakon/bedrock/react/typography';
import { VisuallyHidden } from '@peakon/bedrock/react/visually-hidden';
import type CategoryRecord from '@peakon/records/CategoryRecord';
import { Flex } from '@peakon/shared/components/Flex/Flex';
import { t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

type CommentDriverTooltipProps = {
  category: CategoryRecord;
  contextId?: string;
  segmentId?: string;
};

export const CommentDriverLabel = ({
  category,
  contextId,
  segmentId,
}: CommentDriverTooltipProps) => {
  return (
    <BodyText as="div" bold variant="hint" size="small">
      <Flex alignItems="center" flexWrap="wrap">
        {category?.parentCategory && (
          <Link to={category.parentCategory.getUrl({ contextId, segmentId })}>
            <VisuallyHidden>{t('input__driver')}</VisuallyHidden>
            {category.parentCategory.nameTranslated}
          </Link>
        )}

        {category?.parentCategory && <ArrowRight className={styles.arrow} />}

        <Link to={category.getUrl({ contextId, segmentId })}>
          <VisuallyHidden>
            {category?.parentCategory
              ? t('input__subdriver')
              : t('input__driver')}
          </VisuallyHidden>
          {category?.nameTranslated}
        </Link>
      </Flex>
    </BodyText>
  );
};
