import { type AuthType } from './types';

class RequirementEvaluator {
  auth: AuthType;
  cache: $TSFixMe;
  requirementEvaluators: $TSFixMe;
  constructor(auth: AuthType, requirementEvaluators: $TSFixMe) {
    this.auth = auth;
    this.requirementEvaluators = requirementEvaluators;
    this.cache = new Map();
  }

  // @ts-expect-error TS(7023) FIXME: 'evaluate' implicitly has return type 'any' becaus... Remove this comment to see the full error message
  evaluate(requirement: $TSFixMe, rightRequirements: $TSFixMe) {
    if (requirement.evaluate) {
      return requirement.evaluate(this, rightRequirements);
    } else {
      let result = this.cache.get(requirement);

      if (typeof result === 'undefined') {
        const evaluator = this.requirementEvaluators[requirement];

        if (!evaluator) {
          if (rightRequirements[requirement]) {
            return this.evaluate(
              rightRequirements[requirement],
              rightRequirements,
            );
          } else {
            throw new Error(`No right evaluator for "${requirement}"`);
          }
        }

        result = evaluator(this.auth);
        this.cache.set(requirement, result);
      }

      return result;
    }
  }
}

// eslint-disable-next-line import/no-default-export
export default RequirementEvaluator;
