import { t } from '@peakon/shared/features/i18next/t';

import { type ProductTour } from '../../providers/ProductTourContextProvider/types';

const prioritiesOnboarding: ProductTour = {
  queryParam: 'showOnboarding',
  uxFlow: {
    entity: 'account',
    key: 'prioritiesOnboarding',
  },
  steps: [
    {
      type: 'tooltip',
      id: 'suggested-priority',
      getTitle: () => t('suggested_priorities_onboarding__title'),
      getText: () => t('suggested_priorities_onboarding__text'),
      selector: [
        '[data-test-id="set-priority-button"]',
        '[id="suggested-priority-button-container"]',
      ],
      verticalOffset: 250,
      excludeFromProgress: true,
      hideFooter: true,
      highlightStyle: {
        padding: 20,
      },
    },
  ],
};

// eslint-disable-next-line import/no-default-export
export default prioritiesOnboarding;
