import React from 'react';

import DoneImage from '@peakon/shared/assets/images/temporary/manager-onboarding/done.png';
import DoneImage2x from '@peakon/shared/assets/images/temporary/manager-onboarding/done@2x.png';
import SurveyImage from '@peakon/shared/assets/images/temporary/manager-onboarding/survey.png';
import SurveyImage2x from '@peakon/shared/assets/images/temporary/manager-onboarding/survey@2x.png';
import WelcomeImage from '@peakon/shared/assets/images/temporary/manager-onboarding/welcome.png';
import WelcomeImage2x from '@peakon/shared/assets/images/temporary/manager-onboarding/welcome@2x.png';
import { t } from '@peakon/shared/features/i18next/t';

import { DriversStepTitle, DriversStepText } from './DriversTooltipContent';
import { type ProductTour } from '../../providers/ProductTourContextProvider/types';

const managerOnboarding: ProductTour = {
  queryParam: 'showOnboarding',
  uxFlow: {
    entity: 'account',
    key: 'seenManagerOnboarding',
  },
  steps: [
    {
      type: 'modal',
      id: 'welcome',
      getTitle: () => t('manager_onboarding__welcome__title'),
      getText: () => t('manager_onboarding__welcome__text'),
      illustration: { srcSet: [WelcomeImage, WelcomeImage2x] },
    },
    {
      type: 'modal',
      id: 'survey',
      getTitle: () => t('manager_onboarding__survey__title'),
      getText: () => t('manager_onboarding__survey__text'),
      illustration: { srcSet: [SurveyImage, SurveyImage2x] },
    },
    {
      type: 'tooltip',
      id: 'score',
      getTitle: () => t('manager_onboarding__score__title'),
      getText: () => t('manager_onboarding__score__text'),
      selector: [
        '[data-test-id="main-score-passives"]',
        '[data-test-id="group-main-score"]',
      ],
      verticalOffset: 140,
      placement: 'bottom-start',
      highlightStyle: {
        padding: 24,
      },
    },
    {
      type: 'tooltip',
      id: 'benchmark',
      getTitle: () => t('manager_onboarding__benchmark__title'),
      getText: () => t('manager_onboarding__benchmark__text'),
      selector: '[data-test-id="score-card-benchmark"]',
      verticalOffset: 130,
      placement: 'right-start',
      highlightStyle: {
        padding: 32,
      },
    },
    {
      type: 'tooltip',
      id: 'drivers',
      selector: [
        '[data-test-id^="priority-card"]',
        '[data-test-id="key-driver-card"]',
      ],
      getTitle: () => <DriversStepTitle />,
      getText: () => [
        <DriversStepText key="1" paragraph={1} />,
        <DriversStepText key="2" paragraph={2} />,
      ],
      shouldRenderStep: () => {
        return Boolean(
          window.document.querySelector('[data-test-id="key-drivers-title"]'),
        );
      },
      verticalOffset: 260,
      placement: 'top',
    },
    {
      type: 'tooltip',
      id: 'comments',
      contextRights: [
        'comment:driver:read',
        'comment:text:read',
        'comment:value:read',
      ],
      selector: '[data-test-id="comment-summary"]',
      waitForElement: '[data-test-id="comment-summary-container"]',
      getTitle: () => t('manager_onboarding__comments__title'),
      getText: () => t('manager_onboarding__comments__text'),
      verticalOffset: 260,
      placement: 'top',
      shouldRenderStep: () => {
        return Boolean(
          window.document.querySelector(
            '[data-test-id="comment-summary-container"]',
          ),
        );
      },
    },
    {
      type: 'tooltip',
      id: 'help',
      selector: '#app-header-help-target',
      getTitle: () => t('manager_onboarding__help__title'),
      getText: () => t('manager_onboarding__help__text'),
      parentElement: window.document.body,
      placement: 'bottom-end',
      showOnSkip: true,
    },
    {
      type: 'modal',
      id: 'done',
      getTitle: () => t('manager_onboarding__done__title'),
      getText: () => t('manager_onboarding__done__text'),
      illustration: { srcSet: [DoneImage, DoneImage2x] },
      excludeFromProgress: true,
    },
  ],
};

// eslint-disable-next-line import/no-default-export
export default managerOnboarding;
