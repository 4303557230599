import { zodResolver } from '@hookform/resolvers/zod';
import {
  type FieldValues,
  useForm,
  type UseFormProps,
  type UseFormReturn,
} from 'react-hook-form';
import { type ZodErrorMap } from 'zod';
import type z from 'zod';

import { getErrorMap } from '../errorMap';

export type UseZodFormProps<TSchema extends z.Schema> = {
  schema: TSchema;
  customErrorMap?: () => ZodErrorMap;
} & Omit<UseFormProps<z.infer<TSchema>>, 'resolver'>;

export const useZodForm = <
  TSchema extends z.Schema,
  TFieldValues extends FieldValues = z.infer<TSchema>,
>(
  props: UseZodFormProps<TSchema>,
): UseFormReturn<TFieldValues> => {
  const { schema, customErrorMap, ...rest } = props;

  const errorMap = customErrorMap?.() ?? getErrorMap();

  return useForm<TFieldValues>({
    resolver: zodResolver(schema, {
      errorMap,
    }),
    ...rest,
  });
};
