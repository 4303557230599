import { z } from 'zod';

import { isPreviewApp } from './isPreviewApp';
import { validateData } from './validateData/validateData';
import { APP_SUBDOMAIN } from '../features/subdomains/constants';
import { getCurrentSubdomain } from '../features/subdomains/getCurrentSubdomain';

const envSchema = z.object({
  api: z.string(),
  appendLiveToDBAlertsEnabled: z.boolean(),
  bamboohr: z.object({
    clientId: z.string(),
  }),
  clusterEnv: z
    .union([
      z.literal('development'),
      z.literal('demo'),
      z.literal('impl'),
      z.literal('production'),
      z.literal('preview'),
      z.literal('staging'),
      z.literal('test'),
    ])
    .optional(),
  debugEnabled: z.boolean(),
  displayAppVersionEnabled: z.boolean(),
  enablePrereleaseLanguages: z.boolean(),
  forceShowRecordSchemaErrors: z.boolean(),
  forceVerifyCustomSenderDomainEnabled: z.boolean(),
  host: z.string(),
  microsoftTeams: z.object({
    clientId: z.string().nullable(),
    api: z.string(),
  }),
  mixpanel: z.union([
    z.object({
      enabled: z.literal(true),
      projectToken: z.string(),
      host: z.string(),
    }),
    z.object({
      enabled: z.literal(false),
      projectToken: z.string().optional(),
      host: z.string().optional(),
    }),
  ]),
  realtime: z.object({
    api: z.string(),
    socket: z.string(),
  }),
  rollbar: z.object({
    client: z.string().nullable(),
  }),
  saml: z
    .object({
      redirectTo: z.string().optional(),
      response: z.string(),
    })
    .optional(),
  slack: z.object({
    clientId: z.string(),
  }),
  statusPage: z.object({
    host: z.string(),
  }),
  sprig: z.object({
    environmentId: z.string().nullable(),
  }),
  survey: z.object({
    host: z.string(),
  }),
  training: z.string(),
  version: z.string(),
});

export type EnvType = z.infer<typeof envSchema>;

declare global {
  // eslint-disable-next-line no-var
  var ENV: EnvType;
}

// Disabling eslint rule here because we need to access the global ENV variable to validate it
// eslint-disable-next-line no-restricted-globals
const envConfig = ENV;
const currentSubdomain = getCurrentSubdomain();

if (currentSubdomain !== APP_SUBDOMAIN) {
  if (isPreviewApp()) {
    envConfig.training = `https://${currentSubdomain}.staging-peakon.com`;
    envConfig.survey.host = `https://${currentSubdomain}.staging-peakon.com`;
  } else {
    envConfig.training = window.location.origin;
    envConfig.survey.host = window.location.origin;
  }
}

export const validatedEnvConfig = validateData(envConfig, envSchema, {
  errorMessagePrefix: 'envSchema',
});
