import React, { type ReactNode } from 'react';

import omit from 'lodash/omit';
import { connect } from 'react-redux';

import { type RootState } from '../../types/redux';
import Maybe from '../Maybe';

type FeatureFilterProps = {
  feature: string;
  children: ReactNode | ((isActive: boolean) => JSX.Element);
} & ReturnType<
  // eslint-disable-next-line no-use-before-define
  typeof mapStateToProps
>;

const FeatureFilter = ({
  feature,
  features,
  children,
  ...props
}: FeatureFilterProps) => {
  const isActive = features.includes(feature);

  return typeof children === 'function' ? (
    children(isActive)
  ) : (
    <Maybe test={isActive}>
      {/* @ts-expect-error React-18  Type 'undefined' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.ts(2322) */}
      {React.Children.map(
        children,
        // @ts-expect-error TS(2769): No overload matches this call.
        (child) => child && React.cloneElement(child, omit(props, 'dispatch')),
      )}
    </Maybe>
  );
};

const mapStateToProps = ({ features, company }: RootState) => ({
  features: features.concat(company ? company.features : []),
});

// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps)(FeatureFilter);
