import { List, Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import LegacyPagination from './LegacyPaginationRecord';
import type SegmentManager from './SegmentManagerRecord';
import { type Override } from './types/Override';
import { validateTestingSchema } from './utils';

const schema = z.object({});
const testingSchema = schema.extend({
  employees: z.object({}).passthrough(),
  segments: z.array(z.object({}).passthrough()),
});
type Schema = Override<
  z.infer<typeof testingSchema>,
  {
    employees: LegacyPagination;
    segments: List<SegmentManager>;
  }
>;

const DEFAULT_VALUES = {
  employees: new LegacyPagination(),
  segments: List<SegmentManager>(),
};

// eslint-disable-next-line import/no-default-export
export default class ManagingSegments extends Record<Schema>({
  ...DEFAULT_VALUES,
}) {
  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'ManagingSegments',
      defaultValues: DEFAULT_VALUES,
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'ManagingSegments',
      defaultValues: DEFAULT_VALUES,
      log: [{ environment: 'staging', logLevel: 'warning' }],
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }
}
