import {
  type TypedUseSelectorHook,
  // eslint-disable-next-line no-restricted-imports
  useDispatch,
  useSelector,
} from 'react-redux';

import { type RootState, type Dispatch } from '../types/redux';

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// it was a bit hard to get a typed version of store.dispatch given our current code, so for now useAppDispatch is typed to allow any action.
// On the long term, we should type it according to: https://react-redux.js.org/using-react-redux/usage-with-typescript#define-typed-hooks
export const useAppDispatch: () => Dispatch = useDispatch;
