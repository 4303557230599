import { type Location } from 'history';
import { z } from 'zod';

import jsonapiparser from '@peakon/jsonapiparser';
import { type IntegrationID } from '@peakon/peakon-js';
import {
  SLACK_SCOPES,
  LEGACY_SLACK_SCOPES,
} from '@peakon/shared/constants/slack';
import { getCurrentSubdomain } from '@peakon/shared/features/subdomains/getCurrentSubdomain';
import { isHttpError } from '@peakon/shared/utils';
import api from '@peakon/shared/utils/api';
import { validatedEnvConfig as env } from '@peakon/shared/utils/env';

import { type BetterUpIntegration } from '../containers/administration/routes/integrations/routes/BetterUpRoute/types';
import { type BetterUpConnectAttributes } from '../containers/administration/routes/integrations/routes/BetterUpRoute/utils';
import { type ProvisioningIntegration } from '../containers/administration/routes/integrations/routes/ProvisioningRoute/types';
import { redirectURI } from '../containers/administration/routes/integrations/utils';
import { store } from '../repositories';

export const INTEGRATION_IDS = [
  'slack',
  'microsoft-teams',
  'bamboohr',
  'bob',
  'saml',
  'provisioning',
  'workday',
  'personio',
  'betterup',
] as const satisfies Readonly<Array<IntegrationID>>;

export const INTEGRATION_ID_SCHEMA = z.enum(INTEGRATION_IDS);

const checkStatusFromUrl = async (apiUrl: string) => {
  try {
    const response = await api.get(apiUrl);
    return jsonapiparser(response);
  } catch (error) {
    if (isHttpError(error) && error.code === 404) {
      return 'disconnected';
    }
    throw error;
  }
};

export const INTEGRATION_FACTORY = {
  slack: {
    id: 'slack',
    url: 'https://slack.com/',
    addOn: 'accessibility_slack',
    getConnectUrl: (hasNewUrl: boolean) => {
      const params = new URLSearchParams({
        client_id: env.slack.clientId,
        redirect_uri: redirectURI('/slack/complete'),
        state: `${store('slack')}#${getCurrentSubdomain()}`,
        scope: hasNewUrl
          ? SLACK_SCOPES.join(',')
          : LEGACY_SLACK_SCOPES.join(','),
      }).toString();

      return `https://slack.com/oauth/${
        hasNewUrl ? 'v2/' : ''
      }authorize?${params}`;
    },
    connect: (...params: Parameters<typeof api.slack.connect>) =>
      api.slack.connect(...params),
    checkStatus: () => api.slack.status(),
    disconnect: () => api.slack.disconnect(),
  },

  'microsoft-teams': {
    id: 'microsoft-teams',
    url: 'https://teams.microsoft.com/',
    addOn: 'microsoft_teams',
    getConnectUrl: (
      { clientId }: { clientId: string },
      location?: Location<unknown>,
    ) => {
      const queryParams = new URLSearchParams(location?.search);

      const tenantId = queryParams.get('tenantId') ?? 'common';

      const params = new URLSearchParams({
        client_id: clientId,
        redirect_uri: redirectURI('/microsoft-teams/complete'),
        state: `${store('microsoft-teams')}#${getCurrentSubdomain()}`,
      }).toString();

      return `https://login.microsoftonline.com/${tenantId}/adminconsent?${params}`;
    },
    connect: (...params: Parameters<typeof api.microsoftTeams.connect>) =>
      api.microsoftTeams.connect(...params),
    checkStatus: () => api.microsoftTeams.status(),
    disconnect: () => api.microsoftTeams.disconnect(),
  },

  bamboohr: {
    id: 'bamboohr',
    url: 'http: //www.bamboohr.com?utm_source=Par-Peakon-Ref',
    addOn: 'bamboohr',
    connect: (params: Parameters<typeof api.sync.connect>[1]) =>
      api.sync.connect('bamboohr', params),
    checkStatus: () => api.sync.status('bamboohr'),
    disconnect: () => api.sync.disconnect('bamboohr'),
    enable: () => api.sync.enable('bamboohr'),
    disable: () => api.sync.disable('bamboohr'),
  },

  bob: {
    id: 'bob',
    url: 'https://www.hibob.com/',
    addOn: 'bob',
    connect: (params: Parameters<typeof api.sync.connect>[1]) =>
      api.sync.connect('bob', params),
    checkStatus: () => api.sync.status('bob'),
    disconnect: () => api.sync.disconnect('bob'),
    enable: () => api.sync.enable('bob'),
    disable: () => api.sync.disable('bob'),
  },

  workday: {
    id: 'workday',
    url: 'https://www.workday.com/',
    addOn: 'workday',
    feature: 'workday',
    connect: (params: Parameters<typeof api.sync.connect>[1]) =>
      api.sync.connect('workday', params),
    checkStatus: () => api.sync.status('workday'),
    disconnect: () => api.sync.disconnect('workday'),
    enable: () => api.sync.enable('workday'),
    disable: () => api.sync.disable('workday'),
  },

  personio: {
    id: 'personio',
    url: 'https://www.personio.com/',
    addOn: 'personio',
    feature: 'personio',
    connect: (params: Parameters<typeof api.sync.connect>[1]) =>
      api.sync.connect('personio', params),
    checkStatus: () => api.sync.status('personio'),
    disconnect: () => api.sync.disconnect('personio'),
    enable: () => api.sync.enable('personio'),
    disable: () => api.sync.disable('personio'),
  },

  saml: {
    id: 'saml',
    url: 'http://saml.xml.org/saml-specifications', // NOSONAR
    addOn: 'security_sso',
    connect: (attributes: $TSFixMe) => {
      return api.post(
        '/saml/configuration',
        {},
        {
          data: {
            type: 'saml_configurations',
            attributes,
          },
        },
      );
    },
    disconnect: () => api.delete('/saml/configuration'),
    checkStatus: () => checkStatusFromUrl('/saml/configuration'),
  },

  provisioning: {
    id: 'provisioning',
    url: 'http://www.simplecloud.info/', // NOSONAR
    addOn: 'employee_data_scim',
    connect: () => {
      return api
        .post('/applications/peakon-scim/integrations', null, {
          data: { type: 'app_integrations' },
        })
        .then(jsonapiparser);
    },
    disconnect: (
      appIntegrationId: ProvisioningIntegration['appIntegrationId'],
    ) => api.delete(`/applications/integrations/${appIntegrationId}`),
    checkStatus: () =>
      checkStatusFromUrl('/applications/peakon-scim/integration'),
  },

  betterup: {
    id: 'betterup',
    feature: 'betterup',
    connect: async ({
      attributes,
      appIntegrationId,
    }: {
      attributes: BetterUpConnectAttributes;
      appIntegrationId: BetterUpIntegration['appIntegrationId'];
    }) => {
      const apiBody = {
        data: { type: 'app_integrations', attributes },
      };
      const result = appIntegrationId
        ? await api.patch(
            `/applications/integrations/${appIntegrationId}`,
            null,
            apiBody,
          )
        : await api.post(
            '/applications/peakon-betterup/integrations',
            null,
            apiBody,
          );
      return jsonapiparser(result);
    },
    disconnect: (appIntegrationId: string) =>
      api.delete(`/applications/integrations/${appIntegrationId}`),
    enable: (appIntegrationId: string) =>
      api.patch(`/applications/integrations/${appIntegrationId}`, null, {
        data: {
          type: 'app_integrations',
          attributes: { hookStatus: 'enabled' },
        },
      }),
    disable: (appIntegrationId: string) =>
      api.patch(`/applications/integrations/${appIntegrationId}`, null, {
        data: {
          type: 'app_integrations',
          attributes: { hookStatus: 'disabled' },
        },
      }),
    checkStatus: () =>
      checkStatusFromUrl('/applications/peakon-betterup/integration'),
  },
} as const satisfies Record<IntegrationID, object>;
