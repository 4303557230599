import { Record, fromJS } from 'immutable';
import { z } from 'zod';

import { categorySchema } from '@peakon/shared/schemas/api/categories';
import {
  type EngagementDriversType,
  engagementDrivers,
} from '@peakon/shared/types/Drivers';
import { validateData } from '@peakon/shared/utils/validateData/validateData';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import CategoryRecord from '../CategoryRecord';
import { validateTestingSchema } from '../utils';
import URL_VALIDATION_REGEX from './utils/urlValidationRegex';

const schema = z.object({
  id: z.string().optional(),
});
const testingSchema = schema.extend({
  link: z.string(),
});
type Schema = z.infer<typeof schema>;

const customResourceSchema = z.object({
  id: z.string(),
  type: z.literal('company_resources'),
  attributes: z.object({
    driver: z.enum(engagementDrivers),
    resourceType: z.enum(['article', 'video']),
    status: z.enum(['active', 'inactive']),
    title: z.string(),
    sort: z.number().optional(),
    description: z.string(),
    image: z.string().nullable(),
    link: z.string(),
  }),
  relationships: z.object({
    category: categorySchema,
  }),
});

// eslint-disable-next-line import/no-default-export
export default class CustomResourceRecord
  extends Record({
    id: undefined,
    driver: undefined,
    resourceType: 'article',
    status: 'active',
    title: undefined,
    sort: undefined,
    description: undefined,
    image: undefined,
    link: undefined,
    category: undefined,
  })
  implements Schema
{
  id!: Schema['id'];
  driver?: EngagementDriversType;
  image?: string;
  link!: string;
  status?: 'active' | 'inactive';
  title!: string;
  // FIXME: rename this prop to avoid conflict with base class
  // @ts-expect-error Property 'sort' in type 'CustomResourceRecord' is not assignable to the same property in base type 'Map<string, any>'.
  sort?: string;
  description!: string;
  category?: CategoryRecord;
  resourceType!: 'article' | 'video';

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'CustomResourceRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'CustomResourceRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get isPublished() {
    return this.status === 'active';
  }

  get isValid() {
    return ['driver', 'resourceType', 'title', 'description', 'link'].every(
      (property) => Boolean(this.get(property)),
    );
  }

  get screenshot() {
    return this.image;
  }

  get url() {
    return this.link;
  }

  static createFromApi(data: unknown) {
    const parsedData = validateData(data, customResourceSchema, {
      errorMessagePrefix: 'CustomResourceRecord.createFromApi',
    });
    const { id, attributes, relationships } = parsedData;

    const category = relationships.category;

    return new CustomResourceRecord(
      fromJS({
        id,
        ...attributes,
        category: CategoryRecord.createFromApi(category),
      }),
    );
  }

  hasValidLink() {
    return this.link && URL_VALIDATION_REGEX.test(this.link);
  }
}
