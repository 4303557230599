import React from 'react';

import { EditCloseIcon } from '@peakon/bedrock/icons/system';
import { UnstyledButton } from '@peakon/bedrock/react/button';
import { Heading3 } from '@peakon/bedrock/react/typography';
import { t } from '@peakon/shared/features/i18next/t';

import { type ProductTourTooltip } from '../types';

import styles from './styles.css';

type Props = {
  step: ProductTourTooltip;
  onClose: () => void;
};

export function TooltipHeader({ step, onClose }: Props) {
  return (
    <div className={styles.header}>
      <Heading3 level={1} data-test-id="product-tour-title">
        {step.getTitle?.()}
      </Heading3>
      <UnstyledButton
        onClick={onClose}
        data-test-id="product-tour-close-icon"
        accessibleName={t('common__close')}
      >
        <EditCloseIcon />
      </UnstyledButton>
    </div>
  );
}
