import type React from 'react';
import { type ErrorInfo } from 'react';
import { Component } from 'react';

import { type Dictionary } from 'rollbar';

import { type ErrorReporterType } from '@peakon/shared/utils/errorReporter';

type State = {
  error: null | Error;
};

type Props = {
  children: React.ReactNode;
  errorReporter?: ErrorReporterType;
  fallback?: React.ReactNode | $TSFixMeFunction;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { errorReporter } = this.props;

    if (errorReporter) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      errorReporter.error(error, errorInfo as unknown as Dictionary);
    } else {
      // eslint-disable-next-line no-console
      console.error(error, errorInfo);
    }

    this.setState({
      error,
    });
  }

  render() {
    const { children, fallback } = this.props;
    const { error } = this.state;

    if (error) {
      if (fallback) {
        return typeof fallback === 'function' ? fallback(error) : fallback;
      }

      return null;
    }

    return children;
  }
}

// eslint-disable-next-line import/no-default-export
export default ErrorBoundary;
