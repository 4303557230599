import { List } from 'immutable';

import Topic from '@peakon/records/TopicRecord';

import createPagination from '../utils/createPagination';

export const topicsState = (
  state = List<Topic>(),
  action: $TSFixMe,
): List<Topic> => {
  switch (action.type) {
    case 'GROUP_TOPICS_LIST_SUCCESS':
    case 'TOPIC_LIST_SUCCESS': {
      const { data: topics } = action.data;

      return List(topics.map(Topic.createFromApi));
    }
    case 'TOPIC_LIST_APPEND_SUCCESS':
    case 'GROUP_TOPICS_APPEND_SUCCESS': {
      const { data: topics } = action.data;

      return state.concat(List(topics.map(Topic.createFromApi)));
    }
    case 'TOPIC_GET_SUCCESS': {
      return state.push(Topic.createFromApi(action.data.data));
    }
    case 'TOPIC_BLACKLIST_COMMIT_SUCCESS': {
      return state.filter((topic) => topic.topic !== action.data.topic);
    }
    case 'TOPIC_TRANSLATE_SUCCESS': {
      const { topicId, name, summary } = action.data;

      return state.update(
        state.findIndex((topic) => topic.id === topicId),
        (topic) => (topic ? topic.translate(name, summary) : topic),
      );
    }
    case 'TOPIC_RETRANSLATE': {
      const { topicId } = action.data;

      return state.update(
        state.findIndex((topic) => topic.id === topicId),
        (topic) => (topic ? topic.set('translated', true) : topic),
      );
    }
    case 'TOPIC_UNTRANSLATE': {
      const { topicId } = action.data;

      return state.update(
        state.findIndex((topic) => topic.id === topicId),
        (topic) => (topic ? topic.set('translated', false) : topic),
      );
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default createPagination(topicsState, {
  startTypes: ['GROUP_TOPICS_LIST', 'TOPIC_GET', 'TOPIC_LIST'],
  appendTypes: ['GROUP_TOPICS_APPEND', 'TOPIC_APPEND', 'TOPIC_LIST_APPEND'],
  removeTypes: ['TOPIC_BLACKLIST_COMMIT'],
  clearTypes: [
    'CATEGORY_OVERVIEW_READ_LOADING',
    'ENGAGEMENT_OVERVIEW_READ_LOADING',
  ],
  // @ts-expect-error TS(7006): Parameter 'data' implicitly has an 'any' type.
  getId: (data) => data.req,
  defaultSkip: false,
});
