import { Record, fromJS } from 'immutable';
import { z } from 'zod';

import { type SegmentManagerResponse } from '@peakon/shared/schemas/api/segments';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import Employee from './EmployeeRecord';
import Segment from './SegmentRecord';
import { type Override } from './types/Override';

const schema = z.object({
  id: z.string(),
  automatic: z.boolean(),
  level: z.number().nonnegative(),
  segment: z.object({}).passthrough().optional(),
  employee: z.object({}).passthrough(),
});

type Schema = Override<
  z.infer<typeof schema>,
  {
    segment?: Segment;
    employee: Employee;
  }
>;

// eslint-disable-next-line import/no-default-export
export default class SegmentManager extends Record<Schema>({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  id: undefined!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  automatic: undefined!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  level: undefined!,
  segment: undefined,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  employee: undefined!,
}) {
  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'SegmentManager',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  static createFromApi(data: SegmentManagerResponse) {
    const {
      id,
      attributes,
      relationships: { employee, segment },
    } = data;

    return new SegmentManager(
      fromJS({
        id,
        ...attributes,
        employee: Employee.createFromApi(employee),
        segment:
          segment && segment.attributes
            ? Segment.createFromApi(segment)
            : undefined,
      }),
    );
  }
}
