import {
  type Map,
  type OrderedMap,
  Seq,
  type Set,
  type StaticMap,
} from 'immutable';

import type Category from '@peakon/records/CategoryRecord';
import { type CategoryGroup } from '@peakon/records/constants/questionSets';
import { type AccessByGroup } from '@peakon/records/settings';

export const getAccessByGroup = ({
  categoryGroupSettings,
  categoryIds,
  categoriesByGroup,
}: {
  categoryGroupSettings: StaticMap<Record<CategoryGroup, boolean>>;
  categoryIds?: Set<string>;
  categoriesByGroup?: OrderedMap<string, Map<string, Category>>;
}) => {
  return categoryGroupSettings.reduce<AccessByGroup>((acc, value, key) => {
    if (value) {
      // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
      acc[key] = 'full';
    } else if (categoriesByGroup) {
      const groupCategories = categoriesByGroup.get(key);

      if (groupCategories) {
        const groupCategoriesIds = groupCategories.keySeq();
        // @ts-expect-error 'categoryIds' is possibly 'undefined'.ts(18048)
        const activeCategories = categoryIds.filter((id) =>
          groupCategoriesIds.includes(id),
        );

        if (activeCategories.isEmpty()) {
          // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
          acc[key] = null;
        } else {
          const hasOverview = activeCategories.some((id) =>
            Boolean(groupCategories.get(id).main),
          );

          if (hasOverview) {
            // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
            acc[key] = 'partialOverview';
          } else {
            // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
            acc[key] = 'partial';
          }
        }
      } else {
        // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
        acc[key] = null;
      }
    } else {
      // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
      acc[key] = null;
    }

    return acc;
  }, {});
};

export const getGroupNextGroupSettings = ({
  enabled,
  groupId,
  categoryGroupSettings,
}: {
  enabled: boolean;
  groupId: CategoryGroup;
  categoryGroupSettings: StaticMap<Record<CategoryGroup, boolean>>;
}) => {
  return categoryGroupSettings.set(groupId, enabled);
};

export const getGroupNextCategories = ({
  // the state of the category that was toggled
  enabled,

  // the id of the category that was toggled
  categoryId,

  // the id of the group that was toggle
  groupId,

  // all categories by group
  categoriesByGroup,

  // the individual categories enabled
  categoryIds,

  // the state of each group
  categoryGroupSettings,
}: {
  enabled: boolean;
  categoryId: string | null;
  groupId: CategoryGroup;
  categoriesByGroup: OrderedMap<string, Map<string, Category>>;
  categoryIds: Set<string>;
  categoryGroupSettings: StaticMap<Record<CategoryGroup, boolean>>;
}) => {
  const groupCategories = categoriesByGroup.get(groupId);
  const groupCategoryIds = groupCategories
    ? groupCategories.keySeq()
    : Seq.Indexed<string>();

  // the entire group has been enabled/disabled
  if (categoryId === null) {
    return {
      categoryIds: categoryIds.filter((id) => !groupCategoryIds.includes(id)),
      categoryGroupSettings: categoryGroupSettings.set(groupId, enabled),
    };
  }

  const group = getNextCategories({
    groupAccess: categoryGroupSettings.get(groupId),
    groupCategories: groupCategoryIds,
    categoryId,
    currentCategoryIds: categoryIds,
    enabled,
  });

  return {
    categoryGroupSettings: categoryGroupSettings.set(
      groupId,
      group.groupEnabled,
    ),
    categoryIds: group.categoryIds,
  };
};

export const getNextCategories = ({
  groupAccess,
  groupCategories,
  categoryId,
  currentCategoryIds,
  enabled,
}: {
  groupAccess: boolean;
  groupCategories: Seq.Indexed<string>;
  categoryId: string;
  currentCategoryIds: Set<string>;
  enabled: boolean;
}) => {
  let groupEnabled;
  let categoryIds;

  if (enabled) {
    categoryIds = currentCategoryIds.add(categoryId);

    const currentCategoryIdsForGroup = categoryIds.filter((_v, k) =>
      groupCategories.includes(k),
    );

    if (currentCategoryIdsForGroup.size === groupCategories.size) {
      // if all the categories for a question set are now enabled, we turn on access at a group level and remove the individual category IDs
      groupEnabled = true;
      categoryIds = currentCategoryIds.filter(
        (_v, k) => !currentCategoryIdsForGroup.get(k),
      );
    } else {
      // not all of the categories are enabled for the question set, so we just add the new category
      groupEnabled = false;
      categoryIds = currentCategoryIds.add(categoryId);
    }
  } else {
    if (groupAccess) {
      /** if you turn off one category from a question set that is enabled at group level,
      the group level access is removed, and we need all the categories for that question set to be added to the existing category IDs EXCEPT the turned off category */
      groupEnabled = false;
      categoryIds = currentCategoryIds.merge(
        groupCategories.filter((k) => k !== categoryId),
      );
    } else {
      // if the question set wasn't enabled at group level, we just need to remove the given category
      groupEnabled = false;
      categoryIds = currentCategoryIds.filter((_v, k) => k !== categoryId);
    }
  }

  return { groupEnabled, categoryIds };
};
