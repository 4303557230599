import { Record } from 'immutable';
import { z } from 'zod';

import {
  type ExternalMetric,
  type MetricRelation,
  type MetricStatus,
} from '@peakon/shared/schemas/api/externalMetrics';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import Attribute from '../AttributeRecord';
import Category from '../CategoryRecord';
import { validateTestingSchema } from '../utils';

const schema = z.object({
  id: z.string().optional(),
});
const testingSchema = schema.extend({
  // no testing props yet
});
type Schema = z.infer<typeof schema>;

class MetricRecord
  extends Record({
    id: undefined,
    name: undefined,
    description: undefined,
    status: undefined,
    relation: undefined,
    minValue: undefined,
    maxValue: undefined,

    category: undefined,
    attribute: undefined,
  })
  implements Schema
{
  id!: Schema['id'];
  name?: string;
  description?: string;
  status?: MetricStatus;
  relation?: MetricRelation;
  minValue?: number;
  maxValue?: number;

  category?: Category | null;
  attribute?: Attribute | null;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'MetricRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'MetricRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  static createFromApi(data: ExternalMetric) {
    const {
      id,
      attributes,
      relationships: { category, attribute },
    } = data;

    return new MetricRecord({
      id,
      ...attributes,
      category: category ? Category.createFromApi(category) : null,
      attribute: attribute ? Attribute.createFromApi(attribute) : null,
    });
  }
}

// eslint-disable-next-line import/no-default-export
export default MetricRecord;
