import { type Right } from '../utils/rights/types';

export const CONTEXT_RIGHTS: Right[] = [
  'score:driver:read:manager',
  'score:driver:read:all',
  'score:driver:read:context',
  'employee:read:manager',
  'employee:read:all',
  'question:admin',
  'schedule:admin',
  'schedule:read:manager',
  'schedule:preview:manager',
  'question:read:manager',
  'question:admin:manager',
  'action:read:private',
  'usageMetrics:read',
  'usageMetrics:read:manager',
  'externalMetric:read',
  'branding:admin',
  'branding:read',
  'sensitive:admin',
];
