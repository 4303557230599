import { Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { ALL_QUESTION_SETS } from './constants/questionSets';
import { validateTestingSchema } from './utils';

const schema = z.object({
  order: z.enum(['asc', 'desc']).optional(),
});
const testingSchema = schema.extend({
  aboveAnonymity: z.enum(['above', 'all']),
  access: z.enum(['private', 'shared']).nullable().optional(),
  deadline: z
    .enum(['overdue', 'upcoming', 'date_not_set', 'due_this_week'])
    .nullable()
    .optional(),
  order: z.enum(['asc', 'desc']),
  sort: z.enum(['deadline', 'group']),
  status: z.enum(['open', 'completed', 'archived']),
  categoryGroup: z.enum([...ALL_QUESTION_SETS, 'all', 'values']),
  context: z.enum(['me', 'team']).optional(),
  category: z.string().nullable().optional(),
  driver: z.string().optional(),
  subdriver: z.string().optional(),
});

type Schema = z.infer<typeof testingSchema>;

// eslint-disable-next-line import/no-default-export
export default class ActionFilters extends Record<Schema>({
  aboveAnonymity: 'above',
  access: null,
  context: undefined,
  deadline: null,
  order: 'asc',
  sort: 'deadline',
  status: 'open',
  category: null,
  categoryGroup: 'all',
  driver: undefined,
  subdriver: undefined,
}) {
  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      defaultValues: {
        aboveAnonymity: 'above',
        order: 'asc',
        sort: 'deadline',
        status: 'open',
        categoryGroup: 'all',
      },
      errorMessagePrefix: 'ActionFilters',
    });
    validateTestingSchema(props, testingSchema, {
      defaultValues: {
        aboveAnonymity: 'above',
        order: 'asc',
        sort: 'deadline',
        status: 'open',
        categoryGroup: 'all',
      },
      errorMessagePrefix: 'ActionFilters',
      log: [{ environment: 'staging', logLevel: 'warning' }],
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get dashboardContext() {
    return this.context;
  }

  isFiltering() {
    return Boolean(this.access || this.deadline);
  }
}
