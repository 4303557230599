import { List } from 'immutable';
import flatten from 'lodash/flatten';

import { RegressionObservation } from '@peakon/records/externalMetrics';
import { type ExternalMetricDataPoint } from '@peakon/shared/schemas/api/externalMetricDataPoint';

type Action = {
  type: 'EXTERNAL_METRICS_GET_RESULT_SUCCESS';
  data: {
    data: {
      attributes: {
        regression: {
          formula: {
            kind: 'linear';
            parameters: { alpha: number; beta: number };
          };
          rSquared: number;
          impact: {
            min: number;
            mean: number;
            max: number;
          };
        };
      };
    };
    included: ExternalMetricDataPoint[];
  };
};

const regressions = (state = List(), action: Action) => {
  switch (action.type) {
    case 'EXTERNAL_METRICS_GET_RESULT_SUCCESS': {
      const {
        data: {
          attributes: { regression },
        },
        included = [],
      } = action.data;

      const dataPoints = included
        .filter((i) => i.type === 'external_metric_data_points')
        .map((d) => {
          const {
            id,
            relationships,
            attributes: { externalValue, scoreValue },
          } = d;

          return {
            id,
            attributes: {
              driverValue: scoreValue,
              externalValue,
            },
            relationships,
          };
        });

      // simplify the stories
      const stories = flatten(
        included
          // @ts-expect-error TS(7006): Parameter 'i' implicitly has an 'any' type.
          .filter((i) => i.type === 'stories')
          // @ts-expect-error TS(7006): Parameter 'story' implicitly has an 'any' type.
          .map((story) => story.attributes.texts),
      ).reduce((left, right) => {
        return Object.assign(left, right);
      }, {});

      return {
        regression,
        dataPoints: List(dataPoints.map(RegressionObservation.createFromApi)),
        stories,
      };
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default regressions;
