import { Record, OrderedMap, type OrderedSet } from 'immutable';
import merge from 'lodash/merge';
// eslint-disable-next-line no-restricted-imports -- Automatically disabled here to enable the rule globally
import qs from 'qs';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { type Override } from './types/Override';
import { validateTestingSchema } from './utils';

const schema = z.object({
  id: z.string().optional(),
});
const testingSchema = schema.extend({
  isLoading: z.boolean(),
  isContinuation: z.boolean(),
  continuation: z.string().optional(),
  links: z.record(z.string(), z.unknown()),
  query: z.record(z.string(), z.unknown()).optional(),

  /**
   *
   * NOTE: Multiple types used for `list`
   *
   * The `list` can be either an `OrderedSet` or an `OrderedMap`, so
   * we have to validate against both arrays and objects even though none of
   * them actually validate the contents.
   *
   */

  list: z
    .union([z.array(z.object({}).passthrough()), z.object({}).passthrough()])
    .optional(),
  pages: z.object({
    first: z.number().nullable().optional(),
    last: z.number().nullable().optional(),
    next: z.number().nullable().optional(),
    prev: z.number().nullable().optional(),
    self: z.number().nullable().optional(),
  }),
  total: z.number(),
  nextUrl: z.string().optional(),
});
type Schema = Override<
  z.infer<typeof testingSchema>,
  {
    list: OrderedMap<string, unknown> | OrderedSet<unknown>;
  }
>;

const DEFAULT_VALUES = {
  isLoading: false,
  isContinuation: false,
  links: {},
  list: OrderedMap(),
  pages: {},
  total: 0,
};

class LegacyPagination extends Record<Schema>({
  id: undefined,
  isLoading: false,
  isContinuation: false,
  continuation: undefined,
  links: {},
  query: undefined,
  list: OrderedMap(),
  pages: {},
  total: 0,
  nextUrl: undefined,
}) {
  constructor(props: Partial<Schema> = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'LegacyPagination',
      defaultValues: DEFAULT_VALUES,
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'LegacyPagination',
      defaultValues: DEFAULT_VALUES,
      log: [{ environment: 'local', logLevel: 'error' }],
    });
    // @ts-expect-error - Argument of type 'Partial<Pick<Schema, "list" | "total" | "nextUrl">>' is not assignable to parameter of type 'Schema'.
    super(props);
  }

  getResults() {
    return this.list?.toList();
  }

  getPrimaryButtonUrl() {
    return this.getUrl(this.pages?.next);
  }

  getUrl(page: $TSFixMe) {
    if (this.isContinuation) {
      return qs.stringify({
        ...this.query,
      });
    }

    return qs.stringify({
      ...this.query,
      page,
    });
  }

  isFirst() {
    return this.pages?.self === this.pages?.first;
  }

  hasResults() {
    return !this.list?.isEmpty();
  }

  // @ts-expect-error TS(2416): Property 'first' in type 'LegacyPagination' is not... Remove this comment to see the full error message
  first(params: $TSFixMe) {
    if (this.isContinuation) {
      return {
        params: merge(this.query, params),
      };
    }

    return {
      params: merge(this.query, params),
      page: 1,
    };
  }

  next() {
    if (this.isContinuation) {
      return {
        params: this.query,
        continuation: this.continuation,
      };
    }

    return {
      params: this.query,
      page: this.pages?.next,
    };
  }

  hasNext() {
    if (this.pages) {
      return (
        typeof this.pages.next !== 'undefined' ||
        // @ts-expect-error Object is possibly 'undefined'
        this.pages.self < this.pages.last
      );
    }
    return false;
  }

  hasPrevious() {
    if (this.pages) {
      return (
        typeof this.pages.prev !== 'undefined' ||
        // @ts-expect-error Object is possibly 'undefined'
        this.pages.self > this.pages.first
      );
    }
    return false;
  }

  isLast() {
    return this.pages?.self === this.pages?.last;
  }
}

// eslint-disable-next-line import/no-default-export
export default LegacyPagination;
