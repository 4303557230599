import { type TranslatedString, translatedStringSchema } from './t';
import { validatedEnvConfig as env } from '../../utils/env';
import { errorReporter } from '../../utils/errorReporter';

export const createTranslationMap = <
  TKey extends string | number,
  TValue extends TranslatedString | null,
>(
  map: Record<TKey, TValue>,
) => {
  return {
    get: (key: TKey) => {
      const translated = map[key];

      const isLocal = !env.clusterEnv;

      // it should be valid to have explicit `key: null` in the map
      const hasKey = Object.keys(map).includes(String(key));
      if (!hasKey) {
        const error = new Error(
          `createTranslationMap: No translation found for key: ${key}. This typically happens when a new value is added to the backend but we didn't update the types on the frontend`,
        );
        if (isLocal) {
          // eslint-disable-next-line no-console
          console.error(error);
        } else {
          errorReporter.error(error);
        }
        // convert to string because `key` can be a `number` too but return type should be a TranslatedString
        return translatedStringSchema.parse(String(key));
      }
      return translated;
    },
  };
};
