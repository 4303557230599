import React, { type MouseEvent, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { usePopper } from 'react-popper';

import { Portal } from '@peakon/components';

import { popperModifiers } from './constants';
import TooltipContent from './TooltipContent';
import TooltipFooter from './TooltipFooter';
import { TooltipHeader } from './TooltipHeader';
import useResponsiveTooltipHighlightStyle from './useResponsiveTooltipHighlightStyle';
import { type ProductTourStepComponentProps } from '../ProductTourStepRenderer';
import {
  type ProductTourCustomTooltip,
  type ProductTourTooltip,
} from '../types';

import styles from './styles.css';

type Props = ProductTourStepComponentProps<ProductTourTooltip>;

const ProductTourStepTooltip = (props: Props) => {
  const {
    currentStep,
    hasSkippedTour,
    onClose,
    onStepClick,
    step,
    totalSteps,
    isLast,
    isFirst,
  } = props;
  const [tooltipHasAnimated, setTooltipHasAnimated] = useState(false);
  const [scrollTop, setScrollTop] = useState<number | null>(
    window.document.getElementById('app-content')?.scrollTop ?? null,
  );
  const overlayNode = useRef<HTMLDivElement>(null);
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles: popperStyles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      modifiers: [
        { name: 'arrow', options: { element: arrowElement } },
        ...popperModifiers,
      ],
      placement: step.placement || 'auto',
      strategy: 'fixed',
    },
  );

  const { style, isScrollingToTarget } =
    useResponsiveTooltipHighlightStyle(step);

  useEffect(() => {
    setScrollTop(
      window.document.getElementById('app-content')?.scrollTop ?? null,
    );
  }, [step, isScrollingToTarget]);

  useEffect(() => {
    setTooltipHasAnimated(false);
  }, [step]);

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  // Handle scroll on small screens
  // Since the overlay is above the app-content div, we need to fake scroll it
  const handleScroll = () => {
    const container = window.document.getElementById('app-content');

    if (container && overlayNode.current && scrollTop !== null) {
      container.scrollTop = scrollTop + overlayNode.current.scrollTop;
    }
  };

  const shouldShowTooltip = Boolean(style) && !isScrollingToTarget;

  return (
    <Portal>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        ref={overlayNode}
        className={styles.overlay}
        onClick={handleClick}
        onScroll={handleScroll}
      >
        {shouldShowTooltip && (
          <div
            key={currentStep}
            ref={setReferenceElement}
            className={styles.step}
            style={style}
          />
        )}
      </div>
      {shouldShowTooltip && (
        <FocusTrap
          key={currentStep}
          focusTrapOptions={{ allowOutsideClick: true }}
        >
          <div
            ref={setPopperElement}
            className={styles.popper}
            style={popperStyles.popper}
            data-test-id="product-tour"
            {...attributes.popper}
          >
            <div
              onAnimationEnd={() => setTooltipHasAnimated(true)}
              className={classNames(styles.root, {
                [styles.animate]: !tooltipHasAnimated,
              })}
            >
              <div
                ref={setArrowElement}
                className={styles.arrow}
                style={popperStyles.arrow}
              />
              <TooltipHeader step={step} onClose={onClose} />
              <TooltipContent step={step} />
              {step.content?.(
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                props as ProductTourStepComponentProps<ProductTourCustomTooltip>,
              )}

              <TooltipFooter
                step={step}
                totalSteps={totalSteps}
                currentStep={currentStep}
                hasSkippedTour={hasSkippedTour}
                onClose={onClose}
                onStepClick={onStepClick}
                isLast={isLast}
                isFirst={isFirst}
              />
            </div>
          </div>
        </FocusTrap>
      )}
    </Portal>
  );
};

// eslint-disable-next-line import/no-default-export
export default ProductTourStepTooltip;
