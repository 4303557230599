import { Record, fromJS } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import Context from './ContextRecord';
import { type Override } from './types/Override';
import { validateTestingSchema } from './utils';

const DEFAULT_FIELDS = {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  id: undefined!,
  answerCount: 0,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  closesAt: undefined!,
  employeeCount: 0,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  opensAt: undefined!,
};

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  name: z.string(),
  closed: z.boolean(),
  closesAt: z.string(),
  context: z.object({}).passthrough(),
  status: z.enum(['open', 'closed', 'hard-closed', 'created']),
  lastResentAt: z.string().nullable(),
  type: z.enum(['automatic', 'test']),
  responseId: z.string(),
  opensAt: z.string().date(),
  /**
   *  employeeCount and answerCount have been added to the schema as otherwise the get participationRate function complains that these do not exist on type Round
   *  The function get participationRate does not seem to be used anywhere in the application, therefore once this is verified and removed then employeeCount & answerCount can also be removed from the schema
   */
  employeeCount: z.number(),
  answerCount: z.number(),
});

type Schema = Override<
  z.infer<typeof testingSchema>,
  {
    context?: Context;
  }
>;

class Round extends Record<Schema>({
  ...DEFAULT_FIELDS,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  closed: undefined!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  name: undefined!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  status: undefined!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  type: undefined!,
  context: undefined,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  lastResentAt: undefined!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  responseId: undefined!,
}) {
  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      defaultValues: DEFAULT_FIELDS,
      errorMessagePrefix: 'RoundRecord schema',
    });
    validateTestingSchema(props, testingSchema, {
      defaultValues: DEFAULT_FIELDS,
      errorMessagePrefix: 'RoundRecord schema',
      log: [
        {
          environment: 'local',
          logLevel: 'error',
        },
        {
          environment: 'staging',
          logLevel: 'warning',
        },
        {
          environment: 'production',
          logLevel: 'warning',
        },
      ],
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get dashboardContext() {
    return this.context;
  }

  get participationRate() {
    return this.employeeCount
      ? (100 * this.answerCount) / this.employeeCount
      : 0;
  }

  static createFromApi(data: $TSFixMe) {
    const {
      id,
      attributes,
      relationships: {
        schedule: {
          relationships: { context },
        },
        response,
      },
    } = data;

    return new Round(
      fromJS({
        id,
        ...attributes,
        context: context ? Context.createFromApi(context) : undefined,
        responseId: response ? response.id : null,
      }),
    );
  }

  static get defaultFields() {
    return Object.keys(DEFAULT_FIELDS);
  }
}

// eslint-disable-next-line import/no-default-export
export default Round;
