import { useCallback, useState } from 'react';

import { type Task, type UpdatedTask } from './Tasks/types';

export function useTasks() {
  /*
   * We use this state to force a re-render when the tasks change, since the Map's reference does not change whenever we update it.
   * The content of the state does not matter and is not used, we just need to make sure the content's reference changes whenever the tasks change.
   */
  const [tasks, setTasks] = useState(new Map<string, Task>());

  const addTask = useCallback((newTask: Task) => {
    setTasks((prev) => {
      const newTasks = new Map(prev);
      const existingTask = newTasks.get(newTask.id);

      if (existingTask) {
        /*
         * Because of the asynchronous nature of background tasks,
         * we can sometimes get updates before we even got a chance to add the task to the list of tasks.
         * When that is the case, this task will already have been added to the list of tasks with the type "unknown".
         *
         * We want to update that task with the correct type and data.
         */
        newTasks.set(newTask.id, {
          ...existingTask,
          ...newTask,
          data: {
            ...existingTask.data,
            ...newTask.data,
            /*
             * We want to make sure we don't overwrite the status or percent of the task which is already registered in the list of tasks.
             */
            percent: existingTask.data.percent ?? newTask.data.percent,
            status: existingTask.data.status,
          },
        });
      } else {
        newTasks.set(newTask.id, newTask);
      }
      return newTasks;
    });
  }, []);

  const updateTask = useCallback((updatedTask: UpdatedTask) => {
    setTasks((prev) => {
      const newTasks = new Map(prev);
      const existingTask = newTasks.get(updatedTask.id);

      const task: Task = existingTask
        ? {
            ...existingTask,
            ...updatedTask,
            data: {
              ...existingTask.data,
              ...updatedTask.data,
              status:
                /*
                 * Due to the asynchronous nature of background tasks,
                 * we sometimes get "in progress" updates after the task has already been marked as "completed" or "error".
                 * In that case, we want to keep the status as "completed" or "error".
                 */
                existingTask.data.status === 'completed' ||
                existingTask.data.status === 'error'
                  ? existingTask.data.status
                  : updatedTask.data.status,
            },
          }
        : /*
           * Due to the asynchronous nature of background tasks,
           * we sometimes get "in progress" updates before the task has been added to the list of tasks.
           * In that case, we set the type to "unknown".
           * This allows the toast notifications to filter out the tasks for which we have incomplete information, like these ones.
           */
          { type: 'unknown', ...updatedTask };

      newTasks.set(updatedTask.id, task);

      return newTasks;
    });
  }, []);

  const removeTask = useCallback((id: string) => {
    setTasks((prev) => {
      const newTasks = new Map(prev);
      newTasks.delete(id);
      return newTasks;
    });
  }, []);

  const removeAllTasks = useCallback(() => {
    setTasks(new Map());
  }, []);

  return [
    Array.from(tasks.values()),
    {
      addTask,
      updateTask,
      removeTask,
      removeAllTasks,
    },
  ] as const;
}
