import { fromJS, List } from 'immutable';

import { ActionsList } from '@peakon/records';
import Action from '@peakon/records/ActionRecord';

const pagination = (
  state = new ActionsList(),
  // @ts-expect-error TS(7006): Parameter 'action' implicitly has an 'any' type.
  action,
): ActionsList => {
  switch (action.type) {
    case 'ACTIONS_LIST_SUCCESS': {
      const {
        data,
        links,
        meta: {
          page: { total },
        },
      } = action.data;

      return new ActionsList({
        isLoading: false,
        list: List(
          fromJS(
            data.map(
              (
                // @ts-expect-error TS(7006): Parameter 'dataAction' implicitly has an 'any' typ... Remove this comment to see the full error message
                dataAction,
              ) => Action.createFromApi(dataAction),
            ),
          ),
        ),
        nextUrl: links && links.next,
        total,
      });
    }

    case 'ACTIONS_LIST_LOADING':
    case 'ACTIONS_LOAD_MORE_LOADING': {
      return state.set('isLoading', true);
    }

    case 'ACTIONS_LOAD_MORE_FAILED': {
      return state.set('isLoading', false);
    }

    case 'ACTIONS_LOAD_MORE_SUCCESS': {
      const {
        data,
        links,
        meta: {
          page: { total },
        },
      } = action.data;

      return state.merge({
        isLoading: false,
        list: state.list.concat(
          fromJS(
            data.map(
              (
                // @ts-expect-error TS(7006): Parameter 'dataAction' implicitly has an 'any' typ... Remove this comment to see the full error message
                dataAction,
              ) => Action.createFromApi(dataAction),
            ),
          ),
        ),
        nextUrl: links && links.next,
        total,
      });
    }

    case 'ACTION_COMPLETE_SUCCESS':
    case 'ACTION_INCOMPLETE_SUCCESS': {
      const { id } = action.data;

      const value =
        action.type === 'ACTION_COMPLETE_SUCCESS'
          ? new Date().toISOString()
          : null;

      return state.updateIn(['list'], (actions) => {
        const index = actions.findIndex((a) => a.id === id);

        if (index === -1) {
          return actions;
        }

        return actions.updateIn([index], (a) => a.set('completedAt', value));
      });
    }

    case 'ACTION_REMOVE': {
      const { id } = action.data;

      return state
        .updateIn(['list'], (actions) => actions.filter((a) => a.id !== id))
        .set('total', state.total - 1);
    }

    case 'ACTION_UPDATE_SUCCESS': {
      const { id, attributes, data } = action.data;

      return state.updateIn(['list'], (actions) => {
        const index = actions.findIndex((a) => a.id === id);

        if (index === -1) {
          return actions;
        }

        if (data) {
          return actions.updateIn([index], () => Action.createFromApi(data));
        }

        return actions.updateIn([index], (a) => a.merge(attributes));
      });
    }

    case 'IMPROVE_OVERVIEW_V2_RESET': {
      const { keepActions } = action.data;

      if (keepActions) {
        return state;
      }

      return new ActionsList();
    }

    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/no-default-export
export default pagination;
