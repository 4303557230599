import React from 'react';

import { useController } from 'react-hook-form';
import { type z } from 'zod';

import { ToggleGroup as BRToggleGroup } from '@peakon/bedrock/react/form';

import {
  type ZodSchemaOrRecord,
  type ControlledProps,
} from '../../utils/types';

type ToggleGroupProps = React.ComponentPropsWithoutRef<typeof BRToggleGroup>;

type ToggleItemProps = React.ComponentPropsWithRef<typeof BRToggleGroup.Item>;
type ToggleBespokeItemProps = React.ComponentPropsWithRef<
  typeof BRToggleGroup.BespokeItem
>;

type DisallowedItemProps =
  | 'name'
  | 'required'
  | 'defaultChecked'
  | 'onChange'
  | 'feedbackMessage';

type FormToggleItem = React.FC<Omit<ToggleItemProps, DisallowedItemProps>> & {
  children: React.ReactNode;
};
type FormToggleBespokeItem = React.FC<
  Omit<ToggleBespokeItemProps, DisallowedItemProps>
> & {
  children: React.ReactNode;
};

type Props<TSchema extends ZodSchemaOrRecord> = ControlledProps<
  TSchema,
  ToggleGroupProps
>;

export function ToggleGroup<TSchema extends ZodSchemaOrRecord = z.ZodRawShape>(
  props: Props<TSchema>,
) {
  const {
    name,
    shouldUnregister,
    disabled,
    children,
    ...restToggleGroupProps
  } = props;

  const { field } = useController({
    name,
    shouldUnregister,
    disabled,
  });

  const { onChange, ...restField } = field;

  // Pass required and name field to each child (toggle group item)
  const toggleItems = React.Children.map(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    children as React.ReactElement<ToggleItemProps | ToggleBespokeItemProps>[],
    (toggleItem) =>
      React.isValidElement(toggleItem) &&
      React.cloneElement(toggleItem, {
        onChange,
        name,
        checked: field.value === toggleItem.props.value,
      }),
  );

  return (
    <BRToggleGroup {...restField} {...restToggleGroupProps}>
      {toggleItems}
    </BRToggleGroup>
  );
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
ToggleGroup.Item = BRToggleGroup.Item as unknown as FormToggleItem;
ToggleGroup.BespokeItem =
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  BRToggleGroup.BespokeItem as unknown as FormToggleBespokeItem;
