import React from 'react';

import { type BaseButtonProps, Button } from '@peakon/bedrock/react/button';

import { useSafeState } from '../../hooks/useMounted';

type Props = {
  /**
   *
   * NOTE:
   *
   * We explicitly don't want to use the resolved value of the promise, but due to
   * this issue https://github.com/microsoft/TypeScript/issues/49755 we can't
   * use `Promise<void>` here :/
   *
   */

  onClick: () => Promise<unknown>;
} & Omit<BaseButtonProps<typeof Button>, 'onClick'>;

export const AsyncButton = ({ onClick, ...props }: Props) => {
  const [busy, setBusy] = useSafeState(false);

  const handleClick = async () => {
    if (busy) {
      return;
    }

    setBusy(true);

    try {
      await onClick();
    } catch {
      // noop - this is being handled somewhere hopefully :)
      // ie: react-query, redux asyncDispatch etc.
    } finally {
      setBusy(false);
    }
  };

  return <Button busy={busy} onClick={handleClick} {...props} />;
};
