import { fromJS, Record, type List } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import Action from './ActionRecord';
import type Category from './CategoryRecord';
import Segment from './SegmentRecord';
import { type Override } from './types/Override';
import { validateTestingSchema } from './utils';
import Value from './ValueRecord';

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  actions: z.object({}).passthrough(),
  category: z.object({}).passthrough().optional(),
  group: z.string(),
  title: z.string(),
  segment: z.object({}).passthrough().optional(),
  value: z.object({}).passthrough().optional(),
});
type Schema = Override<
  z.infer<typeof testingSchema>,
  {
    segment?: Segment;
    value?: Value;
    category?: Category;
    actions: List<Action>;
  }
>;

// eslint-disable-next-line import/no-default-export
export default class ActionsGroupRecord extends Record<Schema>({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  id: undefined!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  actions: undefined!,
  category: undefined,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  group: undefined!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  title: undefined!,
  segment: undefined,
  value: undefined,
}) {
  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'ActionsGroupRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'ActionsGroupRecord',
      log: [{ environment: 'staging', logLevel: 'warning' }],
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  getTotalActions() {
    return this.actions ? this.actions.size : 0;
  }

  static createFromApi(group: $TSFixMe, team: $TSFixMe) {
    const {
      relationships: { actions, segment, value },
    } = group;

    return new ActionsGroupRecord(
      fromJS({
        id: group.id,
        actions: actions.map(
          (
            // @ts-expect-error no implicit any
            action,
          ) => Action.createFromApi(action, team),
        ),
        segment: segment ? Segment.createFromApi(segment) : undefined,
        value: value ? Value.createFromApi(value) : undefined,
        category: Action.createFromApi(actions[0], team).get('category'),
        ...group.attributes,
      }),
    );
  }
}
