import React, { Fragment, useMemo } from 'react';

import classnames from 'classnames';

import { VisuallyHidden } from '@peakon/bedrock/react/visually-hidden';
import { Combobox } from '@peakon/components';
import { type TimezoneValue } from '@peakon/shared/data/timezones';
import { t } from '@peakon/shared/features/i18next/t';

import getTimezones from './utils/getTimezones';

import styles from './styles.css';

type Props = {
  asPortal?: HTMLElement;
  defaultValueLabel?: string;
  disabled?: boolean;
  id?: string;
  isAbsolutePosition?: boolean;
  isFilter?: boolean;
  label?: string;
  onSelect: (value: TimezoneValue | null) => void;
  placeholder?: string;
  selected: string | null | undefined;
};

function TimezoneSelector({
  asPortal,
  isFilter,
  selected = null,
  onSelect,
  disabled,
  id,
  label,
  placeholder,
  defaultValueLabel,
  isAbsolutePosition,
}: Props) {
  const timezones = useMemo(() => {
    return getTimezones(isFilter);
  }, [isFilter]);

  const selectedOption = useMemo(() => {
    if (!timezones) {
      return null;
    }

    return timezones.find((timezone) => timezone.value === selected);
  }, [selected, timezones]);

  // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
  label = label || t('account__a11y__timezone__placeholder');
  // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
  placeholder = isFilter
    ? placeholder || t('filters__select__placeholder')
    : placeholder || t('account__timezone__placeholder');
  // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
  // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
  defaultValueLabel = isFilter
    ? null
    : defaultValueLabel || t('account__timezone__placeholder');

  return (
    <div
      data-test-id="timezone-selector"
      className={classnames({
        [styles.fixed]: isAbsolutePosition,
      })}
    >
      <Combobox
        id={id}
        selectedItem={selected}
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        onSelect={(item) => onSelect(item as TimezoneValue)}
      >
        <Combobox.Select
          placeholder={placeholder}
          defaultValueLabel={defaultValueLabel}
          disabled={disabled}
          hasClear={false}
        >
          {label && (
            <VisuallyHidden id="combobox-label">{label}</VisuallyHidden>
          )}
          {selectedOption && (
            <Fragment>
              <span>{selectedOption.label}</span>
              {selectedOption.group && (
                <Fragment>
                  <VisuallyHidden>,</VisuallyHidden>
                  <span className={styles.group}>
                    &nbsp;{selectedOption.group}
                  </span>
                </Fragment>
              )}
            </Fragment>
          )}
          {!selected && defaultValueLabel && (
            <span className={styles.placeholder}>{defaultValueLabel}</span>
          )}
        </Combobox.Select>
        <Combobox.Menu fitTargetWidth popperNode={asPortal}>
          {timezones.map(({ value, label: timezoneLabel, group }) => (
            <Combobox.Option key={value} value={value}>
              <span>{timezoneLabel}</span>
              {group && (
                <Fragment>
                  <VisuallyHidden>,</VisuallyHidden>
                  <span className={styles.group}>&nbsp;{group}</span>
                </Fragment>
              )}
            </Combobox.Option>
          ))}
          {!isFilter && (
            <Combobox.DefaultOption>{defaultValueLabel}</Combobox.DefaultOption>
          )}
        </Combobox.Menu>
      </Combobox>
    </div>
  );
}

// eslint-disable-next-line import/no-default-export
export default TimezoneSelector;
