import { fromJS, List, OrderedMap, Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { ActionsGroup, Attribute } from './';
import Action from './ActionRecord';
import { type Override } from './types/Override';
import { validateTestingSchema } from './utils';

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  abbreviation: z.string(),
  actions: z.array(z.object({}).passthrough()),
  groups: z.object({}).passthrough(),
  name: z.string(),
  direct: z.boolean(),
  isLoading: z.boolean(),
  responses: z.number(),
  attribute: z.object({}).passthrough(),
  logo: z.string().optional().nullable(),
});
type Schema = Override<
  z.infer<typeof testingSchema>,
  {
    actions: List<Action>;
    attribute: Attribute;
    groups: OrderedMap<string, ActionsGroup>;
  }
>;

// eslint-disable-next-line import/no-default-export
export default class ActionsTeamRecord extends Record<Schema>({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  id: undefined!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  abbreviation: undefined!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  actions: undefined!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  groups: undefined!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  name: undefined!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  direct: undefined!,
  isLoading: false,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  responses: undefined!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  attribute: undefined!,
}) {
  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'ActionsTeamRecord',
      defaultValues: {
        isLoading: false,
      },
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'ActionsTeamRecord',
      defaultValues: {
        isLoading: false,
      },
      log: [
        {
          environment: 'staging',
          logLevel: 'warning',
        },
        {
          environment: 'production',
          logLevel: 'warning',
        },
      ],
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  getTotalActions(sort: $TSFixMe) {
    if (sort === 'group') {
      return this.groups
        ? this.groups.reduce(
            (total: $TSFixMe, group: $TSFixMe) =>
              total + group.getTotalActions(),
            0,
          )
        : 0;
    }

    return this.actions.size;
  }

  static createFromApi(team: $TSFixMe) {
    const {
      attributes: { responses },
      relationships: {
        actions,
        context: {
          attributes,
          relationships: { attribute },
        },
        groups,
      },
    } = team;

    return new ActionsTeamRecord(
      fromJS({
        id: team.id,
        actions: List(
          fromJS(
            actions
              ? actions.map(
                  (
                    // @ts-expect-error no implicit any
                    action,
                  ) => Action.createFromApi(action, team.id),
                )
              : [],
          ),
        ),
        groups: groups
          ? OrderedMap(
              groups.map(
                (
                  // @ts-expect-error no implicit any
                  group,
                ) => [
                  group.attributes.group,
                  ActionsGroup.createFromApi(group, team.id),
                ],
              ),
            )
          : OrderedMap(),
        attribute: Attribute.createFromApi(attribute),
        responses,
        ...attributes,
      }),
    );
  }
}
