import { OrderedMap } from 'immutable';

import AccessGroup from '@peakon/records/AccessGroupRecord';
import { type AccessGroupResponseData } from '@peakon/shared/schemas/api/accessGroups';

type Action =
  | {
      type: 'ACCESS_GROUP_FLUSH';
    }
  | {
      type: 'ACCESS_GROUP_LIST_SUCCESS';
      data: {
        data: AccessGroupResponseData[];
      };
    }
  | {
      type: 'ACCESS_GROUP_DELETE_SUCCESS';
      data: {
        id: string;
      };
    }
  | {
      type: 'ACCESS_GROUP_PATCH_SUCCESS' | 'ACCESS_GROUP_CREATE_SUCCESS';
      data: {
        data: AccessGroupResponseData;
      };
    }
  | {
      type:
        | 'ACCESS_GROUP_MEMBER_ADD_LOADING'
        | 'ACCESS_GROUP_MEMBER_REMOVE_FAILED'
        | 'ACCESS_GROUP_MEMBER_ADD_FAILED'
        | 'ACCESS_GROUP_MEMBER_REMOVE_SUCCESS';
      data: {
        groupId: string;
      };
    };

const groups = (
  state = OrderedMap<string, AccessGroup>(),
  action: Action,
): OrderedMap<string, AccessGroup> => {
  switch (action.type) {
    case 'ACCESS_GROUP_FLUSH': {
      return OrderedMap();
    }
    case 'ACCESS_GROUP_LIST_SUCCESS': {
      const { data } = action.data;

      return OrderedMap(
        data.map(AccessGroup.createFromApi).map((group) => [group.id, group]),
      );
    }
    case 'ACCESS_GROUP_DELETE_SUCCESS': {
      const { id } = action.data;

      return state.delete(id);
    }
    case 'ACCESS_GROUP_PATCH_SUCCESS':
    case 'ACCESS_GROUP_CREATE_SUCCESS': {
      const { data } = action.data;

      const group = AccessGroup.createFromApi(data);

      // @ts-expect-error Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
      return state.set(group.id, group);
    }
    case 'ACCESS_GROUP_MEMBER_ADD_LOADING':
    case 'ACCESS_GROUP_MEMBER_REMOVE_FAILED': {
      const { groupId: id } = action.data;

      return state.updateIn(
        [id, 'memberCount'],
        (memberCount) => memberCount + 1,
      );
    }
    case 'ACCESS_GROUP_MEMBER_ADD_FAILED':
    case 'ACCESS_GROUP_MEMBER_REMOVE_SUCCESS': {
      const { groupId: id } = action.data;

      return state.updateIn(
        [id, 'memberCount'],
        (memberCount) => memberCount - 1,
      );
    }

    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/no-default-export
export default groups;
