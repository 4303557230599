import { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import {
  analytics,
  // eslint-disable-next-line no-restricted-imports
  trackPageView as analyticsTrackPageView,
} from './analytics';
import { getRouteInformation } from './productAreas';
import { type Location } from '../../types/ReactRouter';

const trackPageView = (location: Location) => {
  const routeInfo = getRouteInformation(location);

  // We always want to update the enrichment data on URL changes, but we don't
  // want to track technical routes such as SSO routes, Survey transfer etc.
  analytics.setEnrichmentData({
    product_area: routeInfo?.productArea,
    obfuscated_url: routeInfo?.obfuscatedUrl,
    url_search_params: routeInfo?.searchParams,
  });

  if (routeInfo && routeInfo.productArea !== 'Technical Route') {
    analyticsTrackPageView();
  }
};

/**
 * Track page views on route change.
 * Ignores technical routes such SSO routes, Microsoft Teams routes, Survey transfer etc.
 */
export const useTrackPageViews = () => {
  const history = useHistory();

  useEffect(() => {
    trackPageView(history.location); // track the initial page view
    return history.listen((location) => {
      // track subsequent page views
      trackPageView(location);
    });
  }, [history]);
};
