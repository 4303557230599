/**
 *
 * NOTE: Hackiest of hacks for accessing feature flips
 *
 * There is no connection between packages and the applications where we can
 * bootstrap these kinds of things, so we have to directly keep track of feature
 * flips here.
 *
 */

const FEATURE_FLIPS_STORAGE_NEVER_USE: { features: Array<string> } = {
  features: [],
};

export function hasFeatureFlip(name: string) {
  return FEATURE_FLIPS_STORAGE_NEVER_USE.features.find(
    (feature) => feature === name,
  );
}

const featureFactory = (sessionFeatures: string[] = []) => {
  FEATURE_FLIPS_STORAGE_NEVER_USE.features = sessionFeatures;
  return sessionFeatures.map((feature) => ({
    key: feature,
    name: feature,
    active: true,
  }));
};

// eslint-disable-next-line import/no-default-export
export default featureFactory;
