import {
  Record as ImmutableRecord,
  fromJS,
  type List,
  Map,
  type Set,
  type StaticMap,
} from 'immutable';
import every from 'lodash/every';
import first from 'lodash/first';
import get from 'lodash/get';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import reduce from 'lodash/reduce';
import moment from 'moment';
import { z } from 'zod';

import {
  type TimezoneValue,
  timezoneValues,
} from '@peakon/shared/data/timezones';
import { type AttributeOptionResponse } from '@peakon/shared/schemas/api/attributes';
import {
  type EmployeeAccountResponse,
  type EmployeeResponse,
  EmployeeTypesEnum,
  SourceEnum,
} from '@peakon/shared/schemas/api/employees';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import type Attribute from './AttributeRecord';
import { AttributeOption } from './AttributeRecord';
import type SegmentManager from './SegmentManagerRecord';
import { type Override } from './types/Override';

const accountSchema = z.object({
  email: z.string().nullable(),
  id: z.string(),
  _type: z.literal('accounts'),
});

const attributesSchema = z.record(
  z.string(),
  z
    .union([z.string(), z.number(), accountSchema])
    .optional() // the attribute values can be set to undefined in getBulkEditorEmployee
    .nullable(),
);
export type Attributes = z.infer<typeof attributesSchema>;

const employeeAttributesSchema = z.record(
  z.string(),
  z.record(
    z.string(),
    z
      .union([
        z.string(),
        z.number(),
        z.boolean(),
        z.date(),
        z.array(z.string()),
      ])
      .nullable()
      .optional(),
  ),
);
type EmployeeAttributes = z.infer<typeof employeeAttributesSchema>;

const optionAttributesSchema = z.record(
  z.string(),
  z.record(
    z.string(),
    z.union([z.string(), z.number(), z.boolean()]).optional(),
  ),
);
type OptionAttributes = z.infer<typeof optionAttributesSchema>;

/*
 * Note: some of the properties here shouldn't be optional
 * But this is needed because we use the constructor like new Employee({placeholder: true})
 */
const employeeSchema = z.object({
  accessLevels: z.array(z.string()).optional(),
  account: accountSchema.optional(),
  accountId: z.string().optional(),
  attributes: attributesSchema.optional(),
  avatar: z.string().nullable().optional(),
  bounceReason: z.string().nullable().optional(),
  bouncedAt: z.date().optional(),
  complainedAt: z.date().optional(),
  createdAt: z.string().optional(),
  email: z.string().nullable().optional(),
  employeeAttributes: employeeAttributesSchema.optional(),
  externalId: z.string().nullable().optional(),
  features: z.record(z.string(), z.boolean().optional()).optional(),
  firstName: z.string().nullable().optional(),
  id: z.string().optional(),
  identifier: z.string().optional(),
  invitedToAdministerAt: z.string().nullable().optional(),
  isAnonymized: z.boolean().optional(),
  kioskCode: z.string().optional(),
  lastName: z.string().nullable().optional(),
  lastSeenAt: z.date().optional(),
  locale: z.string().nullable().optional(),
  localeEffective: z.string().nullable().optional(),
  managedSegments: z.array(z.object({})).optional(),
  name: z.string().optional(),
  optionAttributes: optionAttributesSchema.optional(),
  phone: z.string().nullable().optional(),
  placeholder: z.boolean(),
  reportCount: z.number().optional(),
  source: SourceEnum.optional(),
  timezone: z.enum(timezoneValues).nullable().optional(),
  timezoneEffective: z.enum(timezoneValues).nullable().optional(),
  type: EmployeeTypesEnum.optional(),
  _type: z.literal('employees').optional(),
});

type Schema = Override<
  z.infer<typeof employeeSchema>,
  {
    accessLevels?: List<string>;
    attributes: StaticMap<Attributes>;
    employeeAttributes: StaticMap<EmployeeAttributes>;
    features: Map<string, boolean>;
    managedSegments?: SegmentManager[];
    optionAttributes: StaticMap<OptionAttributes>;
    timezone?: TimezoneValue | null;
    timezoneEffective?: TimezoneValue | null;
  }
>;

export const DEFAULT_FEATURES = Map<string, boolean>({
  engagement: false,
});

const DEFAULT_VALUES = {
  attributes: Map<Attributes>(),
  employeeAttributes: Map<EmployeeAttributes>(),
  optionAttributes: Map<OptionAttributes>(),
  features: DEFAULT_FEATURES,
  placeholder: false,
};

export const EMPLOYEE_KEYS = {
  accessLevels: undefined,
  avatar: undefined,
  firstName: undefined,
  id: undefined,
  externalId: undefined,
  source: undefined,
  type: undefined,
  identifier: undefined,
  kioskCode: undefined,
  lastName: undefined,
  meta: undefined,
  name: undefined,
  phone: undefined,
  reportCount: undefined,
  createdAt: undefined,
  invitedToAdministerAt: undefined,
  isAnonymized: undefined,
  managedSegments: undefined,

  // account properties, to be grouped in account: new Account()
  accountId: undefined,
  bouncedAt: undefined,
  bounceReason: undefined,
  complainedAt: undefined,
  email: undefined,
  locale: undefined,
  localeEffective: undefined,
  timezone: undefined,
  timezoneEffective: undefined,
  lastSeenAt: undefined,

  ...DEFAULT_VALUES,
};

// eslint-disable-next-line import/no-default-export
export default class Employee extends ImmutableRecord<Schema>(EMPLOYEE_KEYS) {
  constructor(props: unknown = {}) {
    validateRecord(props, employeeSchema, {
      errorMessagePrefix: 'Employee',
      defaultValues: DEFAULT_VALUES,
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get abbreviation() {
    return (first(this.firstName) || '') + (first(this.lastName) || '');
  }

  isEngaged() {
    return this.features.get('engagement');
  }

  canBeNotified() {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const employmentStatus = this.attributes.get('employmentStatus') as string;
    return !['hired', 'left'].includes(employmentStatus);
  }

  isBounced() {
    return Boolean(this.bouncedAt || this.bounceReason);
  }

  isComplained() {
    return Boolean(this.complainedAt);
  }

  isBouncedOrComplained() {
    return this.isBounced() || this.isComplained();
  }

  isBasicAccess() {
    return (
      this.accessLevels &&
      this.accessLevels.size === 1 &&
      this.accessLevels.first() === 'basic'
    );
  }

  isLeaver() {
    const attributes = this.get('attributes');
    const employmentStatus = attributes.get('employmentStatus');

    if (employmentStatus !== 'left') {
      return false;
    }

    const employmentEnd = attributes.get('employmentEnd');
    // @ts-expect-error Type '{ id: string; email: string | null; _type: "accounts"; }' is not assignable to type 'MomentInput'.
    const leaverDate = moment(employmentEnd);

    return leaverDate.diff(new Date(), 'day') < 0;
  }

  static getAccount(account: EmployeeAccountResponse) {
    const bouncedAt = account?.attributes?.bouncedAt;
    const complainedAt = account?.attributes?.complainedAt;
    const lastSeenAt = account?.attributes?.lastSeenAt;
    return {
      accountId: account?.id,
      bouncedAt: bouncedAt ? new Date(bouncedAt) : undefined,
      bounceReason: account?.attributes?.bounceReason,
      complainedAt: complainedAt ? new Date(complainedAt) : undefined,
      email: account?.attributes?.email,
      locale: account?.attributes?.locale,
      localeEffective: account?.attributes?.localeEffective,
      timezone: account?.attributes?.timezone,
      timezoneEffective: account?.attributes?.timezoneEffective,
      lastSeenAt: lastSeenAt ? new Date(lastSeenAt) : undefined,
    };
  }

  static createFromApi(data: EmployeeResponse) {
    const {
      id,
      attributes: {
        accessLevels,
        avatar,
        employmentStart: _employmentStart,
        external: _external,
        features,
        firstName,
        identifier,
        externalId,
        source,
        kioskCode,
        lastName,
        name,
        reportCount,
        createdAt,
        invitedToAdministerAt,
        type,
        isAnonymized,
        ...other
      } = {},
      relationships: {
        account,
        phones = [],
        managedSegments,
        ...otherRelationships
      } = {},
    } = data;

    const employeeAttributes = Object.keys(otherRelationships)
      .filter((key) => {
        const otherRelationshipsType = get(otherRelationships, `${key}.type`);
        return otherRelationshipsType === 'employees';
      })
      .reduce((acc, relationshipsName) => {
        const relationshipsId = get(
          otherRelationships,
          `${relationshipsName}.id`,
        );
        const attrs = get(
          otherRelationships,
          `${relationshipsName}.attributes`,
          {},
        );
        const employeeAccount = Employee.getAccount(
          // @ts-expect-error TS2345: Argument of type '{}' is not assignable to parameter of type '{ attributes:...
          get(
            otherRelationships,
            `${relationshipsName}.relationships.account`,
            {},
          ),
        );

        return acc.set(
          relationshipsName,
          Map({ id: relationshipsId, ...attrs, ...employeeAccount }),
        );
      }, Map());

    const optionAttributes = reduce(
      otherRelationships,
      (result, value: AttributeOptionResponse, key) =>
        value && value.attributes && value.type === 'attribute_options'
          ? result.set(
              key,
              new AttributeOption({
                id: value.id,
                title: value.attributes.name,
                titleTranslated: value.attributes.nameTranslated,
              }),
            )
          : result,
      Map(),
    );

    const featureValues = (features || []).reduce((acc, current) => {
      // @ts-expect-error TS2339
      if (current === 'engagement' && other.employmentStatus === 'left') {
        return acc.set(current, false);
      }
      return acc.set(current, true);
    }, DEFAULT_FEATURES);

    const phone = first(
      (phones || [])
        .filter(
          (phoneItem) =>
            phoneItem.attributes && get(phoneItem, 'attributes.primary'),
        )
        .map((phoneItem) => get(phoneItem, 'attributes.number')),
    );

    const props = fromJS({
      id,
      identifier: identifier || undefined,
      externalId,
      source,
      kioskCode,
      firstName,
      lastName,
      avatar,
      name,
      phone,
      reportCount,
      createdAt,
      features: featureValues,
      employeeAttributes,
      invitedToAdministerAt,
      type,
      isAnonymized,
      optionAttributes,
      accessLevels,
      managedSegments,
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      ...Employee.getAccount(account as EmployeeAccountResponse),
      attributes: {
        ...other,
      },
    });

    return new Employee(props);
  }

  toJsonApiWithAttributes(attributes: Map<string, Attribute>) {
    const features = this.features.toJS();

    const result = merge(
      this.toJsonApi(),
      merge(
        attributes
          .map((attribute) => attribute?.getJsonApiValue())
          .toArray()
          .reduce((curr, prev) => merge(curr, prev), {}),
        {
          attributes: {
            features,
          },
        },
      ),
    );

    return pickBy(
      // Remove features: {} if both are unchanged
      isEmpty(features) || every(features, isUndefined)
        ? omit(result, ['attributes.features'])
        : result,
      identity,
    );
  }

  toJsonApiBulk(attributes: List<Attribute>, fields: Set<string>) {
    let features = this.features
      .filter((_value, key) => Boolean(key && fields.includes(key)))
      .toJS();

    if (isEmpty(features)) {
      // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'Omit<{ set: {...
      features = undefined;
    }

    let timezone;
    let locale;
    if (fields.includes('timezone')) {
      timezone = this.timezone;
    }

    if (fields.includes('locale')) {
      locale = this.locale;
    }

    return merge(
      {
        type: 'employees',
      },
      merge(
        attributes
          .filter((attribute) =>
            Boolean(attribute && fields.includes(attribute.id)),
          )
          .map((attribute) => attribute?.getJsonApiValue())
          .toArray()
          .reduce((curr, prev) => merge(curr, prev), {}),
        { attributes: { features, timezone, locale } },
      ),
    );
  }

  phoneToJsonApi() {
    if (typeof this.phone === 'undefined') {
      return {};
    } else if (this.phone === null || this.phone === '') {
      return {
        phones: {
          data: [],
        },
      };
    }

    return {
      phones: {
        data: [
          { type: 'phones', attributes: { number: this.phone, primary: true } },
        ],
      },
    };
  }

  toJsonApi() {
    const {
      id,
      meta: _meta,
      email,
      phone: _phone,
      locale,
      timezone,
      features,
      placeholder: _placeholder,
      employeeAttributes: _employeeAttributes,
      optionAttributes: _optionAttributes,
      identifier,
      attributes: _attributes,
      ...attributes
      // @ts-expect-error Property 'toJSON' does not exist on type 'Employee'. Did you mean 'toJS'?ts(2551)
    } = this.toJSON();

    return {
      type: 'employees',
      id,
      attributes: {
        email: email === '' ? null : email,
        features,
        locale,
        timezone,
        identifier: identifier === '' ? null : identifier,
        ...attributes,
      },
      relationships: { ...this.phoneToJsonApi() },
    };
  }
}
