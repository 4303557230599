import { OrderedSet, Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import type Round from './RoundRecord';
import { type Override } from './types/Override';
import { validateTestingSchema } from './utils';

const schema = z.object({
  isLoading: z.boolean().optional(),
});
const testingSchema = schema.extend({
  nextUrl: z.string().nullable(),
  open: z.number(),
  rounds: z.array(z.object({}).passthrough()),
  total: z.number(),
});
type Schema = Override<
  z.infer<typeof testingSchema>,
  {
    rounds: OrderedSet<Round>;
  }
>;

const DEFAULT_VALUES = {
  isLoading: true,
  nextUrl: null,
  open: 0,
  rounds: OrderedSet<Round>(),
  total: 0,
};

// eslint-disable-next-line import/no-default-export
export default class EmployeeRounds extends Record<Schema>({
  ...DEFAULT_VALUES,
}) {
  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'EmployeeRounds',
      defaultValues: DEFAULT_VALUES,
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'EmployeeRounds',
      defaultValues: DEFAULT_VALUES,
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }
}
