import React, {
  Fragment,
  forwardRef,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import classnames from 'classnames';

import { ViewAcknowledgeIcon as Checkmark } from '@peakon/bedrock/icons/system';
import { Menu, useMenuContext } from '@peakon/components';
import type Acknowledgement from '@peakon/records/AcknowledgementRecord';
import { type AcknowledgementType } from '@peakon/shared/constants/acknowledgementTypes';
import { t } from '@peakon/shared/features/i18next/t';

import AcknowledgementLabel from '../AcknowledgementLabel';
import AcknowledgementMenu from '../AcknowledgementMenu';
import AcknowledgementModal from '../AcknowledgementModal';

import styles from './styles.css';

const AcknowledgementTarget = forwardRef<
  HTMLDivElement,
  JSX.IntrinsicElements['div']
>((props, ref) => {
  const { isOpen } = useMenuContext();

  return (
    <div
      ref={ref}
      className={classnames(styles.root, {
        [styles.active]: isOpen,
      })}
      data-test-id="acknowledge-button"
      {...props}
    >
      <div className={styles.checkmark}>
        <Checkmark className={styles.checkmarkIcon} />
      </div>
      <span>{t('acknowledge__mark_as_read')}</span>
    </div>
  );
});

AcknowledgementTarget.displayName = 'AcknowledgementTarget';

type AcknowledgementDropdownProps = {
  acknowledgement?: Acknowledgement | null;
  onAcknowledge: (
    type: AcknowledgementType,
    options: { optOutConfirm?: boolean },
  ) => Promise<void>;
  shouldConfirm?: boolean;
  timeout?: number;
};

const AcknowledgementDropdown = ({
  acknowledgement,
  onAcknowledge,
  shouldConfirm,
  timeout = 600,
}: AcknowledgementDropdownProps) => {
  const [isOpen, setOpen] = useState(false);
  const [activeType, setActiveType] = useState<AcknowledgementType | null>(
    null,
  );

  const mouseEnterRef = useRef<boolean>();
  const mouseEnterTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const onKeyDown = useCallback((event: { key: string }) => {
    if (event.key === 'Escape') {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      window.document.addEventListener('keydown', onKeyDown);
    } else {
      window.document.removeEventListener('keydown', onKeyDown);
    }

    return () => {
      window.document.removeEventListener('keydown', onKeyDown);
    };
  }, [isOpen, onKeyDown]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
      clearTimeout(mouseEnterTimeoutRef.current);
    };
  }, []);

  const onConfirm = async (
    type: AcknowledgementType,
    { optOut }: { optOut?: boolean } = {},
  ) => {
    try {
      await onAcknowledge(type, {
        optOutConfirm: optOut,
      });
    } finally {
      setActiveType(null);
    }
  };

  const onShowMenu = () => {
    mouseEnterRef.current = true;

    mouseEnterTimeoutRef.current = setTimeout(() => {
      mouseEnterRef.current = false;
    }, 100);

    clearTimeout(timeoutRef.current);

    setOpen(true);
  };

  const onHideMenu = () => {
    clearTimeout(timeoutRef.current);

    if (activeType) {
      return;
    }

    timeoutRef.current = setTimeout(() => {
      clearTimeout(mouseEnterTimeoutRef.current);
      mouseEnterRef.current = false;

      setOpen(false);
    }, timeout);
  };

  if (acknowledgement) {
    return <AcknowledgementLabel role="alert" type={acknowledgement.type} />;
  }

  return (
    <Fragment>
      <Menu
        closeOnSelection={false}
        isOpen={isOpen}
        onSelect={(value) => {
          if (shouldConfirm) {
            // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
            setActiveType(value);
          } else {
            // @ts-expect-error - Argument of type 'string' is not assignable to parameter of type '"thanks_for_sharing" | "great_idea" | "working_on_it" | "would_love_to_talk_in_person" | "i_agree"'.
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            onConfirm(value);
          }
        }}
        onStateChange={(changes) => {
          if (changes.hasOwnProperty('isOpen')) {
            setOpen(Boolean(changes.isOpen));
          }
        }}
      >
        <Menu.Target
          aria-expanded={isOpen}
          aria-label={t('acknowledge__mark_as_read')}
          onClick={(e) => {
            // when we have just opened via mouse enter, ignore
            if (isOpen && mouseEnterRef.current) {
              e.preventDefault();
            }
          }}
          onMouseEnter={onShowMenu}
          onMouseLeave={onHideMenu}
        >
          <AcknowledgementTarget />
        </Menu.Target>
        <AcknowledgementMenu
          onMouseEnter={onShowMenu}
          onMouseLeave={onHideMenu}
          activeType={activeType}
        />
      </Menu>
      {activeType && shouldConfirm && (
        <AcknowledgementModal
          onCancel={() => {
            setActiveType(null);
            setOpen(false);
          }}
          onConfirm={({ optOut }) => {
            return onConfirm(activeType, { optOut });
          }}
          type={activeType}
        />
      )}
    </Fragment>
  );
};

// eslint-disable-next-line import/no-default-export
export default AcknowledgementDropdown;
