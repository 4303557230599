import { t } from '../../../../../features/i18next/t';
import { type ProductName } from '../types';
import { isActive, getPath, getDefaultQueryParams } from '../utils';

const improve: ProductName = 'improve';

export const getImproveMenu = ({
  rights,
  contextId,
  companyName,
}: {
  rights: { [x: string]: string };
  contextId?: string;
  companyName: string;
}) => {
  return {
    name: improve,
    dataTestId: 'mpn-improve',
    menuLabel: t('multi_product__improve'),
    children: [
      {
        menuLabel: t('multi_product__improve'),
        name: improve,
        children: [
          {
            name: 'resources',
            menuLabel: t('multi_product__improve__menu_item__resources'),
            getPath: getPath({
              path: '/improve',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return Boolean(rights.improve && rights.dataset);
            },
            get isActive() {
              return isActive({
                matchingPaths: [
                  this.getPath({ includeParams: false }),
                  '/courses/:courseId',
                  '/courses/:courseId/lesson/:lessonId',
                ],
              });
            },
          },
          {
            name: 'companyResources',
            menuLabel: t(
              'multi_product__improve__menu_item__company_resources',
              { replace: { companyName } },
            ),
            getPath: getPath({
              path: '/improve/company',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return Boolean(rights.improve && rights.dataset);
            },
            get isActive() {
              return isActive({
                matchingPaths: [this.getPath({ includeParams: false })],
              });
            },
          },
        ],
      },
    ],
  };
};
