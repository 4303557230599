import { useMemo } from 'react';

import { useShellContext } from '@peakon/shell';
import { type NullableDashboardSession } from '@peakon/shell/types/Session';

import {
  type ProductName,
  type RawNavigation,
  type RawNavigationChild,
  type RawNavigationObject,
} from './types';
import { getRawNavigation } from './utils';
import { useProxyViewContext } from '../../../../contexts/ProxyViewContext';
import useWindowSize from '../../../../hooks/useWindowSize';

export const productFallBackOrder: ProductName[] = [
  'insights',
  'analysis',
  'improve',
  'administration',
  'employeeDashboard',
  'profile',
];

type ParseNavigationFn = ({
  rawNavigation,
}: {
  rawNavigation: RawNavigation;
}) => {
  navigation: RawNavigation;
  activeProductName: ProductName;
  activeMenuGroupName?: string | null;
  activeMenuPointName?: string | null;
  isFallbackActiveProduct: boolean;
};

const parseNavigation: ParseNavigationFn = ({ rawNavigation }) => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const navigation = {} as RawNavigation;
  let activeProductName;
  let activeMenuGroupName;
  let activeMenuPointName;
  let isFallbackActiveProduct = false;

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const rawNavigationEntries = Object.entries(rawNavigation) as [
    ProductName,
    RawNavigationObject,
  ][];

  rawNavigationEntries.forEach(([product, sideNavigation]) => {
    const availableMenuGroups: RawNavigationChild[] = [];
    let isProductActive = false;
    sideNavigation.children.forEach((menuGroup) => {
      const availableMenuPoints: RawNavigationChild[] = [];
      let isMenuGroupActive = false;
      menuGroup.children?.forEach((menuPoint) => {
        if (menuPoint?.isActive) {
          isMenuGroupActive = true;
          isProductActive = true;
          activeProductName = product;
          activeMenuGroupName = menuGroup.name;
          activeMenuPointName = menuPoint.name;
        }
        if (menuPoint?.shouldRender) {
          availableMenuPoints.push(menuPoint);
        }
      });
      if (availableMenuPoints.length > 0) {
        availableMenuGroups.push({
          ...menuGroup,
          isActive: isMenuGroupActive,
          children: availableMenuPoints,
        });
      }
    });
    if (availableMenuGroups.length > 0) {
      navigation[product] = {
        ...sideNavigation,
        isActive: isProductActive,
        children: availableMenuGroups,
      };
    }
  });

  // this can happen if the user typed a URL which we cannot match to any product. In that case, we want to use a fallback
  if (!activeProductName) {
    for (const fallbackProductName of productFallBackOrder) {
      if (navigation[fallbackProductName]) {
        const fallbackMenuGroup = navigation[fallbackProductName]?.children[0];
        const fallbackMenuPoint = fallbackMenuGroup?.children
          ? fallbackMenuGroup.children[0]
          : null;

        activeProductName = fallbackProductName;
        activeMenuGroupName = fallbackMenuGroup?.name || null;
        activeMenuPointName = fallbackMenuPoint?.name || null;
        isFallbackActiveProduct = true;
        break;
      }
    }
  }

  return {
    navigation,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    activeProductName: activeProductName as ProductName,
    activeMenuGroupName,
    activeMenuPointName,
    isFallbackActiveProduct,
  };
};

type UseNavigationParams = {
  isTest?: boolean;
};

/**
 * Get the navigation object, containing only the products and menu points the user has access to.
 * @returns {object} the navigation object which only contains the products and menu points the user has access to.
 * */

export const useNavigation = ({ isTest = false }: UseNavigationParams = {}) => {
  const { proxyViewId } = useProxyViewContext();
  const { width: screenWidth } = useWindowSize();

  const {
    rights,
    context,
    session,
    isLoading,
    isConsultant,
    mainCategoryGroup: categoryGroup,
    mainCategoryGroupWithDrivers: categoryGroupWithDrivers,
  } = useShellContext<NullableDashboardSession>();

  const companyName =
    session?.data.relationships?.company?.attributes.name ?? '';

  const contextId = context?.id;
  const contextLevel = context?.attributes.level;
  const contextIsDirect = context?.attributes.direct;

  const {
    navigation,
    activeProductName,
    activeMenuGroupName,
    activeMenuPointName,
    isFallbackActiveProduct,
  } = useMemo(() => {
    const rawNavigation = getRawNavigation({
      rights,
      categoryGroup,
      categoryGroupWithDrivers,
      companyName,
      contextId,
      contextLevel,
      contextIsDirect,
      screenWidth,
      isConsultant,
      isProxyViewing: Boolean(proxyViewId),
      features: session?.data?.attributes?.features ?? [],
      isTest,
      addOns: session?.data.relationships?.company?.attributes.addOns ?? [],
    });

    return parseNavigation({ rawNavigation });
  }, [
    categoryGroup,
    categoryGroupWithDrivers,
    companyName,
    contextId,
    contextIsDirect,
    contextLevel,
    isConsultant,
    proxyViewId,
    rights,
    screenWidth,
    session?.data?.attributes?.features,
    isTest,
    session?.data.relationships?.company?.attributes.addOns,
  ]);

  return {
    isLoading,
    navigation,
    activeProductName,
    activeMenuGroupName,
    activeMenuPointName,
    categoryGroup,
    categoryGroupWithDrivers,
    companyName,
    isFallbackActiveProduct,
  };
};
