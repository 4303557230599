import { createSelector } from 'reselect';

import { isOtherEmployee } from './EmployeeSelectors';
import { type RootState } from '../types/redux';
import { createGetDatasetParams } from '../utils/createGetDatasetParams';

const settingsSelector = (state: RootState) => state.settings;
export const sessionSelector = (state: RootState) => state.session;

export const isConsultant = createSelector(
  [sessionSelector, isOtherEmployee],
  (session, otherEmployee) =>
    // FIXME-ProxyView: better way to check for a consultant then relying on scopeGroup
    Boolean(
      session.isOtherCompany || (session.scopeGroup === 'all' && otherEmployee),
    ),
);

export const getDatasetParams = createSelector(
  [settingsSelector, sessionSelector],
  (settings, session) => {
    // NOTE: shared dashboard does not have a 'settings' reducer
    return createGetDatasetParams({
      isTestMode: settings?.test,
      isShare: session.isShare,
      closesAt: settings?.closesAt,
    });
  },
);

export const getDashboardSettings = createSelector(
  [settingsSelector],
  ({ closesAt }) => ({ closesAt }),
);

export const getAnalyticsEnrichmentData = createSelector(
  [sessionSelector],
  (session) => session.raw?.attributes.mixpanel,
);

export const getEmployeeFromSession = createSelector(
  [sessionSelector],
  (session) => {
    return {
      accountSessionId: session.accountId,
      employeeId: session.employeeId,
      employeeAccountId: session.employeeAccountId,
    };
  },
);
