import { abbreviation } from '@peakon/engage-helpers';

export function getAbbreviation(
  firstName?: string | null,
  lastName?: string | null,
) {
  const words = [firstName ?? ''];

  if (lastName) {
    words.push(lastName);
  }

  return abbreviation.abbreviateWords(words);
}

export function getAbbreviationFromName(name: string) {
  return abbreviation.abbreviate(name);
}
