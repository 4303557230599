import { List, Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import type EmployeeRecord from './EmployeeRecord';

const schema = z.object({
  isLoadingNext: z.boolean().optional(),
  members: z.array(z.object({})).optional(),
  nextUrl: z.string().optional(),
  total: z.number().optional(),
  totalFiltered: z.number().nullish(),
});
type Schema = Omit<z.infer<typeof schema>, 'members'> & {
  members?: List<EmployeeRecord>;
};

class BulkNotify extends Record<Schema>({
  isLoadingNext: false,
  members: List(),
  nextUrl: undefined,
  total: undefined,
  totalFiltered: undefined,
}) {
  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'BulkNotify',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }
}

// eslint-disable-next-line import/no-default-export
export default BulkNotify;
