import includes from 'lodash/includes';

import {
  timezones as timezoneData,
  type TimezoneGroupValue,
  type TimezoneValue,
} from '@peakon/shared/data/timezones';
import { t } from '@peakon/shared/features/i18next/t';

const EXCLUDED_GROUPS = ['Manual Offsets', 'US (Common)'];

type TimezoneItem = {
  label: string;
  value: TimezoneValue | 'not_set';
  group: TimezoneGroupValue | null;
};

function getTimezones(isFilter?: boolean) {
  const timezones = timezoneData
    .filter((item) => !includes(EXCLUDED_GROUPS, item.group))
    .reduce((list: TimezoneItem[], group) => {
      const zones = group.zones.map((zone) => ({
        label: zone.name,
        value: zone.value,
        group: group.group,
      }));

      return list.concat(zones);
    }, []);

  if (isFilter) {
    timezones.push({
      group: null,
      label: t('employees__filter__not-set'),
      value: 'not_set',
    });
  }
  return timezones;
}

// eslint-disable-next-line import/no-default-export
export default getTimezones;
