import React, { useState } from 'react';

import classnames from 'classnames';

import { UnstyledButton } from '@peakon/bedrock/react/button';
import { Modal } from '@peakon/bedrock/react/dialog';
import { Spinner } from '@peakon/bedrock/react/spinner';
import { t } from '@peakon/shared/features/i18next/t';

import EmailPreviewBody from './EmailPreviewBody';
import usePreviewQuery from './queries/usePreviewQuery';
import {
  type PreviewType,
  type Variation,
  type TemplateOptions,
} from './types';
import { LanguageSelector } from '../LanguageSelector';

import styles from './styles.css';

type Props = {
  type?: PreviewType;
  isOpen: boolean;
  onClose: () => void;
  /**
   * List of locales that are supported by the email template.
   * If not provided, the language selector will show all the available locales.
   */
  supportedLocales?: string[];
  templateOptions?: TemplateOptions;
  templateVariations?: Variation[];
};

const EmailPreviewModal = ({
  supportedLocales,
  isOpen,
  onClose,
  templateOptions,
  type,
  templateVariations,
}: Props) => {
  const [locale, setLocale] = useState<string | undefined>(undefined);
  const [selectedVariation, setSelectedVariation] = useState<
    Variation | undefined
  >(templateVariations?.at(0));

  const handleClose = () => {
    onClose();
    setLocale(undefined);
    setSelectedVariation(templateVariations?.at(0));
  };

  const {
    data: preview,
    isLoading,
    isError,
  } = usePreviewQuery({
    enabled: isOpen,
    onError: handleClose,
    type,
    variation: selectedVariation,
    locale,
    templateOptions,
  });

  const onChangeLocale = (newLocale: string | null) => {
    setLocale(newLocale ?? undefined);
  };

  const onChangeVariation = (newVariation: Variation) => {
    setSelectedVariation(newVariation);
  };

  return (
    <Modal
      heading={t('sendouts__settings__email-preview__header')}
      open={isOpen}
      onDismiss={handleClose}
      closeLabel={t('common__close')}
      size="medium"
      data-test-id="email-preview-modal"
    >
      <div className={styles.toolbar}>
        {templateVariations && (
          <div className={styles.variations}>
            {templateVariations.map((variation) => (
              <UnstyledButton
                key={variation.id}
                accessibleName={variation.label}
                className={classnames({
                  [styles.active]: selectedVariation?.id === variation.id,
                })}
                onClick={() => onChangeVariation(variation)}
              >
                {variation.label}
              </UnstyledButton>
            ))}
          </div>
        )}

        {/* hide the language selector when there is only 1 locale to pick from */}
        {supportedLocales?.length !== 1 && (
          <div className={styles.languageSelector}>
            <LanguageSelector
              defaultValueLabel={t('survey_email_preview__language_automatic')}
              forcedLocales={supportedLocales}
              selected={locale}
              onSelect={onChangeLocale}
            />
          </div>
        )}
      </div>

      <Modal.Content>
        {isLoading || isError ? (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        ) : (
          <EmailPreviewBody locale={locale} preview={preview} />
        )}
      </Modal.Content>
    </Modal>
  );
};

// eslint-disable-next-line import/no-default-export
export default EmailPreviewModal;
