import { t } from '../../../../../features/i18next/t';
import { type ProductName } from '../types';
import { isActive, getPath } from '../utils';

const employeeDashboard: ProductName = 'employeeDashboard';

export const getEmployeeDashboardMenu = ({
  rights,
}: {
  rights: { [x: string]: string };
  features: string[];
}) => {
  return {
    name: employeeDashboard,
    dataTestId: 'mpn-employee-dashboard',
    menuLabel: t('multi_product__employee_dashboard'),
    children: [
      {
        menuLabel: t('multi_product__employee_dashboard'),
        name: employeeDashboard,
        children: [
          {
            name: 'overview',
            menuLabel: t(
              'multi_product__employee_dashboard__menu_item__overview',
            ),
            dataTestId: 'personal_dashboard__menu-item__overview',
            getPath: getPath({
              path: '/employee-dashboard',
            }),
            get shouldRender() {
              return Boolean(rights.employeeDashboard);
            },
            get isActive() {
              return isActive({
                matchingPaths: [this.getPath({ includeParams: false })],
              });
            },
          },
          {
            name: 'scores',
            menuLabel: t(
              'multi_product__employee_dashboard__menu_item__your_scores_v2',
            ),
            dataTestId: 'personal_dashboard__menu-item__your-scores',
            getPath: getPath({
              path: '/employee-dashboard/my-scores',
            }),
            get shouldRender() {
              return Boolean(rights.employeeDashboard);
            },
            get isActive() {
              return isActive({
                matchingPaths: [
                  this.getPath({ includeParams: false }),
                  '/employee-dashboard/my-scores/group/:categoryGroup/category/:categoryId',
                ],
              });
            },
          },
          {
            name: 'comments',
            menuLabel: t(
              'multi_product__employee_dashboard__menu_item__your_comments',
            ),
            dataTestId: 'personal_dashboard__menu-item__your-comments',
            getPath: getPath({
              path: '/employee-dashboard/my-comments',
            }),
            get shouldRender() {
              return Boolean(rights.employeeDashboard);
            },
            get isActive() {
              return isActive({
                matchingPaths: [
                  this.getPath({ includeParams: false }),
                  '/employee-dashboard/my-comments/comment/:id',
                ],
              });
            },
          },
        ],
      },

      {
        menuLabel: t('multi_product__employee_dashboard__menu_item__your_team'),
        name: 'your-team',
        children: [
          {
            name: 'focus-areas',
            menuLabel: t(
              'multi_product__employee_dashboard__menu_item__focus_areas',
            ),
            dataTestId: 'personal_dashboard__menu-item__focus-areas',
            getPath: getPath({
              path: '/employee-dashboard/focus-areas',
            }),
            get shouldRender() {
              return Boolean(rights.employeeDashboard);
            },
            get isActive() {
              return isActive({
                matchingPaths: [this.getPath({ includeParams: false })],
              });
            },
          },
          {
            name: 'manager-actions',
            menuLabel: t(
              'multi_product__employee_dashboard__menu_item__manager_actions',
            ),
            dataTestId: 'personal_dashboard__menu-item__manager-actions',
            getPath: getPath({
              path: '/employee-dashboard/manager-actions',
            }),
            get shouldRender() {
              return Boolean(rights.employeeDashboard);
            },
            get isActive() {
              return isActive({
                matchingPaths: [this.getPath({ includeParams: false })],
              });
            },
          },
        ],
      },
    ],
  };
};
