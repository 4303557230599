import { createContext, useContext } from 'react';

import { type ProductTourKey } from '../providers/ProductTourContextProvider/types';

export type ProductTourContextType = {
  tour: ProductTourKey | null;
  setTour: (tour: ProductTourKey) => void;
};

export const ProductTourContext = createContext<
  ProductTourContextType | undefined
>(undefined);

export function useProductTourContext() {
  const productTourContext = useContext(ProductTourContext);

  if (!productTourContext) {
    throw new Error(
      '`useProductTourContext` used outside of `ProductTourContextProvider`.',
    );
  }

  return productTourContext;
}
