import { Record, Map } from 'immutable';

import type AccessGroupRecord from './AccessGroupRecord';
import type BrandingRecord from './BrandingRecord';
import type MetricRecord from './externalMetrics/MetricRecord';
import { type ObservationRecord } from './externalMetrics/ObservationRecord';
import type ScheduleRecord from './ScheduleRecord';

type EditableRecord =
  | AccessGroupRecord
  | BrandingRecord
  | MetricRecord
  | ObservationRecord
  | ScheduleRecord;

class FormEditorRecord extends Record({
  current: undefined,
  original: undefined,
}) {
  current!: EditableRecord;
  original!: EditableRecord;

  isEditing() {
    return typeof this.original !== 'undefined';
  }

  isDirty() {
    if (!this.isEditing()) {
      return false;
    }

    if (typeof this.current.equals === 'function') {
      return !this.current.equals(this.original);
    }

    return this.current !== this.original;
  }

  diff() {
    if (!this.current) {
      return Map();
    }

    // @ts-expect-error Argument of type '(modified: Map<unknown, unknown> | undefined...
    return this.current.keySeq().reduce((modified, key) => {
      if (!key || !modified) {
        return modified;
      }

      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to parameter ...
      const current = this.current.get(key);
      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to parameter ...
      const original = this.original.get(key);

      return current !== original ? modified.set(key, current) : modified;
    }, Map());
  }
}

export class AccessGroupFormEditorRecord extends FormEditorRecord {
  current!: AccessGroupRecord;
  original!: AccessGroupRecord;

  static startEditing(original: AccessGroupRecord) {
    return new AccessGroupFormEditorRecord({
      original,
      current: original,
    });
  }
}

export class BrandingFormEditorRecord extends FormEditorRecord {
  current!: BrandingRecord;
  original!: BrandingRecord;

  static startEditing(original: BrandingRecord) {
    return new BrandingFormEditorRecord({
      original,
      current: original,
    });
  }
}

export class ScheduleFormEditorRecord extends FormEditorRecord {
  current!: ScheduleRecord;
  original!: ScheduleRecord;

  static startEditing(original: ScheduleRecord) {
    return new ScheduleFormEditorRecord({
      original,
      current: original,
    });
  }
}

// eslint-disable-next-line import/no-default-export
export default FormEditorRecord;
