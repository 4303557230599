import React, { useMemo } from 'react';

import get from 'lodash/get';
import times from 'lodash/times';
import { InView } from 'react-intersection-observer';

import { NotificationsNotificationsBellOutlineIcon as NotificationIcon } from '@peakon/bedrock/icons/system';
import { Spinner } from '@peakon/bedrock/react/spinner';
import { Heading5 } from '@peakon/bedrock/react/typography';
import { Menu } from '@peakon/components';
import type PeakonApi from '@peakon/peakon-js';
import { t } from '@peakon/shared/features/i18next/t';
import api from '@peakon/shared/utils/api';
import { ErrorBoundary, useShellContext } from '@peakon/shell';

import { EmptyNotification } from './Empty';
import { Notification } from './Notification';
import { type NormalizedNotificationItem } from './Notification/types';
import useNotifications from './useNotifications';

import styles from './styles.css';
import otherStyles from '../styles.css';

type NotificationsDropdownProps = {
  client: PeakonApi;
  employeeId: string | number;
};

const NotificationsDropdown = ({
  client,
  employeeId,
}: NotificationsDropdownProps) => {
  const {
    unreadNotifications,
    newNotifications,
    unread,
    isInitialLoading,
    isEmpty,
    isLoading,
    isLoadingMore,
    hasMore,
    loadMore,
    onNewNotifications,
    onToggleMenu,
  } = useNotifications({
    client,
    employeeId,
  });

  const hasUnread = unread > 0;
  const hasNewNotifications = newNotifications > 0;

  return (
    <Menu
      id="notifications"
      onStateChange={(changes) => {
        if (changes.hasOwnProperty('isOpen')) {
          onToggleMenu(changes.isOpen);
        }
      }}
    >
      <Menu.Target
        aria-label={
          hasUnread
            ? t('multi_product__a11y__notifications_unread', {
                replace: {
                  count: unread,
                },
              })
            : t('multi_product__a11y__notifications')
        }
      >
        <div className={otherStyles.iconLink}>
          <NotificationIcon />
          {hasUnread && (
            <div className={styles.badge}>{unread > 99 ? '99+' : unread}</div>
          )}
        </div>
      </Menu.Target>
      <Menu.Popover
        className={styles.menu}
        placement="bottom"
        strategy="fixed"
        maxHeight={420}
      >
        <div className={styles.header}>
          <Heading5 level={2}>{t('multi_product__notifications')}</Heading5>
        </div>
        {hasNewNotifications && (
          <Menu.Item
            // @ts-expect-error React-18 Type 'DetailedHTMLFactory<WebViewHTMLAttributes<HTMLWebViewElement>, HTMLWebViewElement>' is not assignable to type 'ReactNode'.ts(2322)
            onClick={(event: React.MouseEvent<React.ReactNode>) => {
              event.preventDefault();
              event.stopPropagation();

              onNewNotifications();
            }}
            value="new"
            data-preserve-outside
          >
            {t('multi_product__notifications__dropdown__new_notifications', {
              replace: {
                count: newNotifications,
              },
            })}
          </Menu.Item>
        )}
        {isInitialLoading ? (
          times(5).map((i) => (
            // @ts-expect-error TS(2322): Type '{ component: ForwardRefExoticComponent<Props... Remove this comment to see the full error message
            <Menu.Item key={i} component={Notification} disabled isLoading />
          ))
        ) : isEmpty ? (
          <Menu.Item component={EmptyNotification} value="empty" />
        ) : (
          unreadNotifications.map(
            (notification: NormalizedNotificationItem) => (
              <Menu.ItemLink
                key={notification?.id}
                // @ts-expect-error TS(2322): Type '{ children: any; component: ForwardRefExotic... Remove this comment to see the full error message
                component={Notification}
                notification={notification}
                value={notification?.id}
              >
                {notification?.id}
              </Menu.ItemLink>
            ),
          )
        )}
        {!isLoading && hasMore && (
          <div role="none" className={styles.loader}>
            <InView onChange={(visible: boolean) => visible && loadMore()}>
              <Spinner />
            </InView>
          </div>
        )}
        {isLoadingMore && (
          <div role="none" className={styles.loader}>
            <Spinner />
          </div>
        )}
      </Menu.Popover>
    </Menu>
  );
};

// This component is necessary to conditionaly call the useRealtimeChannel
const NotificationsDropdownWrapper = () => {
  const { session } = useShellContext();

  const { hasNotificationRight } = useMemo(() => {
    const rights = get(session, 'data.attributes.rights', []);

    return {
      // @ts-expect-error TS2345: Argument of type 'string' is not assignable to parameter of type 'never'.
      hasNotificationRight: rights.includes('notification:read'),
    };
  }, [session]);

  if (!hasNotificationRight) {
    return null;
  }

  const employeeId = get(session, 'data.relationships.employee.id');

  return (
    <ErrorBoundary>
      {/* @ts-expect-error TS2322: Type 'undefined' is not assignable to type 'string | number'. */}
      <NotificationsDropdown client={api} employeeId={employeeId} />
    </ErrorBoundary>
  );
};

// eslint-disable-next-line import/no-default-export
export default NotificationsDropdownWrapper;
