import React from 'react';

import { useController } from 'react-hook-form';
import { type z } from 'zod';

import { InputField as BRInputField } from '@peakon/bedrock/react/form';

import {
  type ControlledProps,
  type ZodSchemaOrRecord,
} from '../../utils/types';
import { useRequiredFields } from '../RequiredFieldsProvider';

type InputFieldProps = React.ComponentPropsWithoutRef<typeof BRInputField>;

type Props<TSchema extends ZodSchemaOrRecord> = Omit<
  ControlledProps<TSchema, InputFieldProps>,
  'type'
> & {
  type?: InputFieldProps['type'];
};

export function InputField<TSchema extends ZodSchemaOrRecord = z.ZodRawShape>(
  props: Props<TSchema>,
) {
  const {
    name,
    type = 'text',
    shouldUnregister,
    disabled,
    required,
    ...restInputProps
  } = props;

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    shouldUnregister,
    disabled,
  });

  const requiredFields = useRequiredFields();
  const isRequired = Boolean(required ?? requiredFields?.includes(name));

  const { value, ...restField } = field;

  return (
    <BRInputField
      status={error && 'error'}
      feedbackMessage={error?.message}
      disabled={disabled}
      required={isRequired}
      type={type}
      value={value ?? ''}
      {...restInputProps}
      {...restField}
    />
  );
}
