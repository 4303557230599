// eslint-disable-next-line no-restricted-imports -- Automatically disabled here to enable the rule globally
import qs from 'qs';
import localStorage from 'store2';

import { type IntegrationID } from '@peakon/peakon-js';
import { SLACK_SCOPES } from '@peakon/shared/constants/slack';
import { t } from '@peakon/shared/features/i18next/t';
import { exhaustiveMatchingGuard } from '@peakon/shared/utils/typescript/exhaustiveMatchingGuard';

import { validate } from '../../repositories';
import { redirectURI } from '../administration/routes/integrations/utils';

type Response =
  | {
      data: $TSFixMe;
      error?: never;
    }
  | {
      data?: never;
      error: string;
    };

const slackResponseChecker = (locationSearch: string): Response => {
  const { state, code, error } = qs.parse(locationSearch, {
    ignoreQueryPrefix: true,
  });

  if (error) {
    return {
      error:
        error === 'access_denied'
          ? t('slack__auth__denied')
          : // @ts-expect-error TS(2769): No overload matches this call.
            t('slack__auth__error', error),
    };
  } else if (validate('slack', state)) {
    return {
      data: {
        code,
        redirectURI: redirectURI('/slack/complete'),
        scopes: SLACK_SCOPES,
      },
    };
  }

  return {
    error: t('slack__auth__invalid-state'),
  };
};

const workdayResponseChecker = (locationSearch: string): Response => {
  const { state, code, error } = qs.parse(locationSearch, {
    ignoreQueryPrefix: true,
  });

  const data = localStorage.get('peakon.workday');
  if (error) {
    return {
      error:
        error === 'access_denied'
          ? t('workday__auth__denied')
          : // @ts-expect-error TS(2769): No overload matches this call.
            t('workday__auth__error', error),
    };
  }
  if (validate('workday', state)) {
    return {
      data: {
        ...data,
        code,
      },
    };
  }
  return {
    error: t('workday__auth__invalid-state'),
  };
};

const bambooResponseChecker = (locationSearch: string): Response => {
  const {
    state,
    code,
    error,
    error_description: errorDescription,
  } = qs.parse(locationSearch, {
    ignoreQueryPrefix: true,
  });

  if (error) {
    if (error === 'access_denied') {
      return {
        error: t('bamboohr__auth__denied'),
      };
    } else if (errorDescription) {
      return {
        // @ts-expect-error TS(2769): No overload matches this call.
        error: t('bamboohr__auth__error', errorDescription),
      };
    }
  } else if (validate('bamboohr', state)) {
    const subdomain = localStorage.get('peakon.bamboohr.subdomain');

    return {
      data: {
        subdomain,
        code,
        redirect_uri: redirectURI('/bamboohr/complete'),
      },
    };
  }

  return {
    error: t('bamboohr__auth__invalid-state'),
  };
};

const microsoftTeamsResponseChecker = (locationSearch: string): Response => {
  const {
    state,
    tenant,
    admin_consent: adminConsent,
    error,
  } = qs.parse(locationSearch, {
    ignoreQueryPrefix: true,
  });

  // Microsoft Booleans ¯\_(ツ)_/¯
  if (error || adminConsent === 'False') {
    return {
      error:
        error === 'access_denied'
          ? t('microsoft-teams__auth__denied')
          : // @ts-expect-error TS(2769): No overload matches this call.
            t('microsoft-teams__auth__error', error),
    };
  } else if (validate('microsoft-teams', state)) {
    return {
      data: {
        tenantId: tenant,
      },
    };
  }

  return {
    error: t('microsoft-teams__auth__invalid-state'),
  };
};

export type OAuthService = Extract<
  IntegrationID,
  'slack' | 'microsoft-teams' | 'bamboohr' | 'workday'
>;
export const responseCheckerFactory = (service: OAuthService) => {
  switch (service) {
    case 'slack':
      return slackResponseChecker;
    case 'workday':
      return workdayResponseChecker;
    case 'bamboohr':
      return bambooResponseChecker;
    case 'microsoft-teams':
      return microsoftTeamsResponseChecker;
    default:
      return exhaustiveMatchingGuard(service);
  }
};
