import React from 'react';

import moment from 'moment';

import { Token } from '@peakon/components';
import { type Employee } from '@peakon/records';
import { t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

type Props = {
  employee: Employee;
};

export const EmploymentStatusLabel = ({ employee }: Props) => {
  const attributes = employee.get('attributes');
  const employmentStatus = attributes.get('employmentStatus');

  const employmentEnd = attributes?.get?.('employmentEnd');
  const isHired = employmentStatus === 'hired';
  const hasLeft = employmentStatus === 'left';

  if (isHired) {
    return (
      <div className={styles.root}>
        <Token size="tiny" type="default">
          {t('employees-page__hired')}
        </Token>
      </div>
    );
  }

  if (employmentEnd) {
    return (
      <div className={styles.root}>
        <Token size="tiny" type="negative">
          {/* @ts-expect-error Type '{ id: string; email: string | null; _type: "accounts"; }' is not assignable to type 'MomentInput'.ts(2345) */}
          {renderLeaving(employmentEnd)}
        </Token>
      </div>
    );
  }

  if (hasLeft) {
    return (
      <div className={styles.root}>
        <Token size="tiny" type="negative">
          {t('employees-page__leaving_left')}
        </Token>
      </div>
    );
  }

  return null;
};

function renderLeaving(employmentEnd: moment.MomentInput) {
  const leaverDate = moment(employmentEnd);
  const isToday = leaverDate.isSame(new Date(), 'day');
  const isPast = leaverDate.diff(new Date(), 'day') < 0;

  if (isToday) {
    return t('employees-page__leaving__leaving_today');
  }

  if (isPast) {
    return t('employees-page__leaving_left');
  }

  return t('employees-page__leaving__leaving_date', {
    replace: {
      date: leaverDate.format('L'),
    },
  });
}
