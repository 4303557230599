import { useMemo, useRef } from 'react';

import { useHistory, useLocation } from 'react-router';
import { type z } from 'zod';

import { type Nullish } from '../utils/typescript/typeHelpers';

type NavigationOptions = {
  /**
   * The navigation method to use when updating the search parameters.
   */
  method: 'replace' | 'push';
};

/**
 * A custom hook that facilitates access to and modification of URL search parameters.
 *
 * @returns A tuple consisting of the URLSearchParams object for reading search parameters and a function to
 * update these parameters.
 *
 * @example
 *
 * // Access and use the search parameters
 * const [urlSearchParams, setSearchParams] = useSearchParams();
 *
 * // Reading a search parameter
 * const searchValue = urlSearchParams.get('viewBy');
 *
 * // Updating a search parameter
 * urlSearchParams.set('viewBy', 'engagement');
 * setSearchParams(urlSearchParams);
 *
 * // Replacing all search parameters
 * // Caution: This replaces all existing parameters, potentially deleting crucial ones like the contextId
 * const newParams = new URLSearchParams();
 * newParams.set('viewBy', 'engagement');
 * setSearchParams(newParams);
 */
export const useSearchParams = () => {
  const location = useLocation();
  const history = useHistory();

  const urlSearchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  /**
   * Updates the search parameters.
   *
   * @param nextParams URLSearchParams with the updated parameters.
   * @param options - Optional. Navigation options used when updating the parameters. Default is `{ method: 'replace' }`.
   */
  const setSearchParams = useMemo(
    () =>
      (
        nextParams: URLSearchParams,
        options: NavigationOptions = { method: 'replace' },
      ) => {
        history[options.method]({ search: nextParams.toString() });
      },
    [history],
  );

  return [urlSearchParams, setSearchParams] as const;
};

/**
 * A custom hook to access, validate, and update URL search parameters
 *
 * This hook will NOT recompute the parameters if the validationSchema is changed on-the-fly. This is to avoid unnecessary re-renders.
 *
 * @param {Object} [options]
 * @param {Function} options.validationSchema - Zod schema to parse and validate the search parameters. The hook will only retrieve search params that are listed in the zod schema.
 * @returns A tuple with the validated parameters object and a function to update these parameters.
 *
 * @example
 *
 * // Using the hook to get the search parameters
 * const [filters, setFilters] = useValidatedSearchParams({
 *   validationSchema: z.object({
 *    viewBy: z.string().catch('all'),
 *   sortBy: z.string().catch('group'),
 * }),
 * });
 *
 * // Accessing the search parameters
 * console.log(filters.viewBy); // Outputs the value of 'viewBy' parameter
 * console.log(filters.sortBy); // Outputs the value of 'sortBy' parameter
 *
 * // Updating the search parameters
 * setFilters({ ...filters, viewBy: 'engagement' }); // Sets 'viewBy' parameter to 'engagement'
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useValidatedSearchParams = <TSchema extends z.ZodObject<any>>({
  validationSchema,
}: {
  validationSchema: TSchema;
}) => {
  const [urlSearchParams, setSearchParams] = useSearchParams();
  // setting a ref to the validationSchema to avoid re-computing the parameters on every render if the schema is inlined in the consumer components
  const stableValidationSchema = useRef(validationSchema);
  stableValidationSchema.current = validationSchema;

  const params: z.infer<TSchema> = useMemo(() => {
    const extractedParams: Record<string, unknown> = {};

    const schemaKeys = stableValidationSchema.current.shape;

    Object.keys(schemaKeys).forEach((key) => {
      const value = urlSearchParams.get(key);
      extractedParams[key] = value;
    });

    return stableValidationSchema.current.parse(extractedParams);
  }, [urlSearchParams]);

  /**
   * Updates the search parameters based on the provided input.
   *
   * @param nextParams This parameter an object containing the updated search parameters.
   * @param options Optional. Navigation options used when updating the parameters. Default is `{ method: 'replace' }`.
   */
  const setParams = useMemo(
    () =>
      (
        nextParams: Nullish<z.infer<TSchema>>,
        options: NavigationOptions = { method: 'replace' },
      ) => {
        Object.entries(nextParams).forEach(([key, value]) => {
          if (typeof value === 'undefined' || value === null) {
            urlSearchParams.delete(key);
          } else {
            urlSearchParams.set(key, value.toString());
          }
        });
        setSearchParams(urlSearchParams, options);
      },
    [setSearchParams, urlSearchParams],
  );

  return [params, setParams] as const;
};
