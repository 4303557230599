import React, { Fragment, useContext, useState } from 'react';

import sum from 'lodash/sum';
import toArray from 'lodash/toArray';

import { type AcknowledgementType } from '@peakon/shared/constants/acknowledgementTypes';
import { analytics } from '@peakon/shared/features/analytics/analytics';
import { getCurrentLocaleInfo } from '@peakon/shared/features/i18next/helpers';
import { t } from '@peakon/shared/features/i18next/t';

import { FeedbackType } from './CommentFeedback/CommentFeedback';
import {
  Comment as CommentComponent,
  type Props as CommentComponentProps,
} from './Core';
import {
  listCommentManagers,
  onTranslate,
  onRevertTranslate,
  sendFeedback,
  sendSemanticTopicsFeedback,
  deleteComment,
  acknowledgeComment,
  markAsSensitive,
  markNotSensitive,
} from '../../actions/CommentActions';
import { showSuccessNotification } from '../../actions/NotificationActions';
import { complete as completeUXFlowAction } from '../../actions/UxFlowsActions';
import { ConversationsContext } from '../../context/ConversationsContext';
import { useDashboardContext } from '../../hooks/useDashboardContext';
import { useFeature } from '../../hooks/useFeature';
import {
  getAccountLocale,
  getAccountSettings,
} from '../../selectors/AccountSelectors';
import { companySelector } from '../../selectors/CompanySelectors';
import { sessionSelector } from '../../selectors/SessionSelectors';
import { isSameLanguage } from '../../utils/languageUtils';
import { useAppDispatch, useAppSelector } from '../../utils/reduxHooks';
import ConfirmModal from '../ConfirmModal';

export const Comment = CommentComponent;

export type Props = {
  sessionRights?: boolean;
  searchTerm?: string;
  isCommentsPage?: boolean;
} & Pick<
  CommentComponentProps,
  | 'comment'
  | 'withHighlightBanner'
  | 'segmentId'
  | 'isExplore'
  | 'topicId'
  | 'feedbackType'
  | 'commentFeedback'
  | 'commentDateVisibility'
  | 'shouldConfirmAcknowledgement'
>;

// eslint-disable-next-line import/no-default-export
export default function ConnectedComment({
  sessionRights,
  isCommentsPage,
  isExplore,
  withHighlightBanner = true,
  shouldConfirmAcknowledgement,
  searchTerm,
  topicId,
  segmentId,
  commentDateVisibility,
  comment,
  commentFeedback,
  feedbackType,
}: Props) {
  const dispatch = useAppDispatch();
  const dashboardContext = useDashboardContext();
  const session = useAppSelector(sessionSelector);
  const company = useAppSelector(companySelector);
  const accountLocale = useAppSelector(getAccountLocale);
  const categories = useAppSelector((state) => state.categories);
  const accountSettings = useAppSelector(getAccountSettings);

  const rightChecker = sessionRights ? session : dashboardContext;

  const canDelete = rightChecker.hasRight(`comment:${comment.type}:delete`);
  const canMarkAsSensitive = rightChecker.hasRight('comment:sensitive:mark');
  const hasAcknowledgements = rightChecker.hasRight(
    `comment:${comment.type}:acknowledge`,
  );

  const hasCommentManagers = rightChecker.hasRight(
    `comment:${comment.type}:managers`,
  );

  const hasCommentRank = company.addOns.includes('comment_rank');
  const hasConversations = rightChecker.hasRight('conversation:read');

  const companyCommentMachineTranslations = company.settings.get(
    'commentMachineTranslations',
  );

  const locale = getCurrentLocaleInfo();

  const hasNewTranslations = useFeature('useCommentMachineTranslations');
  const hasNewAutomaticTranslations =
    companyCommentMachineTranslations === 'on' &&
    accountSettings.commentMachineTranslationMode !== 'original' &&
    Boolean(comment.commentTranslation);
  const hasNewManualTranslations = Boolean(
    ((companyCommentMachineTranslations === 'on' &&
      accountSettings.commentMachineTranslationMode === 'original') ||
      companyCommentMachineTranslations === 'one-by-one') &&
      comment.locale &&
      !isSameLanguage(comment.locale, locale.id) &&
      !comment.commentTranslation,
  );

  const hasTranslations = hasNewTranslations
    ? hasNewAutomaticTranslations || hasNewManualTranslations
    : Boolean(
        company.hasAddOn('google_translate') &&
          comment.locale &&
          !isSameLanguage(comment.locale, locale.id),
      );

  const category =
    comment.question?.category ||
    (comment.categoryId ? categories.get(comment.categoryId) : undefined);

  const { onConversationToggle } = useContext(ConversationsContext);
  const [isDeleting, setIsDeleting] = useState(false);

  const onDelete = () => {
    setIsDeleting(true);
  };

  const onCancelDelete = () => {
    setIsDeleting(false);
  };

  const onConfirmDelete = async () => {
    await dispatch(deleteComment(comment.id));
  };

  const onAcknowledge = (
    type: AcknowledgementType,
    { optOutConfirm }: { optOutConfirm?: boolean },
  ) => {
    analytics.track('comment_acknowledge__clicked', {
      comment_locale: comment.locale,
      account_locale: accountLocale,
    });

    return dispatch(acknowledgeComment(comment.id, type)).then(() => {
      if (optOutConfirm) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        dispatch(
          completeUXFlowAction('account', 'dontAskConfirmationAcknowledge'),
        );
      }
    });
  };

  const onSendFeedback = async (
    isRelevant: boolean,
    isBiased: boolean,
    biasedComment: string,
  ) => {
    let result = true;
    try {
      if (feedbackType === FeedbackType.SemanticTopics) {
        await dispatch(
          sendSemanticTopicsFeedback({
            commentId: comment.id,
            isRelevant,
            isBiased,
            isBiasedComment: biasedComment,
            // @ts-expect-error - Type 'string | undefined' is not assignable to type 'string'.
            topicId,
          }),
        );
      } else {
        await dispatch(
          sendFeedback({
            commentId: comment.id,
            // @ts-expect-error - Type 'string | undefined' is not assignable to type 'string'.
            searchTerm,
            isRelevant,
            isBiased,
            isBiasedComment: biasedComment,
          }),
        );
      }
    } catch {
      result = false;
    }

    return result;
  };

  const onMarkAsSensitive = () => {
    return comment.sensitive
      ? dispatch(markNotSensitive(comment.id)).then(() => {
          dispatch(
            showSuccessNotification({
              message: t('sensitive_comments_unmarked'),
            }),
          );
        })
      : dispatch(markAsSensitive(comment.id)).then(() => {
          dispatch(
            showSuccessNotification({
              message: t('sensitive_comments_marked'),
            }),
          );
        });
  };

  const toggleConversation = () => {
    analytics.track('comment_conversation__clicked', {
      comment_locale: comment.locale,
      account_locale: accountLocale,
    });

    onConversationToggle(comment);
  };

  const acknowledgementCounts = comment.acknowledgementCounts?.toJS();
  const totalAcknowledgementCounts = hasAcknowledgements
    ? sum(toArray(acknowledgementCounts))
    : 0;

  /**
   *
   * If only the current user acknowledged the comment we won't show the counts
   *
   */

  const shouldShowAcknowledgementCounts =
    totalAcknowledgementCounts > (comment.acknowledgement ? 1 : 0);

  /**
   *
   * FIXME: Remove rights check?
   *
   * Pretty sure we should be able to remove this rights check since
   * we should never receive any semantic tags from the backend if
   * we are not allowed to access them?
   *
   */

  const isSemanticTagsEnabled = rightChecker.hasRight('comment:semanticTags');
  const semanticTopics = isSemanticTagsEnabled
    ? comment.semanticTags?.toArray().map((semanticTag) => {
        return {
          // @ts-expect-error - Property 'get' does not exist on type '{ locale: "id" | "en" | "en-GB" | "en-US" | "ar" | "az" | "bg" | "bn" | "ca" | "cs" | "da" | "de" | "el" | "es" | "es-LA" | "et" | "fi" | "fo" | "fr" | "fr-FR" | "fr-CA" | "gu-IN" | "he" | ... 43 more ... | "zu"; id: string; name: string & BRAND<...>; }'.
          id: semanticTag.get('id'),
          // @ts-expect-error - Property 'get' does not exist on type '{ id: string; locale: "id" | "en" | "en-GB" | "en-US" | "ar" | "az" | "bg" | "bn" | "ca" | "cs" | "da" | "de" | "el" | "es" | "es-LA" | "et" | "fi" | "fo" | "fr" | "fr-FR" | "fr-CA" | "gu-IN" | ... 44 more ... | "zu"; name: string & BRAND<...>; }'.
          name: semanticTag.get('name'),
          // @ts-expect-error - Property 'get' does not exist on type '{ id: string; locale: "id" | "en" | "en-GB" | "en-US" | "ar" | "az" | "bg" | "bn" | "ca" | "cs" | "da" | "de" | "el" | "es" | "es-LA" | "et" | "fi" | "fo" | "fr" | "fr-FR" | "fr-CA" | "gu-IN" | ... 44 more ... | "zu"; name: string & BRAND<...>; }'.
          locale: semanticTag.get('locale'),
        };
      })
    : undefined;
  const filteredSemanticTopics = semanticTopics
    ? topicId
      ? semanticTopics.filter((semanticTopic) => semanticTopic.id !== topicId)
      : semanticTopics
    : undefined;

  return (
    <Fragment>
      <Comment
        locale={locale}
        hasNewTranslations={hasNewTranslations}
        comment={comment}
        category={category || undefined}
        contextId={dashboardContext.id}
        segmentId={segmentId}
        semanticTopics={filteredSemanticTopics}
        isExplore={isExplore}
        onAcknowledge={hasAcknowledgements ? onAcknowledge : undefined}
        acknowledgementCounts={
          shouldShowAcknowledgementCounts ? acknowledgementCounts : undefined
        }
        onTranslate={(id) => {
          analytics.track('comment_translate__clicked', {
            comment_locale: comment.locale,
            account_locale: accountLocale,
          });

          return dispatch(
            onTranslate(id, { type: isCommentsPage ? 'overview' : undefined }),
          );
        }}
        onRevertTranslate={(id) => dispatch(onRevertTranslate(id))}
        onDelete={canDelete ? onDelete : undefined}
        onConversationsClick={hasConversations ? toggleConversation : undefined}
        onLoadManagers={
          hasCommentManagers
            ? () =>
                dispatch(
                  listCommentManagers(comment.id, {
                    singleComment: sessionRights,
                  }),
                )
            : undefined
        }
        isLoadingManagers={
          hasCommentManagers ? comment.isLoadingManagers : undefined
        }
        onMarkAsSensitive={canMarkAsSensitive ? onMarkAsSensitive : undefined}
        commentFeedback={commentFeedback}
        sendFeedback={onSendFeedback}
        translated={comment.translated}
        withHighlightBanner={hasCommentRank && withHighlightBanner}
        feedbackType={feedbackType}
        translatable={hasTranslations}
        commentDateVisibility={commentDateVisibility}
        shouldConfirmAcknowledgement={shouldConfirmAcknowledgement}
        topicId={topicId}
      />

      {isDeleting && (
        <ConfirmModal
          asyncConfirm
          isOpen
          onConfirm={onConfirmDelete}
          onCancel={onCancelDelete}
          title={
            comment.type === 'driver'
              ? t('comment__delete__confirm__title')
              : comment.type === 'text'
                ? t('input__delete__confirm__title')
                : comment.type === 'value'
                  ? t('value_comment__delete__confirm__title')
                  : undefined
          }
          confirmLabel={t('delete')}
          type="negative"
        >
          {comment.type === 'driver'
            ? t('comment__delete__confirm')
            : comment.type === 'text'
              ? t('input__delete__confirm')
              : comment.type === 'value'
                ? t('value_comment__delete__confirm')
                : undefined}
        </ConfirmModal>
      )}
    </Fragment>
  );
}
