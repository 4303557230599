import moment, { type MomentInput } from 'moment';
import { type ZodErrorMap, ZodIssueCode, ZodParsedType } from 'zod';

import { t } from '../../features/i18next/t';

export const formatDate = (date: MomentInput, format = 'LL') => {
  return moment(date).format(format);
};

export const getErrorMap = (): ZodErrorMap => (issue, _ctx) => {
  let message = '';

  switch (issue.code) {
    case ZodIssueCode.invalid_type:
      if (issue.received === ZodParsedType.undefined) {
        message = t('form_error__required_field');
        break;
      }

      message = t('form_error__invalid_input');
      break;
    case ZodIssueCode.invalid_literal:
      if (issue.received === undefined) {
        message = t('form_error__required_field');
        break;
      }

      message = t('form_error__invalid_input');
      break;
    case ZodIssueCode.invalid_date:
      message = t('form_error__invalid_date');
      break;
    case ZodIssueCode.invalid_string:
      if (issue.validation === 'email') {
        message = t('form_error__invalid_email');
        break;
      }

      if (issue.validation === 'url') {
        message = t('form_error__invalid_url');
        break;
      }

      message = t('form_error__invalid_input');
      break;
    case ZodIssueCode.too_small:
      if (issue.type === 'string') {
        if (issue.exact) {
          message = t('form_error__exact_characters', {
            replace: { exact: Number(issue.minimum) },
          });

          break;
        }

        if (issue.inclusive) {
          message = t('form_error__at_least_min_characters', {
            replace: { minimum: Number(issue.minimum) },
          });
          break;
        }

        if (issue.minimum) {
          message = t('form_error__over_min_characters', {
            replace: { minimum: Number(issue.minimum) },
          });
          break;
        }
      }

      if (issue.type === 'number') {
        if (issue.exact) {
          message = t('form_error__exact_number', {
            replace: { exact: Number(issue.minimum) },
          });
          break;
        }

        if (issue.inclusive) {
          message = t('form_error__greater_than_or_equal_number', {
            replace: { minimum: Number(issue.minimum) },
          });
          break;
        }

        if (issue.minimum) {
          message = t('form_error__greater_than_number', {
            replace: { minimum: Number(issue.minimum) },
          });
          break;
        }
      }

      if (issue.type === 'date') {
        if (issue.exact) {
          message = t('form_error__exact_date', {
            replace: { date: formatDate(Number(issue.minimum)) },
          });
          break;
        }

        if (issue.inclusive) {
          message = t('form_error__later_than_or_equal_date', {
            replace: { date: formatDate(Number(issue.minimum)) },
          });
          break;
        }

        if (issue.minimum) {
          message = t('form_error__later_than_date', {
            replace: { date: formatDate(Number(issue.minimum)) },
          });
          break;
        }
      }

      break;
    case ZodIssueCode.too_big:
      if (issue.type === 'string') {
        if (issue.exact) {
          message = t('form_error__exact_characters', {
            replace: { exact: Number(issue.maximum) },
          });
          break;
        }

        if (issue.inclusive) {
          message = t('form_error__less_than_or_equal_characters', {
            replace: { maximum: Number(issue.maximum) },
          });
          break;
        }

        if (issue.maximum) {
          message = t('form_error__less_than_characters', {
            replace: { maximum: Number(issue.maximum) },
          });
          break;
        }
      }

      if (issue.type === 'number') {
        if (issue.exact) {
          message = t('form_error__exact_number', {
            replace: { exact: Number(issue.maximum) },
          });
          break;
        }

        if (issue.inclusive) {
          message = t('form_error__less_than_or_equal_number', {
            replace: { maximum: Number(issue.maximum) },
          });
          break;
        }

        if (issue.maximum) {
          message = t('form_error__less_than_number', {
            replace: { minimum: Number(issue.maximum) },
          });
          break;
        }
      }

      if (issue.type === 'date') {
        if (issue.exact) {
          message = t('form_error__exact_date', {
            replace: { date: formatDate(Number(issue.maximum)) },
          });
          break;
        }

        if (issue.inclusive) {
          message = t('form_error__earlier_than_or_equal_date', {
            replace: { date: formatDate(Number(issue.maximum)) },
          });
          break;
        }

        if (issue.maximum) {
          message = t('form_error__earlier_than_date', {
            replace: { date: formatDate(Number(issue.maximum)) },
          });
          break;
        }
      }

      break;
    default:
      message = t('form_error__invalid_input');
  }

  return { message };
};
