import { useQuery } from '@tanstack/react-query';

import api from '@peakon/shared/utils/api';
import hasRight from '@peakon/shared/utils/hasRight';
import jsonapiparser from '@peakon/shared/utils/jsonapiparser';
import { validateData } from '@peakon/shared/utils/validateData/validateData';
import { useShellContext } from '@peakon/shell';
import { type DashboardSession } from '@peakon/shell/types/Session';

import { OpenResponsesSchema } from './schemas';

const getOpenSurveys = async () => {
  const response = await api.get('/responses/employee/open');
  return response
    ? jsonapiparser(
        validateData(response, OpenResponsesSchema, {
          errorMessagePrefix: 'OpenResponsesSchema',
        }),
      )
    : null;
};

export const useGetOpenSurveys = () => {
  const { session } = useShellContext<DashboardSession>();
  const hasRightsToQuerySurveys = hasRight(
    session?.data?.attributes.rights ?? [],
    'response:read',
  );
  const isOpenSurveyQueryExecutable = hasRightsToQuerySurveys;

  const result = useQuery({
    queryKey: ['open_surveys'],
    queryFn: getOpenSurveys,
    select: ({ data }) => data,
    enabled: isOpenSurveyQueryExecutable,
  });

  return {
    isOpenSurveyQueryExecutable,
    ...result,
  };
};
