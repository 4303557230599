import React from 'react';

import { type SubmitErrorHandler, type SubmitHandler } from 'react-hook-form';
import type z from 'zod';

import { Form } from './Form';
import { useZodForm, type UseZodFormProps } from './useZodForm';
import { ZodFormProvider } from './ZodFormProvider';

export type FormProps<TSchema extends z.Schema> = UseZodFormProps<TSchema> & {
  onSubmit: SubmitHandler<z.infer<TSchema>>;
  onError?: SubmitErrorHandler<z.infer<TSchema>>;
  children: React.ReactNode;
  nativeFormProps?: Omit<
    React.ComponentPropsWithRef<'form'>,
    'onError' | 'onSubmit'
  >;
};

export function ZodForm<TSchema extends z.Schema>({
  children,
  onSubmit,
  onError,
  nativeFormProps,
  schema,
  ...useFormProps
}: FormProps<TSchema>) {
  const methods = useZodForm({
    shouldFocusError: true,
    ...useFormProps,
    schema,
  });

  return (
    <ZodFormProvider schema={schema} {...methods}>
      <Form onSubmit={onSubmit} onError={onError} {...nativeFormProps}>
        {children}
      </Form>
    </ZodFormProvider>
  );
}
