import { fromJS, Record } from 'immutable';
import { z } from 'zod';

import { type KioskEmployeeExportData } from '@peakon/shared/schemas/api/employeeKioskExport';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import Schedule from './ScheduleRecord';
import { type Override } from './types/Override';
import { validateTestingSchema } from './utils';

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  count: z.number().optional(),
  schedule: z.object({}).passthrough().optional(),
});
type Schema = Override<
  z.infer<typeof testingSchema>,
  {
    schedule?: Schedule;
  }
>;

// eslint-disable-next-line import/no-default-export
export default class EmployeeKioskScheduleRecord extends Record<Schema>({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  id: undefined!,
  count: undefined,
  schedule: undefined,
}) {
  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'EmployeeKioskScheduleRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'EmployeeKioskScheduleRecord',
      log: [{ environment: 'local', logLevel: 'error' }],
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  static createFromApi(kioskSchedule: KioskEmployeeExportData) {
    const {
      id,
      attributes: { count },
      relationships: { schedule },
    } = kioskSchedule;

    return new EmployeeKioskScheduleRecord(
      fromJS({
        id,
        count,
        schedule: Schedule.createFromApi(schedule),
      }),
    );
  }
}
