import { fromJS, List, type Map, Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import Context from './ContextRecord';
import Segment from './SegmentRecord';
import type Translation from './TranslationRecord';

const attributesSchema = z.object({
  contextBranded: z.boolean().nullable(),
  engageLogo: z.string().nullable(),
  engageName: z.string().nullable(),
  engageNameTranslations: z.record(z.string(), z.string()).nullable(),
  rights: z.array(z.enum(['view', 'edit', 'delete'])),
});
type Attributes = Omit<
  z.infer<typeof attributesSchema>,
  'engageNameTranslations' | 'rights'
> & {
  engageNameTranslations?: Map<string, Translation>;
  rights?: List<'view' | 'edit' | 'delete'>;
};
const schema = z.object({
  id: z.string(),
  attributes: attributesSchema.optional(),
  context: z.object({}).passthrough().optional(),
  excludedSegments: z.array(z.object({}).passthrough()),
  includedSegments: z.array(z.object({}).passthrough()),
});
type Schema = Omit<
  z.infer<typeof schema>,
  'context' | 'excludedSegments' | 'includedSegments'
> & {
  context?: Context;
  excludedSegments: List<Segment>;
  includedSegments: List<Segment>;
};

// eslint-disable-next-line import/no-default-export
export default class BrandingRecord
  extends Record({
    id: undefined,
    contextBranded: undefined,
    engageName: undefined,
    engageNameTranslations: undefined,
    engageLogo: undefined,
    rights: [],
    context: undefined,
    excludedSegments: List(),
    includedSegments: List(),
  })
  implements Schema
{
  id!: Schema['id'];
  contextBranded?: Attributes['contextBranded'];
  engageName?: Attributes['engageName'];
  engageNameTranslations?: Attributes['engageNameTranslations'];
  engageLogo?: Attributes['engageLogo'];
  rights?: Attributes['rights'];
  context?: Schema['context'];
  excludedSegments!: Schema['excludedSegments'];
  includedSegments!: Schema['includedSegments'];

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'BrandingRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get dashboardContext() {
    return this.context;
  }

  hasIncludedOrExcludedSegments() {
    return !this.excludedSegments.isEmpty() || !this.includedSegments.isEmpty();
  }

  // createFromApi truly can accept a whole range of values. It's not really possible to type this function accurately.
  // And that's okay! We will be getting rid of this function soon anyway.
  // ! But please BE MINDFUL of changing this code. It's worked for years without throwing errors and we want to keep it that way until Records are obliterated.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static createFromApi(branding: any) {
    const {
      id,
      attributes,
      relationships: { context, excludedSegments, includedSegments } = {},
    } = branding;

    return new BrandingRecord(
      fromJS({
        id,
        ...attributes,
        context: context && Context.createFromApi(context),
        excludedSegments: excludedSegments
          ? List(excludedSegments.map(Segment.createFromApi))
          : List(),
        includedSegments: includedSegments
          ? List(includedSegments.map(Segment.createFromApi))
          : List(),
      }),
    );
  }
}
