import { List } from 'immutable';

import { ObservationRecord } from '@peakon/records/externalMetrics/ObservationRecord';
import { type ExternalMetricObservation } from '@peakon/shared/schemas/api/externalMetricObservations';

import createPagination from '../utils/createPagination';

type Action =
  | {
      type:
        | 'EXTERNAL_METRICS_GET_OBSERVATIONS_SUCCESS'
        | 'EXTERNAL_METRICS_APPEND_OBSERVATIONS_SUCCESS';
      data: { id: string; data: ExternalMetricObservation[] };
    }
  | {
      type:
        | 'EXTERNAL_METRICS_CREATE_OBSERVATION_SUCCESS'
        | 'EXTERNAL_METRICS_UPDATE_OBSERVATION_SUCCESS'
        | 'EXTERNAL_METRICS_DELETE_OBSERVATION_SUCCESS';
      data: {
        id: string;
        data: ExternalMetricObservation;
      };
    };

const observations = (
  state = List<ObservationRecord>(),
  action: Action,
): List<ObservationRecord> => {
  switch (action.type) {
    case 'EXTERNAL_METRICS_GET_OBSERVATIONS_SUCCESS': {
      const { data: segments } = action.data;

      return List(segments.map(ObservationRecord.createFromApi));
    }

    case 'EXTERNAL_METRICS_APPEND_OBSERVATIONS_SUCCESS': {
      const { data: segments } = action.data;

      return state.concat(List(segments.map(ObservationRecord.createFromApi)));
    }

    case 'EXTERNAL_METRICS_CREATE_OBSERVATION_SUCCESS': {
      const { data } = action.data;

      return state.push(ObservationRecord.createFromApi(data));
    }

    case 'EXTERNAL_METRICS_UPDATE_OBSERVATION_SUCCESS': {
      const { id, data } = action.data;

      const index = state.findIndex((observation) =>
        Boolean(observation && observation.id === id),
      );

      if (index === -1) {
        return state;
      }

      return state.update(index, () => ObservationRecord.createFromApi(data));
    }

    case 'EXTERNAL_METRICS_DELETE_OBSERVATION_SUCCESS': {
      const { id } = action.data;

      return state.filter((observation) =>
        Boolean(observation && observation.id !== id),
      );
    }

    default:
      return state;
  }
};

// @ts-expect-error Argument of type '(state: List<ObservationRecord> | undefined, action: Action)
// eslint-disable-next-line import/no-default-export
export default createPagination(observations, {
  startTypes: [
    'EXTERNAL_METRICS_GET_OBSERVATIONS',
    'EXTERNAL_METRICS_DELETE_ALL_OBSERVATIONS',
  ],
  appendTypes: ['EXTERNAL_METRICS_APPEND_OBSERVATIONS'],
  clearTypes: ['EXTERNAL_METRICS_DELETE_ALL_OBSERVATIONS_SUCCESS'],
  createTypes: ['EXTERNAL_METRICS_CREATE_OBSERVATION'],
  removeTypes: ['EXTERNAL_METRICS_DELETE_OBSERVATION'],
  resetTypes: ['EXTERNAL_METRICS_FLUSH_OBSERVATIONS'],
  defaultSkip: false,
});
