import React from 'react';

import { Modal } from '@peakon/components';

import ModalContent from './ModalContent';
import ModalFooter from './ModalFooter';
import ModalHeaderButton from './ModalHeaderButton';
import { type ProductTourStepComponentProps } from '../ProductTourStepRenderer';
import { type ProductTourCustomModal, type ProductTourModal } from '../types';

import styles from './styles.css';

type Props = Pick<
  ProductTourStepComponentProps<ProductTourModal>,
  | 'currentStep'
  | 'onClose'
  | 'onStepClick'
  | 'step'
  | 'totalSteps'
  | 'isLast'
  | 'isFirst'
>;

const ProductTourStepModal = (props: Props) => {
  const {
    currentStep,
    onClose,
    onStepClick,
    step,
    totalSteps,
    isLast,
    isFirst,
  } = props;

  return (
    <React.Fragment>
      <Modal
        onClose={onClose}
        isOpen
        modalClassName={styles.modal}
        testId="product-tour"
      >
        <Modal.Header className={styles.header}>
          <div className={styles.headerActions}>
            <ModalHeaderButton
              step={step}
              onClose={onClose}
              onStepClick={onStepClick}
              isLast={isLast}
              isFirst={isFirst}
            />
          </div>
        </Modal.Header>
        <Modal.Content className={styles.content}>
          <ModalContent step={step} />
          {step.content?.(
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            props as ProductTourStepComponentProps<ProductTourCustomModal>,
          )}
        </Modal.Content>
        <ModalFooter
          step={step}
          currentStep={currentStep}
          totalSteps={totalSteps}
          onClose={onClose}
          onStepClick={onStepClick}
          isFirst={isFirst}
          isLast={isLast}
        />
      </Modal>
    </React.Fragment>
  );
};

// eslint-disable-next-line import/no-default-export
export default ProductTourStepModal;
