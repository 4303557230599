import React from 'react';

import { get, useFormContext } from 'react-hook-form';
import { z } from 'zod';

import { VisuallyHidden } from '@peakon/bedrock/react/visually-hidden';

import { t } from '../../../features/i18next/t';
import { validateData } from '../../../utils/validateData/validateData';
import { TextArea } from '../components/fields/TextArea';
import { type ZodSchemaOrRecord } from '../utils/types';

type TextAreaProps<TSchema extends ZodSchemaOrRecord> =
  React.ComponentPropsWithoutRef<typeof TextArea<TSchema>>;

type CharacterLimitedTextAreaProps<TSchema extends ZodSchemaOrRecord> = Omit<
  TextAreaProps<TSchema>,
  'maxLength'
> & {
  maxLength: number;
};

const inputSchema = z.string().nullish();

/**
 * Wrapped form `TextArea` that displays the number of characters remaining
 * in its description.
 */
export function CharacterLimitedTextArea<
  TSchema extends ZodSchemaOrRecord = z.ZodRawShape,
>({
  name,
  maxLength,
  feedbackMessage,
  ...textAreaProps
}: CharacterLimitedTextAreaProps<TSchema>) {
  const {
    watch,
    formState: { errors },
  } = useFormContext();
  const inputText = watch(name);

  // Ensure that the input text is a string.
  // If it's not, the most likely reason is that the
  // incorrect schema path (`name`) has been passed.
  const validatedInputText = validateData(inputText, inputSchema, {
    errorMessagePrefix: 'inputSchema',
  });

  const remainingCharacters = maxLength - (validatedInputText?.length ?? 0);
  const charactersLeftText = t('character_limit_chars_left_and_max_chars', {
    replace: {
      charsLeft: remainingCharacters,
      maximumChars: maxLength,
    },
  });

  const errorMessage = get(errors, name)?.message;

  return (
    <React.Fragment>
      <TextArea<TSchema>
        name={name}
        maxLength={maxLength}
        // The characters left message has the lowest priority, so it should
        // only be shown if there aren't any errors and `feedbackMessage`
        // hasn't been overridden
        feedbackMessage={feedbackMessage ?? errorMessage ?? charactersLeftText}
        {...textAreaProps}
      />
      <VisuallyHidden aria-live="polite">
        {/* We want screen readers to only announce this when it's
         * relevant (i.e. when there's <= 10 characters left) */}
        {remainingCharacters <= 10 ? charactersLeftText : ''}
      </VisuallyHidden>
    </React.Fragment>
  );
}
