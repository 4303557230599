import React, { Component } from 'react';

import { bindActionCreators } from '@reduxjs/toolkit';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { components } from 'react-select';

import { AccessLockIcon as LockIcon } from '@peakon/bedrock/icons/system';
import { Select } from '@peakon/components';
import { type Attribute } from '@peakon/records';
import { t } from '@peakon/shared/features/i18next/t';

import {
  list as listAction,
  getAttributeOptions as getAttributeOptionsAction,
} from '../../actions/AttributeActions';
import { type RootState, type Dispatch } from '../../types/redux';
import { getSelectOptionProps } from '../../utils/getSelectOptionProps';

import styles from './styles.css';

const isRestricted = (option: $TSFixMe) =>
  [option.comparisonAccess, option.employeeAccess].includes('restricted');

type OwnAttributesSelectorProps = {
  autoLoad?: boolean;
  placeholder?: string;
  excludeEmployeeType?: boolean;
  attribute?: Attribute;
} & (
  | {
      withAttributeOption: true;
      onSelect: (
        value:
          | { attribute: { label: string; attributeId: string } }
          | {
              attributeId: unknown;
              attributeOptionId: unknown;
            },
      ) => void;
    }
  | {
      withAttributeOption?: false;
      onSelect: (value: {
        attribute: { label: string; attributeId: string };
      }) => void;
    }
);

type AttributesSelectorState = {
  hasLoaded: boolean;
  hasPages: boolean;
  isLoading: boolean;
  isLoadingOptions: boolean;
  options: Array<unknown>;
  value: $TSFixMe;
  selectedOption?: unknown;
};

type AttributesSelectorProps = OwnAttributesSelectorProps &
  // eslint-disable-next-line no-use-before-define
  ReturnType<typeof mapStateToProps> &
  // eslint-disable-next-line no-use-before-define
  ReturnType<typeof mapDispatchToProps>;

export class AttributesSelector extends Component<
  AttributesSelectorProps,
  AttributesSelectorState
> {
  static defaultProps: Partial<AttributesSelectorProps> = {
    autoLoad: true,
    withAttributeOption: true,
  };

  onOptionMenuOpened: boolean;

  constructor(props: AttributesSelectorProps) {
    super(props);

    this.state = {
      hasLoaded: false,
      hasPages: true,
      isLoading: false,
      isLoadingOptions: false,
      options: [],
      value: null,
    };

    this.onOptionMenuOpened = false;
    this.onInputChange = debounce(this.onInputChange, 300);
  }

  static getDerivedStateFromProps(nextProps: AttributesSelectorProps) {
    const { attribute } = nextProps;

    if (attribute) {
      return {
        value: {
          id: attribute.id,
          attributeID: attribute.id,
          label: attribute.name,
        },
      };
    }

    return null;
  }

  componentDidMount() {
    const { autoLoad } = this.props;

    if (autoLoad) {
      this.loadOptions();
    }
  }

  loadOptions = () => {
    const { attributeActions, excludeEmployeeType } = this.props;

    this.setState({ isLoading: true }, async () => {
      const params = {
        filter: {
          status: 'active',
          type: 'option',
        },
      };

      if (excludeEmployeeType) {
        params.filter.type = '"employee"$ne';
      }

      await attributeActions.list(params);

      this.setState({ isLoading: false, hasLoaded: true });
    });
  };

  getOptions() {
    const { attributes } = this.props;

    return attributes
      .valueSeq()
      .toArray()
      .map((attr) => ({
        value: attr.id,
        attributeId: attr.id,
        label: attr.name,
        comparisonAccess: attr.comparisonAccess,
        employeeAccess: attr.employeeAccess,
      }));
  }

  onChange = (value: $TSFixMe) => {
    const { withAttributeOption, onSelect } = this.props;

    this.setState(
      {
        hasPages: true,
        options: [],
        selectedOption: null,
        value,
      },

      () => {
        if (withAttributeOption) {
          this.onOptionMenuOpened = false;
          this.onMenuOpen();
        } else {
          onSelect({
            attribute: value,
          });
        }
      },
    );
  };

  onOptionChange = (option: $TSFixMe) => {
    const { onSelect } = this.props;
    const { value } = this.state;

    this.setState(
      {
        selectedOption: option,
      },

      () =>
        onSelect({
          // @ts-expect-error - Object literal may only specify known properties, but 'attributeId' does not exist in type '{ attribute: { label: string; attributeId: string; }; }'. Did you mean to write 'attribute'?
          attributeId: value.attributeId,
          attributeOptionId: option ? option.value : null,
        }),
    );
  };

  onMenuOpen = () => {
    if (!this.onOptionMenuOpened) {
      this.onOptionMenuOpened = true;

      // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
      return this.onInputChange();
    }
  };

  onInputChange = (text: $TSFixMe) => {
    const { attributeActions } = this.props;
    const { hasPages, value } = this.state;

    if (!hasPages) {
      return text;
    }

    this.setState({ isLoadingOptions: true }, async () => {
      const response = await attributeActions.getAttributeOptions(
        value.attributeId,
        text,
      );
      const nextState = {
        isLoadingOptions: false,
        // @ts-expect-error TS(7006): Parameter 'option' implicitly has an 'any' type.
        options: response.data.map((option) => ({
          value: option.id,
          label: option.attributes.name,
          comparisonAccess: option.attributes.comparisonAccess,
          employeeAccess: option.attributes.employeeAccess,
        })),
      };
      // set flag so we don't refetch options if there is only 1 page
      if (!text) {
        // @ts-expect-error Property 'hasPages' does not exist on type '{ isLoadingOptions: boolean; options: any; }'.ts(2339)
        nextState.hasPages = Boolean(response.links.next);
      }
      this.setState(nextState);
    });
  };

  render() {
    const { placeholder, withAttributeOption } = this.props;
    const {
      isLoading,
      isLoadingOptions,
      hasLoaded,
      options,
      selectedOption,
      value,
    } = this.state;

    return (
      <div className={styles.wrapper}>
        <div className={styles.select}>
          <Select
            components={{
              Option: (
                // @ts-expect-error - Parameter 'props' implicitly has an 'any' type.
                props,
              ) => {
                const newProps = getSelectOptionProps(props);

                const { data: option } = props;

                return (
                  <components.Option
                    {...newProps}
                    key={option.id}
                    className={styles.option}
                  >
                    {}
                    {option.label}{' '}
                    {isRestricted(option) && (
                      <LockIcon className={styles.restrictedIcon} />
                    )}
                  </components.Option>
                );
              },
            }}
            onChange={this.onChange}
            isLoading={isLoading}
            onInputChange={!hasLoaded ? this.loadOptions : undefined}
            options={this.getOptions()}
            placeholder={placeholder}
            value={value}
          />
        </div>
        {withAttributeOption && (
          <div className={styles.select}>
            <Select
              isDisabled={!value}
              isLoading={isLoadingOptions}
              loadingMessage={() => t('employee_selector__loading')}
              noOptionsMessage={() => t('employees__search__no_result')}
              getOptionValue={(
                // @ts-expect-error - Parameter 'option' implicitly has an 'any' type.
                option,
              ) => option.label}
              onInputChange={this.onInputChange}
              onMenuOpen={this.onMenuOpen}
              onChange={this.onOptionChange}
              options={options}
              value={selectedOption}
              placeholder={
                value
                  ? t('employee__bulk_edit__select-field', {
                      replace: { name: value.label },
                    })
                  : undefined
              }
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ attributes }: RootState) => {
  return {
    attributes,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  attributeActions: bindActionCreators(
    {
      list: listAction,
      getAttributeOptions: getAttributeOptionsAction,
    },
    dispatch,
  ),
});

// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, mapDispatchToProps)(AttributesSelector);
