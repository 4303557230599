import React from 'react';

import classnames from 'classnames';

import { Menu } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';

import { EmployeeAvatar } from '../../components/ProfileSummary';
import { type RawNavigation } from '../../hooks/useNavigation/types';

import styles from '../styles.css';

type Props = {
  navigation: RawNavigation;
  employee?: {
    avatar?: string;
    firstName?: string;
    lastName?: string;
    name?: string;
  };
};

function ProfileDropdown({ navigation, employee }: Props) {
  const profileNavChildren = navigation.profile?.children[0]?.children;

  return (
    <Menu>
      <Menu.Target aria-label={t('multi_product__profile')}>
        <div
          className={classnames(styles.iconLink, {
            [styles.active]: navigation.profile && navigation.profile.isActive,
          })}
          data-test-id="profile-menu-target"
        >
          <EmployeeAvatar employee={employee} small />
        </div>
      </Menu.Target>
      <Menu.Popover>
        {navigation.profile &&
          profileNavChildren &&
          profileNavChildren[0]?.getPath && (
            <Menu.ItemLink href={profileNavChildren[0].getPath()}>
              {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
              {navigation.profile.children[0].menuLabel}
            </Menu.ItemLink>
          )}
        <Menu.ItemLink href="/login/logout">
          {t('common__logout')}
        </Menu.ItemLink>
      </Menu.Popover>
    </Menu>
  );
}

// eslint-disable-next-line import/no-default-export
export default ProfileDropdown;
