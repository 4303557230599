import { useQuery } from '@tanstack/react-query';

import jsonapiparser from '@peakon/jsonapiparser';
import api from '@peakon/shared/utils/api';

import {
  type StandaloneTeamSuggestionsResponse,
  isCategory,
  isContext,
  isEmployee,
  isParentCategory,
  isPriority,
  isTeamSuggestion,
} from './schema';
import { catchHandler } from '../../../actions/NotificationActions';
import { useAppDispatch } from '../../../utils/reduxHooks';

const params = {
  include:
    'source,source.category,source.category.parentCategory,source.context,source.setByEmployee,source.teamSuggestion',
  fields: {
    source: 'object',
    contexts: 'name,logo',
    team_suggestions: 'text',
    priority_drivers:
      'status,statusSetAt,managerMessage,allowTeamSuggestions,category,setByEmployee,context,teamSuggestion',
    categories:
      'nameTranslated,descriptionTranslated,standard,group,parentCategory',
    employees: 'name,avatar',
  },
};

async function authenticateStandalonePage(token: string) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const { data, included } = jsonapiparser(
    await api.post('/auth/priority_driver', params, {
      token,
    }),
  ) as StandaloneTeamSuggestionsResponse;

  const sessionToken = data.id;
  api.auth.authenticate(sessionToken);

  const categories = included.filter(isCategory);

  return {
    category:
      categories.length > 1
        ? categories.find((category) => !isParentCategory(category))
        : categories[0],
    parentCategory:
      categories.length > 1 ? categories.find(isParentCategory) : null,
    priority: included.find(isPriority),
    employee: included.find(isEmployee),
    context: included.find(isContext),
    teamSuggestion: included.find(isTeamSuggestion),
  };
}

type Params = {
  token: string;
};

export function useStandaloneTeamSuggestionsQuery({ token }: Params) {
  const dispatch = useAppDispatch();

  return useQuery({
    retry: false,
    queryKey: standaloneTeamSuggestionsQueryKeys.list(token),
    queryFn: () => authenticateStandalonePage(token),
    onError: (error: { status: number; message: string; id?: string }) => {
      dispatch(catchHandler(error));
    },
  });
}

export const standaloneTeamSuggestionsQueryKeys = {
  all: ['standalone_team_suggestions_page'],
  session: ['standalone_team_suggestions_session'],
  list: (token: string) => [...standaloneTeamSuggestionsQueryKeys.all, token],
};
