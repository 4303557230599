import { createContext } from 'react';

import noop from 'lodash/noop';

import type CommentRecord from '@peakon/records/CommentRecord';
import type RealtimeChannel from '@peakon/shared/utils/realtime/RealtimeChannel';

// eslint-disable-next-line no-restricted-syntax
export enum ConversationKind {
  reply = 'reply',
  note = 'note',
}

export type ConversationContent = CommentRecord;

export type Employee = {
  id: string;
  attributes: {
    avatar?: string;
    firstName: string;
    lastName: string;
    name: string;
  };
};

type DefaultContext = {
  comment: ConversationContent | null;
  kind: ConversationKind;
  isPermalink: boolean;
  onConversationToggle: (
    comment: ConversationContent | null,
    kind?: ConversationKind,
  ) => void;
  onKindChange: (kind: ConversationKind) => void;
  realtimeChannel: RealtimeChannel | null;
};

const DEFAULT_CONTEXT: DefaultContext = {
  comment: null,
  kind: ConversationKind.reply,
  isPermalink: false,
  onConversationToggle: noop,
  onKindChange: noop,
  realtimeChannel: null,
};

export const ConversationsContext = createContext(DEFAULT_CONTEXT);
