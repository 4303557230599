import React, {
  useEffect,
  createContext,
  useContext,
  useCallback,
} from 'react';

import { createPortal } from 'react-dom';
import { z } from 'zod';

import {
  AttributeCleanupTaskNotification,
  isAttributeCleanupImportTask,
} from './Tasks/AttributeCleanup/AttributeCleanup';
import {
  isAttributeStandardMappingTask,
  AttributeStandardMappingTaskNotification,
} from './Tasks/AttributeStandardMapping/AttributeStandardMapping';
import {
  BenchmarkMappingImportTaskNotification,
  isBenchmarkMappingImportTask,
} from './Tasks/BenchmarkMappingImport/BenchmarkMappingImport';
import { isCommentSummaryTask } from './Tasks/CommentSummary/CommentSummary';
import {
  EmployeesDeleteTaskNotification,
  isEmployeesDeleteTask,
} from './Tasks/EmployeesDelete/EmployeesDelete';
import {
  EmployeesImportTaskNotification,
  isEmployeesImportTask,
} from './Tasks/EmployeesImport/EmployeesImport';
import {
  isEmployeesUpdateTask,
  EmployeesUpdateTaskNotification,
} from './Tasks/EmployeesUpdate/EmployeesUpdate';
import { ExportTaskNotification, isExportTask } from './Tasks/Export/Export';
import {
  ExternalMetricsImportHandler,
  isExternalMetricsImportTask,
} from './Tasks/ExternalMetricsImport/ExternalMetricsImport';
import {
  isSegmentManagersImportTask,
  SegmentManagersImportTaskNotification,
} from './Tasks/SegmentManagersImport/SegmentManagersImport';
import {
  type Task,
  type TaskInitializationData,
  type TaskType,
} from './Tasks/types';
import { useBackgroundTaskChannel } from './useBackgroundTaskChannel';
import { useTasks } from './useTasks';
import { useAppSelector } from '../../utils/reduxHooks';

import styles from './styles.css';

type StartBackgroundTask = (
  data: TaskInitializationData & {
    startBackgroundTaskFunction: () => Promise<{ backgroundTaskId: string }>;
  },
) => Promise<void>;

type IsTaskRunning = (taskType: Exclude<TaskType, 'unknown'>) => boolean;

export const BackgroundTaskContext = createContext<null | {
  startBackgroundTask: StartBackgroundTask;
  isTaskRunning: IsTaskRunning;
  getTaskHistory: () => Task[];
  clearTaskHistory: () => void;
}>(null);

export function useBackgroundTaskContext() {
  const context = useContext(BackgroundTaskContext);

  if (!context) {
    throw new Error(
      'useBackgroundTaskContext must be used within a BackgroundTaskProvider',
    );
  }

  return context;
}

const backgroundTaskResponseSchema = z
  .object({
    backgroundTaskId: z.string(),
  })
  .passthrough();

export const BackgroundTaskProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [tasks, { updateTask, removeTask, removeAllTasks, addTask }] =
    useTasks();

  const accountId = useAppSelector((state) => state.session.accountId);

  const onComplete = useCallback(
    (data: unknown) => {
      const validatedResponse = backgroundTaskResponseSchema.parse(data);
      updateTask({
        id: validatedResponse.backgroundTaskId,
        data: {
          ...validatedResponse,
          status: 'completed',
          // hardcode the percentage to 100 since the data coming back from the API does not contain an update for the percentage
          percent: 100,
        },
      });
    },
    [updateTask],
  );

  const onProgress = useCallback(
    (data: unknown) => {
      const validatedResponse = backgroundTaskResponseSchema.parse(data);
      updateTask({
        id: validatedResponse.backgroundTaskId,
        data: { ...validatedResponse, status: 'inProgress' },
      });
    },
    [updateTask],
  );

  const onError = useCallback(
    (data: unknown) => {
      const validatedResponse = backgroundTaskResponseSchema.parse(data);

      updateTask({
        id: validatedResponse.backgroundTaskId,
        data: { ...validatedResponse, status: 'error' },
      });
    },
    [updateTask],
  );

  const {
    initialize: initializeChannel,
    leave: leaveChannel,
    connect: connectRealtimeChannel,
  } = useBackgroundTaskChannel({
    onProgress,
    onComplete,
    onError,
  });

  useEffect(() => {
    initializeChannel(accountId);

    return () => {
      removeAllTasks();
      leaveChannel();
    };
  }, [accountId, initializeChannel, leaveChannel, removeAllTasks]);

  useEffect(() => {
    // disconnect from the channel once all the running tasks's status changes to completed, since we don't need to listen for updates anymore
    if (tasks.length > 0 && tasks.every((t) => t.data.status === 'completed')) {
      leaveChannel();
    }
  }, [leaveChannel, tasks]);

  const startBackgroundTask: StartBackgroundTask = useCallback(
    async ({ type, startBackgroundTaskFunction, ...rest }) => {
      await connectRealtimeChannel();

      const { backgroundTaskId } = backgroundTaskResponseSchema.parse(
        await startBackgroundTaskFunction(),
      );

      addTask({
        id: backgroundTaskId,
        type,
        ...rest,
        data: {
          status: 'inProgress',
          percent: 0,
        },
      });
    },
    [connectRealtimeChannel, addTask],
  );

  const isTaskRunning = useCallback(
    (type: TaskType) => {
      return tasks.some(
        (task) => task.type === type && task.data.status === 'inProgress',
      );
    },
    [tasks],
  );

  return (
    <BackgroundTaskContext.Provider
      value={{
        startBackgroundTask,
        isTaskRunning,
        getTaskHistory: () => tasks,
        clearTaskHistory: removeAllTasks,
      }}
    >
      {createPortal(
        <div className={styles.backgroundTaskNotifications}>
          <div
            id="background-task-region"
            data-test-id="background-task-notification"
            role="region"
            aria-live="assertive"
          >
            {tasks.map((task) => {
              if (isExportTask(task)) {
                return (
                  <ExportTaskNotification
                    key={task.id}
                    task={task}
                    onClose={() => {
                      removeTask(task.id);
                    }}
                  />
                );
              }

              if (isExternalMetricsImportTask(task)) {
                return (
                  <ExternalMetricsImportHandler key={task.id} task={task} />
                );
              }

              if (isEmployeesImportTask(task)) {
                return (
                  <EmployeesImportTaskNotification
                    key={task.id}
                    task={task}
                    onClose={() => {
                      removeTask(task.id);
                    }}
                  />
                );
              }

              if (isAttributeCleanupImportTask(task)) {
                return (
                  <AttributeCleanupTaskNotification
                    key={task.id}
                    task={task}
                    onClose={() => {
                      removeTask(task.id);
                    }}
                  />
                );
              }

              if (isEmployeesDeleteTask(task)) {
                return (
                  <EmployeesDeleteTaskNotification
                    key={task.id}
                    task={task}
                    onClose={() => {
                      removeTask(task.id);
                    }}
                  />
                );
              }

              if (isAttributeStandardMappingTask(task)) {
                return (
                  <AttributeStandardMappingTaskNotification
                    key={task.id}
                    task={task}
                    onClose={() => {
                      removeTask(task.id);
                    }}
                  />
                );
              }

              if (isEmployeesUpdateTask(task)) {
                return (
                  <EmployeesUpdateTaskNotification
                    key={task.id}
                    task={task}
                    onClose={() => {
                      removeTask(task.id);
                    }}
                  />
                );
              }

              if (isSegmentManagersImportTask(task)) {
                return (
                  <SegmentManagersImportTaskNotification
                    key={task.id}
                    task={task}
                    onClose={() => {
                      removeTask(task.id);
                    }}
                  />
                );
              }

              if (isBenchmarkMappingImportTask(task)) {
                return (
                  <BenchmarkMappingImportTaskNotification
                    key={task.id}
                    task={task}
                    onClose={() => {
                      removeTask(task.id);
                    }}
                  />
                );
              }

              if (isCommentSummaryTask(task)) {
                return null;
              }

              if (task.type === 'unknown') {
                return null;
              }

              throw new Error(`Unknown task type: ${task.type}`);
            })}
          </div>
        </div>,
        window.document.body,
      )}
      {children}
    </BackgroundTaskContext.Provider>
  );
};
