import { List } from 'immutable';
import get from 'lodash/get';

import CategoryRecord from '@peakon/records/CategoryRecord';
import QuestionRecord from '@peakon/records/QuestionRecord';
import TopicGroup from '@peakon/records/TopicGroupRecord';

const topicGroups = (
  state = List<TopicGroup>(),
  action: $TSFixMe,
): List<TopicGroup> => {
  switch (action.type) {
    case 'TOPIC_GROUP_LIST_SUCCESS': {
      const { data: groups } = action.data;

      return List(groups.map(TopicGroup.createFromApi));
    }
    case 'GROUP_TOPICS_LIST_LOADING': {
      const { force } = action.data;

      if (force) {
        return List();
      }

      return state;
    }
    case 'TOPIC_GET_SUCCESS': {
      const {
        data: { attributes, relationships },
      } = action.data;

      let group;
      if (relationships.category) {
        group = relationships.category.attributes.group;
      }

      return List([
        new TopicGroup({
          id: attributes.group,
          group: attributes.group,
          title:
            group && group === 'text'
              ? relationships.question.attributes.text
              : null,
          category: relationships.category
            ? CategoryRecord.createFromApi(relationships.category)
            : undefined,
        }),
      ]);
    }

    case 'TOPIC_LIST_SUCCESS':
    case 'GROUP_TOPICS_LIST_SUCCESS': {
      let { id, included, meta } = action.data;

      // Why such a hack? So that we have the group meta information
      // in order to be displayed in the header
      let question, category;
      if (included) {
        const questionObj = included.find(
          (obj: $TSFixMe) => obj.type === 'questions',
        );
        if (questionObj) {
          question = QuestionRecord.createFromApi(questionObj);
        }

        const categoryObj = included.find(
          (obj: $TSFixMe) => obj.type === 'categories',
        );
        if (categoryObj) {
          id = categoryObj.id;
          category = CategoryRecord.createFromApi(categoryObj);
        }
      }

      return List([
        new TopicGroup({
          id: `category_${id}`,
          topics: List(),
          title: question
            ? question.text
            : category
              ? category.nameTranslated
              : null,
          count: get(meta, 'page.total'),
          category,
        }),
      ]);
    }

    case 'TOPIC_TRANSLATE_SUCCESS': {
      const { topicId, name, summary } = action.data;

      const groupIndex = state.findIndex((group) =>
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        group.topics.find((topic) => topic.id === topicId),
      );

      if (groupIndex < 0) {
        return state;
      }

      return state.update(groupIndex, (group) =>
        group.update('topics', (topics) =>
          topics.update(
            topics.findIndex((topic) => topic.id === topicId),
            (topic) => topic.translate(name, summary),
          ),
        ),
      );
    }
    case 'TOPIC_UNTRANSLATE': {
      const { topicId } = action.data;

      const groupIndex = state.findIndex((group) =>
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        group.topics.find((topic) => topic.id === topicId),
      );

      if (groupIndex < 0) {
        return state;
      }

      return state.updateIn([groupIndex, 'topics'], (topics) =>
        topics.update(
          // @ts-expect-error TS(7006): Parameter 'topic' implicitly has an 'any' type.
          topics.findIndex((topic) => topic.id === topicId),
          (topic: $TSFixMe) => topic.set('translated', false),
        ),
      );
    }
    case 'TOPIC_RETRANSLATE': {
      const { topicId } = action.data;

      const groupIndex = state.findIndex((group) =>
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        group.topics.find((topic) => topic.id === topicId),
      );

      if (groupIndex < 0) {
        return state;
      }

      return state.updateIn([groupIndex, 'topics'], (topics) =>
        topics.update(
          // @ts-expect-error TS(7006): Parameter 'topic' implicitly has an 'any' type.
          topics.findIndex((topic) => topic.id === topicId),
          (topic: $TSFixMe) => topic.set('translated', true),
        ),
      );
    }
    case 'TOPIC_BLACKLIST_COMMIT_SUCCESS': {
      const { topic } = action.data;

      // Find all groups that cointain such topic
      const groups = state.filter((group) =>
        group.topics.map((topicInGroup) => topicInGroup.topic).includes(topic),
      );

      // Find the index of those groups
      const groupIndexes = groups.map((group) =>
        state.findIndex((g) => g.id === group.id),
      );

      // Remove topic from them
      groupIndexes.forEach((groupIndex) => {
        // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
        // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
        state = state.update(groupIndex, (group) => {
          const remainingTopics = group.topics.filter(
            (groupTopic: $TSFixMe) => groupTopic.topic !== topic,
          );

          return group
            .set('topics', remainingTopics)
            .set('count', remainingTopics.size);
        });
      });

      return state.filter((group) => group.count !== 0);
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default topicGroups;
