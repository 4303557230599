import {
  type QueryKey,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { type IntegrationID } from '@peakon/peakon-js';

import { INTEGRATION_FACTORY } from '../../../../../data/integrations';

export const useConnectIntegrationMutation = (id: IntegrationID) => {
  const queryClient = useQueryClient();
  const queryKey: QueryKey = ['integration', id];

  return useMutation({
    // `void` - see https://github.com/TanStack/query/issues/1077#issuecomment-1698583282
    mutationFn: (payload?: Record<string, $TSFixMe> | void) =>
      INTEGRATION_FACTORY[id].connect(payload),
    onSuccess: () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disabled here to enable the rule globally
      queryClient.invalidateQueries({ queryKey });
    },
  });
};
