import React from 'react';

import { connect } from 'react-redux';

import hasRight from '@peakon/shared/utils/hasRight';
import { type Right } from '@peakon/shared/utils/rights/types';

import { currentContext } from '../../selectors/ContextSelectors';
import { type RootState } from '../../types/redux';
import Maybe from '../Maybe';

type BaseProps = {
  children?: React.ReactNode;
  elseRender?: React.ReactNode;
  dispatch?: $TSFixMeFunction;
  rights?: Right[];
  useContext?: boolean;
};

type RightsFilterProps = BaseProps &
  ReturnType<
    // eslint-disable-next-line no-use-before-define
    typeof mapStateToProps
  >;

export const RightsFilter = ({
  hasRight: hasRightProp,
  children,
  elseRender,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  rights,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dispatch,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useContext,
  ...props
}: RightsFilterProps) => (
  // @ts-expect-error TS(2322): Type 'ReactNode' is not assignable to type 'ReactN... Remove this comment to see the full error message
  <Maybe test={hasRightProp} elseRender={elseRender}>
    {/* @ts-expect-error TS(2322): Type 'DetailedReactHTMLElement<any, HTMLElement>[]... Remove this comment to see the full error message */}
    {React.Children.map(children, (child) => React.cloneElement(child, props))}
  </Maybe>
);

const mapStateToProps = (state: RootState, props: BaseProps) => {
  const { session } = state;
  const { rights: requiredRights, useContext } = props;

  const dashboardContext = currentContext(state);

  const rights = useContext
    ? dashboardContext && dashboardContext.rights?.toArray()
    : session && session.rights.toArray();

  return {
    hasRight: hasRight(rights, requiredRights),
  };
};

// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps)(RightsFilter);
