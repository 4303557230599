import { type TranslatedString } from '../features/i18next/t';

export const engagementDrivers = [
  'engagement',
  'autonomy',
  'growth',
  'recognition',
  'meaningful_work',
  'accomplishment',
  'goal_setting',
  'organisational_fit',
  'management_support',
  'reward',
  'strategy',
  'peer_relationship',
  'free_opinions',
  'workload',
  'environment',
  'intent_to_stay',
] as const;

export type EngagementDriversType = (typeof engagementDrivers)[number];

const _engagementSubdrivers = [
  'loyalty',
  'belief',
  'satisfaction',
  'how',
  'when',
  'where',
  'opportunity',
  'learning',
  'career_development',
  'mentoring',
  'career_path',
  'recognition',
  'performance',
  'meaningful_work',
  'job_significance',
  'job_fit',
  'accomplishment',
  'challenging',
  'known_expectations',
  'alignment',
  'values',
  'support',
  'equality',
  'health',
  'response',
  'friendly',
  'openness',
  'procedural_fairness',
  'expectancy_theory',
  'distributive_fairness',
  'interactional_fairness',
  'communication',
  'mission',
  'strategy',
  'efficacy',
  'impact',
  'team',
  'manager',
  'burnout',
  'focus',
  'collaboration',
  'informal',
  'equipment',
] as const;

export type EngagementSubdriversType = (typeof _engagementSubdrivers)[number];

export type Driver = {
  id: EngagementDriversType | 'null';
  category: {
    id: string;
    nameTranslated: TranslatedString;
  };
  classification: 'string';
};
