import React, { useState } from 'react';

import { List } from 'immutable';

import { AccessVisibilityCommentIcon as CommentManagersIcon } from '@peakon/bedrock/icons/system';
import { UnstyledButton } from '@peakon/bedrock/react/button';
import { type Employee } from '@peakon/records/index';
import { t } from '@peakon/shared/features/i18next/t';
import { type PlainObject } from '@peakon/shared/utils/typescript/typeHelpers';

import { ManagerSlideIn } from './ManagerSlideIn/ManagerSlideIn';
import { useFeature } from '../../../hooks/useFeature';
import EmployeeSlideIn from '../../EmployeeSlideIn';

import styles from './styles.css';

type V1Props = {
  isLoading?: boolean;
  managers?: List<Employee>;
  onLoad: () => void;
};

export const CommentManagers = ({ isLoading, managers, onLoad }: V1Props) => {
  const showNewSlideIn = useFeature('commentManagerListImprovements');

  // TODO: Remove this check once the feature is 100% rolled out
  if (!showNewSlideIn) {
    return (
      <CommentManagersV1
        isLoading={isLoading}
        managers={managers}
        onLoad={onLoad}
      />
    );
  }

  return (
    <CommentManagersV2
      isLoading={isLoading}
      managers={managers?.toJS()}
      onLoad={onLoad}
    />
  );
};

// TODO: Delete this component once `commentManagerListImprovements` is 100% rolled out
const CommentManagersV1 = ({
  isLoading,
  managers = List(),
  onLoad,
}: V1Props) => {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    onLoad();

    setOpen(true);
  };

  return (
    <div className={styles.root}>
      <UnstyledButton
        accessibleName={t('comment__managers')}
        onClick={handleOpen}
        onMouseEnter={() => {
          if (isLoading) {
            return;
          }

          onLoad();
        }}
      >
        <CommentManagersIcon />
      </UnstyledButton>
      {isOpen && (
        <EmployeeSlideIn
          isOpen={isOpen}
          isLoading={isLoading}
          handleOutsideClick={() => setOpen(false)}
          title={t('comment__managers')}
          employees={managers}
          totalEmployees={managers?.size}
        />
      )}
    </div>
  );
};

type V2Props = {
  isLoading?: boolean;
  managers?: Array<
    Pick<
      PlainObject<Employee>,
      | 'id'
      | 'firstName'
      | 'lastName'
      | 'name'
      | 'avatar'
      | 'email'
      | 'identifier'
    >
  >;
  onLoad: () => void;
};

const CommentManagersV2 = ({ isLoading, managers, onLoad }: V2Props) => {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    onLoad();
  };

  return (
    <div className={styles.root}>
      <UnstyledButton
        accessibleName={t('comment__managers')}
        onClick={handleOpen}
      >
        <CommentManagersIcon />
      </UnstyledButton>
      <ManagerSlideIn
        isOpen={isOpen}
        isLoading={Boolean(isLoading)}
        onDismiss={() => setOpen(false)}
        managers={managers}
      />
    </div>
  );
};
