import React from 'react';

import { useHistory } from 'react-router-dom';

import { Button } from '@peakon/bedrock/react/button';
import { t } from '@peakon/shared/features/i18next/t';

import ProgressBar from '../ProgressBar/ProgressBar';
import { type ProductTourTooltip } from '../types';

import styles from './styles.css';

type Props = {
  currentStep: number;
  hasSkippedTour: boolean;
  onClose: () => void;
  onStepClick: (step: number) => void;
  step: ProductTourTooltip;
  totalSteps: number;
  isFirst: boolean;
  isLast: boolean;
};

type GetDefaultPrimaryButtonLabelParams = {
  isLast: boolean;
  hasSkippedTour: boolean;
};

const getDefaultPrimaryButtonLabel = (
  params: GetDefaultPrimaryButtonLabelParams,
) => {
  const { isLast, hasSkippedTour } = params;

  if (hasSkippedTour) {
    return t('common__close');
  }

  return isLast ? t('onboarding__modal_complete') : t('onboarding__modal_next');
};

// eslint-disable-next-line import/no-default-export
export default function TooltipFooter({
  hasSkippedTour,
  onClose,
  currentStep,
  totalSteps,
  step,
  onStepClick,
  isFirst,
  isLast,
}: Props) {
  const history = useHistory();

  if (step.hideFooter) {
    return null;
  }

  const defaultPrimaryButtonLabel = getDefaultPrimaryButtonLabel({
    isLast,
    hasSkippedTour,
  });

  const primaryButtonLabel =
    step.getPrimaryButtonLabel?.() ?? defaultPrimaryButtonLabel;

  if (hasSkippedTour) {
    return (
      <div className={styles.footer} data-test-id="product-tour-tooltip-footer">
        <div className={styles.action}>
          <Button
            onClick={onClose}
            data-test-id="product-tour-close"
            variant="primary"
          >
            {primaryButtonLabel}
          </Button>
        </div>
      </div>
    );
  }

  const onBackClick = () => {
    if (step.historyBack) {
      history.go(-1);
    }

    onStepClick(currentStep - 1);
  };

  const onNextClick = () => {
    if (isLast) {
      return onClose();
    }

    return onStepClick(currentStep + 1);
  };

  return (
    <div className={styles.footer} data-test-id="product-tour-tooltip-footer">
      <div className={styles.progress}>
        <ProgressBar
          currentStep={isLast ? currentStep - 1 : currentStep}
          totalSteps={totalSteps}
        />
      </div>
      <div className={styles.actions}>
        {!isFirst && (
          <Button
            onClick={onBackClick}
            data-test-id="product-tour-back"
            variant="secondary"
          >
            {t('common__back')}
          </Button>
        )}
        <Button
          data-test-id="product-tour-next"
          variant="primary"
          onClick={onNextClick}
        >
          {primaryButtonLabel}
        </Button>
      </div>
    </div>
  );
}
