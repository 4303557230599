import { type Session, isSharedDashboardSession } from '../../../types/Session';

export const isConsultant = ({ session }: { session?: Session | null }) => {
  // We are not able to check `isConsultant` status with shared dashboard session
  if (session && !isSharedDashboardSession(session)) {
    const employee = session.data.relationships?.employee;
    const account = session.data.relationships?.account;
    const isOtherCompany = !employee;
    const isOtherEmployee =
      employee && account && account.id !== employee.relationships?.account.id;

    // FIXME-ProxyView: better way to check for a consultant then relying on scopeGroup
    return (
      isOtherCompany ||
      (session.data.attributes.scopeGroup === 'all' && isOtherEmployee)
    );
  }
  return false;
};
