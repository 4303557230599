import { z } from 'zod';

import { validateData } from '@peakon/shared/utils/validateData/validateData';

import { type Task } from '../types';

const completedDataSchema = z.object({
  status: z.literal('completed'),
  percent: z.number().min(0).max(100),
  commentSummaryId: z.string(),
  backgroundTaskId: z.string(),
  contextId: z.string(),
  type: z.literal('commentSummary'),
});

const errorDataSchema = z.object({
  status: z.literal('error'),
  error: z.string(),
  errorId: z
    .enum([
      'comment_summary_lock_conflict',
      'comment_summary_restricted_email',
      'comment_summary_content_error',
      'comment_summary_invalid_comments',
    ])
    .optional(),
  type: z.literal('commentSummary'),
});

const inProgressDataSchema = z.object({
  status: z.literal('inProgress'),
  percent: z.number().min(0).max(100),
});

const dataSchema = z.union([
  inProgressDataSchema,
  completedDataSchema,
  errorDataSchema,
]);

const typeSchema = z.literal('commentSummary');

const commentSummaryTask = z.object({
  id: z.string(),
  type: typeSchema,
  data: dataSchema,
});

const _completedSummaryTask = z.object({
  id: z.string(),
  type: typeSchema,
  data: completedDataSchema,
});

const _failedSummaryTask = z.object({
  id: z.string(),
  type: typeSchema,
  data: errorDataSchema,
});

export type CommentSummaryTask = z.infer<typeof commentSummaryTask>;
export type CommentSummaryTaskError = z.infer<typeof _failedSummaryTask>;
export type CommentSummaryTaskCompleted = z.infer<typeof _completedSummaryTask>;

export type CommentSummaryTaskInitializationData = Pick<
  CommentSummaryTask,
  'type'
>;

export const isSummaryTaskError = (
  task: CommentSummaryTask,
  // eslint-disable-next-line no-restricted-syntax
): task is CommentSummaryTaskError => {
  return task.data.status === 'error';
};

export const isCompletedSummaryTask = (
  task: CommentSummaryTask,
  // eslint-disable-next-line no-restricted-syntax
): task is CommentSummaryTaskCompleted => {
  return task.data.status === 'completed';
};

export const isCommentSummaryTask = (
  task: Task,
  // eslint-disable-next-line no-restricted-syntax
): task is CommentSummaryTask => {
  if (typeSchema.safeParse(task.type).success) {
    validateData(task, commentSummaryTask, {
      errorMessagePrefix: 'CommentSummaryBackgroundTask',
    });
    return true;
  }
  return false;
};
