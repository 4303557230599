import z from 'zod';

import { accountSchema } from './accounts';
import { segmentManagerSchema } from './segments';

const phoneSchema = z.object({
  id: z.string(),
  type: z.literal('ohones'),
  attributes: z.object({
    number: z.string(),
    primary: z.boolean(),
    type: z.string(),
  }),
});

const employeeAccountSchema = accountSchema.extend({
  attributes: accountSchema.shape.attributes.extend({
    lastSeenAt: z.string().nullable(),
  }),
});

export type EmployeeAccountResponse = z.infer<typeof employeeAccountSchema>;

const relationshipsSchema = z.object({
  account: employeeAccountSchema,
  phones: z.array(phoneSchema).optional(),
});

const EMPLOYMENT_STATUS = ['hired', 'employed', 'left'] as const;
export const EmploymentStatusEnum = z.enum(EMPLOYMENT_STATUS);

const SOURCE = [
  'internal',
  'scim',
  'bamboohr',
  'bob',
  'workday',
  'personio',
] as const;
export const SourceEnum = z.enum(SOURCE);

const EMPLOYEE_TYPES = ['permanent', 'ephemeral', 'partner'] as const;
export const EmployeeTypesEnum = z.enum(EMPLOYEE_TYPES);

export const employeeBaseSchema = z.object({
  id: z.string(),
  type: z.literal('employees'),
  attributes: z.object({
    accessLevels: z.array(z.string()).optional(),
    avatar: z.string().nullable(),
    createdAt: z.string(),
    employmentStart: z.string().nullable(),
    employmentStatus: EmploymentStatusEnum.nullable(),
    external: z.boolean().optional(),
    externalId: z.string().nullable(),
    features: z.array(z.string()),
    firstName: z.string().nullable(),
    identifier: z.string().nullable(),
    invitedToAdministerAt: z.string().nullable(),
    isAnonymized: z.boolean(),
    kioskCode: z.string(),
    lastName: z.string().nullable(),
    name: z.string().nullable(),
    reportCount: z.number().nullable(),
    source: SourceEnum,
    type: EmployeeTypesEnum,
  }),
});

export const employeeBaseSchemaWithRelationships = employeeBaseSchema.extend({
  relationships: relationshipsSchema,
});

const _employeeSchema = employeeBaseSchemaWithRelationships.extend({
  relationships: relationshipsSchema.merge(
    z.object({
      managedSegments: z.array(segmentManagerSchema).optional(),
    }),
  ),
});

export type PhoneResponse = z.infer<typeof phoneSchema>;
export type EmployeeResponse = z.infer<typeof _employeeSchema>;
