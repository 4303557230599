import moment, { type MomentInput } from 'moment';

import yearless from '@peakon/yearless-date';

const formatYearless = (date: MomentInput, format = 'L') => {
  if (!date) {
    return;
  }

  const formatted = yearless.resolve(format);

  return moment(date).format(formatted);
};

// eslint-disable-next-line import/no-default-export
export default formatYearless;
