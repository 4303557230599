import React from 'react';

import { BodyText } from '@peakon/bedrock/react/typography';

import { type ProductTourTooltip } from '../types';

import styles from './styles.css';

type Props = {
  step: ProductTourTooltip;
};

// eslint-disable-next-line import/no-default-export
export default function TooltipContent({ step }: Props) {
  if (!step.getText) {
    return null;
  }

  return (
    <div className={styles.content} data-test-id="product-tour-text">
      {[step.getText()].flat().map((translation, index) => (
        <BodyText key={index}>{translation}</BodyText>
      ))}
    </div>
  );
}
