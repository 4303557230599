import { List } from 'immutable';

import { Metric } from '@peakon/records/externalMetrics';
import { type ExternalMetric } from '@peakon/shared/schemas/api/externalMetrics';

import createPagination from '../utils/createPagination';

type Action =
  | {
      type: 'EXTERNAL_METRICS_LIST_SUCCESS' | 'EXTERNAL_METRICS_APPEND_SUCCESS';
      data: {
        id: string;
        data: ExternalMetric[];
      };
    }
  | {
      type:
        | 'EXTERNAL_METRICS_CREATE_SUCCESS'
        | 'EXTERNAL_METRICS_UPDATE_SUCCESS'
        | 'EXTERNAL_METRICS_DELETE_SUCCESS';
      data: {
        id: string;
        data: ExternalMetric;
      };
    };

const metricsState = (state = List<Metric>(), action: Action): List<Metric> => {
  switch (action.type) {
    case 'EXTERNAL_METRICS_LIST_SUCCESS': {
      const { data: metrics } = action.data;

      return List(metrics.map(Metric.createFromApi));
    }

    case 'EXTERNAL_METRICS_APPEND_SUCCESS': {
      const { data: metrics } = action.data;

      return state.concat(List(metrics.map(Metric.createFromApi)));
    }

    case 'EXTERNAL_METRICS_CREATE_SUCCESS': {
      const { data } = action.data;

      return state.push(Metric.createFromApi(data));
    }

    case 'EXTERNAL_METRICS_UPDATE_SUCCESS': {
      const { id, data } = action.data;

      const index = state.findIndex((metric) =>
        Boolean(metric && metric.id === id),
      );

      if (index === -1) {
        return state;
      }

      return state.update(index, () => Metric.createFromApi(data));
    }

    case 'EXTERNAL_METRICS_DELETE_SUCCESS': {
      const { id } = action.data;

      return state.filter((metric) => Boolean(metric && metric.id !== id));
    }

    default:
      return state;
  }
};

// @ts-expect-error Argument of type '(state: List<MetricRecord> | undefined, action: Action)
// eslint-disable-next-line import/no-default-export
export default createPagination(metricsState, {
  startTypes: ['EXTERNAL_METRICS_LIST'],
  appendTypes: ['EXTERNAL_METRICS_APPEND'],
  createTypes: ['EXTERNAL_METRICS_CREATE'],
  removeTypes: ['EXTERNAL_METRICS_DELETE'],
  resetTypes: ['EXTERNAL_METRICS_FLUSH'],
  defaultSkip: false,
});
