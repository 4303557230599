import store from 'store2';

import { type ScoreMode } from '@peakon/shared/types/ScoreMode';

type State = {
  test: boolean;
  scoreMode?: ScoreMode;
  closesAt?: $TSFixMe;
};

const initialState: State = {
  test: false,
  closesAt: undefined,
  scoreMode: undefined,
};

const settings = (state = initialState, action: $TSFixMe): State => {
  switch (action.type) {
    case 'NPS_MODE_CHANGE': {
      return {
        ...state,
        scoreMode: action.data,
      };
    }
    case 'SESSION_RESTORED':
    case 'SESSION_READ_SUCCESS': {
      const scoreMode = store.session.get('peakon.ui.scoreMode');
      const test = store.session.get('peakon.ui.test.enabled') || false;
      const closesAt = store.session.get('peakon.ui.closesAt');

      return {
        ...state,
        scoreMode,
        test,
        closesAt,
      };
    }
    case 'DATA_TEST_MODE': {
      return {
        ...state,
        closesAt: null,
        test: action.data,
      };
    }
    case 'SETTINGS_SET_CLOSES_AT': {
      return {
        ...state,
        closesAt: action.data,
      };
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default settings;
