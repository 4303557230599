import { List, Map, Set, Record, fromJS, type StaticMap } from 'immutable';
import pickBy from 'lodash/pickBy';
import { z } from 'zod';

import { t } from '@peakon/shared/features/i18next/t';
import {
  type AccessGroupResponseData,
  type AccessGroupAttributes,
  type SpecialistAccessGroupAttributes,
} from '@peakon/shared/schemas/api/accessGroups';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { AttributeOption } from './AttributeRecord';
import {
  ALL_QUESTION_SETS,
  type CategoryGroup,
} from './constants/questionSets';
import Segment from './SegmentRecord';
import { type AccessSettingGroupKey } from './settings';
import { type Override } from './types/Override';

function isSpecialistAccessGroup(
  groupAttributes: AccessGroupAttributes | SpecialistAccessGroupAttributes,
  // eslint-disable-next-line no-restricted-syntax
): groupAttributes is SpecialistAccessGroupAttributes {
  return (
    'categoryIds' in groupAttributes &&
    groupAttributes.categoryIds !== undefined
  );
}

// Data requirements for AccessGroup
export const REQUIRED_FIELDS = {
  fields: {
    groups: [
      'attribute',
      'attributeOption',
      'excludedSegments',
      'includedSegments',
      'level',
      'memberCount',
      'memberType',
      'name',
      'settings',
      'standard',
      'status',
      'categoryIds',
      'categoryGroupSettings',
      'categoryIds',
      'specialist',
      'sort',
    ].join(','),
  },

  include: [
    'attribute',
    'attributeOption',
    'includedSegments',
    'excludedSegments',
    'includedSegments.attribute',
    'excludedSegments.attribute',
  ].join(','),
};

// https://github.com/peakon/api/blob/master/models/common/group_model.js#L398
export const ACCESS_GROUP_STANDARDS = [
  'admin',
  'manager',
  'employee',
  'hr',
  'leadership',
  'partners',
] as const;

// https://github.com/peakon/api/blob/master/models/common/group_model.js#L385
export const ACCESS_GROUP_MEMBER_TYPES = [
  'all',
  'manager',
  'member',
  'attribute',
  'partner',
] as const;
export type AccessGroupMemberType = (typeof ACCESS_GROUP_MEMBER_TYPES)[number];

// https://github.com/peakon/api/blob/master/models/common/group_model.js#L392
export const ACCESS_GROUP_LEVELS = [
  'all',
  'manager',
  'overall',
  'context',
] as const;
export type AccessGroupLevel = (typeof ACCESS_GROUP_LEVELS)[number];

/*
  FIXME / Note:

  Some of the properties here shouldn't really be optionals, like id, name and status for example.
  Using this record to create new access control groups in the access control admin page is preventing us from doing that.
  We should try to use a separate record there.
*/
const accessGroupSchema = z.object({
  id: z.string().optional(),
  attributeId: z.string().nullable().optional(),
  attributeOption: z.object({}).nullable().optional(),
  attributeOptionId: z.string().nullable().optional(),
  categoryGroupSettings: z
    .record(z.enum(ALL_QUESTION_SETS), z.boolean())
    .nullable()
    .optional(),
  categoryIds: z.array(z.string()).nullable().optional(),
  excludedSegments: z.array(z.object({})).nullable().optional(),
  includedSegments: z.array(z.object({})).nullable().optional(),
  level: z.enum(ACCESS_GROUP_LEVELS).optional(),
  memberCount: z.number().optional(),
  memberType: z.enum(ACCESS_GROUP_MEMBER_TYPES).optional(),
  name: z.string().optional(),
  settings: z.record(z.string(), z.boolean()).optional(),
  sort: z.number().optional(),
  specialist: z.boolean().optional(),
  standard: z.enum(ACCESS_GROUP_STANDARDS).nullable().optional(),
  status: z.enum(['enabled', 'disabled']).optional(),
});

type AccessGroupSchema = z.infer<typeof accessGroupSchema>;

type AccessGroupType = Override<
  AccessGroupSchema,
  {
    attributeOption?: AttributeOption;
    categoryGroupSettings: StaticMap<Record<CategoryGroup, boolean>>;
    categoryIds: Set<string>;
    excludedSegments?: List<Segment>;
    includedSegments?: List<Segment>;
    settings: StaticMap<Record<AccessSettingGroupKey, boolean>>;
  }
>;

class AccessGroup extends Record<AccessGroupType>({
  id: undefined,
  name: undefined,
  standard: undefined,
  memberType: undefined,
  level: undefined,
  specialist: false,
  memberCount: 0,
  status: undefined,
  settings: Map<Record<AccessSettingGroupKey, boolean>>(),
  categoryGroupSettings: Map<Record<CategoryGroup, boolean>>(),
  categoryIds: Set(),

  attributeId: undefined,
  attributeOptionId: undefined,
  attributeOption: undefined,

  includedSegments: undefined,
  excludedSegments: undefined,

  sort: undefined,
}) {
  constructor(props: unknown = {}) {
    validateRecord(props, accessGroupSchema, {
      errorMessagePrefix: 'AccessGroup',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get url() {
    return this.standard || this.id;
  }

  get path() {
    return `/admin/access/${this.specialist ? 'specialist' : 'groups'}/${
      this.url
    }`;
  }

  get levelTitle() {
    const titleDirectory = {
      manager: t('groups__level-picker__manager__title'),
      all: t('groups__level-picker__all__title'),
      overall: t('groups__level-picker__overall__title'),
      context: t('groups__level-picker__context__title'),
    };

    return this.level ? titleDirectory[this.level] : '';
  }

  canRemoveMembers() {
    return this.memberType === 'member';
  }

  isEnabled() {
    return this.status === 'enabled';
  }

  canNotify() {
    const notifiableSettings = [
      'attributeAdmin',
      'questionAdmin',
      'scheduleAdmin',
      'dataset',
      'readEmployees',
    ] as const;

    return (
      this.isEnabled() &&
      notifiableSettings.some((setting) => this.settings.get(setting))
    );
  }

  /**
   * Checks if the current user can notify a given member from this group.
   * @param employeeId  The authSession employee id.
   * @param memberId    The employee id of the member to be notified.
   */
  canNotifyMember(employeeId: string, memberId: string) {
    return (
      this.canNotify() &&
      this.standard !== 'employee' &&
      employeeId !== memberId
    );
  }

  /**
   * Checks if the current user can remove a given member from this group.
   * @param employeeId  The authSession employee id.
   * @param memberId    The employee id of the member to be removed.
   */
  canRemoveMember(employeeId: string, memberId: string) {
    return (
      this.canRemoveMembers() &&
      (this.standard !== 'admin' || employeeId !== memberId)
    );
  }

  toJsonApi({
    type = 'PATCH',
    hasAccessByQuestionSet = false,
    hasAccessByCategory = false,
  } = {}) {
    const {
      name,
      level,
      memberType,
      attributeId,
      attributeOptionId,
      specialist,
      standard,
      status,
      settings,
      categoryGroupSettings,
      categoryIds,
      includedSegments,
      excludedSegments,
      // @ts-expect-error `sort` is a method on the Record class, so our TS
      // types removes it when converting it to JS. We need to rename it in
      // order for the types to work properly here.
      sort,
    } = this.toJS();

    let attributes = {
      name,
      level,
      memberType,
      status,
      specialist,
      sort,
      standard,
    };

    if (attributeId && attributeOptionId) {
      // @ts-expect-error TS(2322): Type '{ attributeId: any; attributeOptionId: any; ... Remove this comment to see the full error message
      attributes = { ...attributes, attributeId, attributeOptionId };
    }

    if (hasAccessByQuestionSet && standard !== 'admin') {
      // @ts-expect-error TS(2322): Type '{ categoryGroupSettings: any; name: any; lev... Remove this comment to see the full error message
      attributes = { ...attributes, categoryGroupSettings };
      if (hasAccessByCategory && categoryIds && level !== 'overall') {
        // @ts-expect-error TS(2322): Type '{ categoryIds: any; name: any; level: any; m... Remove this comment to see the full error message
        attributes = { ...attributes, categoryIds };
      }
    }

    let relationships;

    if (includedSegments) {
      if (!relationships) {
        relationships = {};
      }

      // @ts-expect-error TS(2339): Property 'includedSegments' does not exist on type... Remove this comment to see the full error message
      relationships.includedSegments = {
        data:
          includedSegments.length > 0
            ? // @ts-expect-error Type of property 'segment' circularly references itself in mapped type '{ id: never; level: never; employee: never; automatic: never; segment: never; }'
              includedSegments.map((segment) => ({
                type: 'segments',
                id: segment.id,
              }))
            : null,
      };
    }

    if (excludedSegments) {
      if (!relationships) {
        relationships = {};
      }

      // @ts-expect-error TS(2339): Property 'excludedSegments' does not exist on type... Remove this comment to see the full error message
      relationships.excludedSegments = {
        data:
          excludedSegments.length > 0
            ? excludedSegments.map((segment) => ({
                type: 'segments',
                id: segment.id,
              }))
            : null,
      };
    }

    const hasPermissionEnabled = Object.keys(settings).some(
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expression o...
      (setting) => settings[setting],
    );

    // When creating, only send settings if there as it least one enabled
    if ((type === 'POST' && hasPermissionEnabled) || type === 'PATCH') {
      // @ts-expect-error TS(2322): Type '{ settings: any; name: any; level: any; memb... Remove this comment to see the full error message
      attributes = { ...attributes, settings };
    }

    return pickBy({ type: 'groups', attributes, relationships });
  }

  static createFromApi(data: AccessGroupResponseData) {
    const {
      id,
      attributes,
      relationships: {
        attribute,
        attributeOption,
        includedSegments,
        excludedSegments,
      } = {},
    } = data;

    return new AccessGroup(
      fromJS({
        id,
        ...attributes,
        attributeId: attribute ? attribute.id : undefined,
        attributeOptionId: attributeOption ? attributeOption.id : undefined,
        attributeOption: attributeOption
          ? AttributeOption.createFromApi(attributeOption)
          : undefined,
        settings: Map(attributes.settings),
        categoryGroupSettings: attributes.categoryGroupSettings
          ? Map(attributes.categoryGroupSettings)
          : Map(),
        categoryIds: isSpecialistAccessGroup(attributes)
          ? Set(
              // we need to convert to String so it is typed correctly to be filtered against categories, which we store by ID
              attributes.categoryIds.map((c: string) => c.toString()),
            )
          : Set(),
        includedSegments: includedSegments
          ? includedSegments
              .filter((segment) => segment.attributes)
              // @ts-expect-error Argument of type '(data: { id: string; type: "segments"; attributes: { name:...
              .map(Segment.createFromApi)
          : List(),
        excludedSegments: excludedSegments
          ? excludedSegments
              .filter((segment) => segment.attributes)
              // @ts-expect-error Argument of type '(data: { id: string; type: "segments"; attributes: { name:...
              .map(Segment.createFromApi)
          : List(),
      }),
    );
  }
}

// eslint-disable-next-line import/no-default-export
export default AccessGroup;
